import React from 'react';

const Flex = ({
    alignContent,
    alignItems,
    backgroundColor,
    border,
    children,
    height,
    flex,
    flexDirection,
    fontWeight,
    justifyContent,
    justifyItems,
    left,
    margin,
    padding,
    position,
    top,
    bottom,
    width,
    zIndex
}) => {
    return (
        <div
            style={{
                alignItems: `${alignItems}`,
                alignContent: `${alignContent}`,
                backgroundColor: `${backgroundColor}`,
                border: `${border}`,
                display: 'flex',
                height: `${height}`,
                flex: `${flex}`,
                flexDirection: `${flexDirection}`,
                fontWeight: `${fontWeight}`,
                justifyContent: `${justifyContent}`,
                justifyItems: `${justifyItems}`,
                left: `${left}`,
                margin: `${margin}`,
                padding: `${padding}`,
                position: `${position}`,
                top: `${top}`,
                bottom: `${bottom}`,
                width: `${width}`,
                zIndex: `${zIndex}`
            }}
        >
            {children}
        </div>
    );
};

export default Flex;
