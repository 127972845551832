/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useStore } from 'react-redux';
import {
    Accordion,
    VerticalDiv,
    RedDiv,
    Span16px,
    MarginY10px,
    Span20px,
    TableDiv,
    Span14px,
    TableCell
} from '../../../ui';
import SubHeader from '../../../components/sub-header';
import AccordionCards from '../../../ui/AccordionCards';

const TexasScramble = () => {
    const { texasScramble } = useStore().getState();

    const groups = [];
    const numberOfGroups = [...new Set(texasScramble.map(g => +g.group))];

    numberOfGroups
        .sort((a, b) => (a > b ? 1 : -1))
        // eslint-disable-next-line array-callback-return
        .map(groupNumber => {
            const playersInGroup = texasScramble
                .filter(player => player.group === groupNumber)
                .map(player => ({
                    name: player.name,
                    handicap: player.handicap,
                    teeOff: player.teeOff,
                    group: groupNumber
                }))
                .sort((a, b) => (a.match > b.match ? 1 : -1));
            if (playersInGroup.length > 0) {
                groups.push({
                    number: playersInGroup[0].group,
                    teeOff: playersInGroup[0].teeOff,
                    playersInGroup
                });
            }
        });
    return (
        <RedDiv>
            <SubHeader data-test='component-subheader'>
                <Span20px>Day 2 Match Format: Texas Scramble</Span20px>
            </SubHeader>
            <VerticalDiv>
                <Accordion icon='📕' title='Rules'>
                    <MarginY10px>
                        <Span16px>
                            Texas Scramble is a team game, where the players in
                            your 4 ball are your team mates.
                        </Span16px>
                    </MarginY10px>
                    <MarginY10px>
                        <Span16px>
                            All members of the team will tee off. Then, one of
                            these drives is chosen to be used for the next shot
                            and all members of the team place their balls where
                            the chosen drive ended up, and all the team play a
                            second shot from this spot.
                        </Span16px>
                    </MarginY10px>
                    <MarginY10px>
                        <Span16px>
                            From these second shots, one is chosen and all play
                            a third shot for there – and so on until the ball is
                            holed. Each team returns one score for each hole and
                            the team with the lowest score for the round wins.
                        </Span16px>
                    </MarginY10px>
                    <MarginY10px>
                        <Span16px>
                            Note: The team must use at least 3 of each players'
                            tee shot.
                        </Span16px>
                    </MarginY10px>
                    <MarginY10px>
                        <Span16px>
                            One of the attractions of Texas Scramble is that if
                            you play a poor shot, your team's score won't be
                            affected as you can just choose one of your team
                            mates' shots - especially if you have Tony in your
                            team.
                        </Span16px>
                    </MarginY10px>
                    <MarginY10px>
                        <VerticalDiv>
                            <Span16px>
                                <strong>A playing example</strong>
                            </Span16px>
                            <Span16px>
                                - A team is made up of 4 players, Ryan, Matt,
                                Alec and Chris.
                            </Span16px>
                            <Span16px>
                                - All players take their tee shot and agree
                                Ryan's was the best. - After everyone taking
                                their 2nd shot from where Ryan's tee shot ended
                                up, the team decide Matt's was the best, as it's
                                10ft from the pin.
                            </Span16px>
                            <Span16px>
                                - Each player than has a putt, where Chris
                                drains it.
                            </Span16px>
                            <Span16px>
                                - The team's score is 3 for this hole.
                            </Span16px>
                        </VerticalDiv>
                    </MarginY10px>
                </Accordion>

                <AccordionCards icon='😎' title='Groups'>
                    {groups.map(group => (
                        <VerticalDiv>
                            <Span20px>
                                <span role='img' aria-label='Golf pin emoji'>
                                    ⛳️
                                </span>{' '}
                                Group {group.number} - Tee Off {group.teeOff}
                            </Span20px>
                            <TableDiv>
                                <TableCell>
                                    <strong>Name</strong>
                                </TableCell>
                                <TableCell>
                                    <strong>Handicap</strong>
                                </TableCell>
                            </TableDiv>
                            {group.playersInGroup.map(player => (
                                <TableDiv>
                                    <TableCell>
                                        <Span14px>{player.name}</Span14px>
                                    </TableCell>
                                    <TableCell>
                                        <Span14px>{player.handicap}</Span14px>
                                    </TableCell>
                                </TableDiv>
                            ))}
                        </VerticalDiv>
                    ))}
                </AccordionCards>
            </VerticalDiv>
        </RedDiv>
    );
};

export default TexasScramble;
