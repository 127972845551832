import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { RedDiv, Span20px, Dropdown, Button } from '../../../ui';
import SubHeader from '../../../components/sub-header';
import { CenteredDiv } from '../../scoring/scoring-landing-page/ScoringLandingPage.styles';

class ItineraryLandingPage extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            name: ''
        };
    }

    nameDropdownOnChange = event => {
        this.setState({
            name: event.target.value
        });
    };

    getPlayerNames = () => {
        let { itinerary } = this.props;
        if (!Array.isArray(itinerary)) {
            itinerary = Object.values(itinerary);
        }
        return itinerary
            .map(guest => guest.name)
            .sort((a, b) => {
                return a > b ? 1 : -1;
            });
    };

    handleSubmit = () => {
        const { history } = this.props;
        const { name } = this.state;
        history.push(`/my-itinerary/${name.toLowerCase().replace(' ', '-')}`);
    };

    render() {
        const { name } = this.state;
        return (
            <RedDiv data-test='component-itinerary-landing-page'>
                <SubHeader data-test='component-subheader'>
                    <Span20px>ITINERARY</Span20px>
                </SubHeader>
                <CenteredDiv>
                    <Dropdown
                        data-test='component-dropdown'
                        disabled={false}
                        label='Whose itinerary are you looking for?'
                        options={this.getPlayerNames()}
                        onChange={this.nameDropdownOnChange}
                        value={name}
                    />
                    <Button
                        onClick={this.handleSubmit}
                        data-test='component-button'
                        style={{ marginTop: '10px' }}
                        disabled={
                            !(name && name !== ' - ') ? 'disabled' : undefined
                        }
                    >
                        Open
                    </Button>
                </CenteredDiv>
            </RedDiv>
        );
    }
}

const mapStateToProps = ({ itinerary }) => ({
    itinerary
});

export default connect(mapStateToProps, null)(withRouter(ItineraryLandingPage));
