import styled from 'styled-components';
import colours from '../../../global/colours';

export const TableWrapper = styled.div`
    min-width: 1020px;
    text-align: left;
    margin: 380px auto 30px auto;

    @media only screen and (min-width: 1400px) {
        margin: 240px auto 30px auto;
    }
`;

export const Spacer = styled.div`
    display: inline-block;
    vertical-align: middle;
    width: 50px;
    height: 50px;
`;

export const Row = styled.div`
    &::after {
        content: '';
        clear: both;
    }
    @media only screen and (min-width: 0) {
        padding: 0;
    }
    @media only screen and (min-width: 1610px) {
        padding: 0 50px;
    }

    text-align: center;
`;

export const Column = styled.div`
    width: ${props => (props.span ? props.span * 43 : '2.6')}px;
    display: inline-block;
    vertical-align: middle;
    padding: 0 3px;
    text-align: ${props => {
        if (props.right) return 'right';
        if (props.center) return 'center';
        return 'left';
    }};
    color: ${props => {
        if (props.red) return colours.red;
        if (props.blue) return colours.blue;
        return colours.charcoal;
    }};

    @media only screen and (min-width: 0px) {
        display: ${props => {
            if (props.collapse) return 'none';
            return 'inline-block';
        }};
    }

    @media only screen and (min-width: 1300px) {
        width: ${props => {
            if (props.span === '4') return '145px';
            if (props.span === '5') return '165px';
            if (props.span === '3' && props.right) return '90px';
            return `${props.span * 43}px`;
        }};
        display: inline-block;
    }
    @media only screen and (min-width: 1610px) {
        width: ${props => (props.span ? props.span * 43 : '2.6')}px;
    }
    h2,
    h2,
    img {
        vertical-align: middle;
        display: inline-block;
    }
    h3 {
        font-weight: 100;
    }
    img {
        margin-left: 3px;
        width: 50px;
    }
`;

export const CircleScore = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
    width: 40px;
    height: 40px;
    border-radius: 25px;
    background-color: ${props => {
        if (props.result === 'lose') return colours.red;
        if (props.result === 'win') return colours.blue;
        if (props.result === 'draw') return colours.grey_light;
        return 'none';
    }};

    border: 1px solid
        ${props => {
            if (props.result === 'lose') return colours.red;
            if (props.result === 'win') return colours.blue;
            if (props.result === 'draw') return colours.grey_light;
            return colours.charcoal;
        }};
`;

export const CircleText = styled.p`
    &::before {
        content: "${props => {
            if (props.result === 'draw') return 'A/S';
            return '';
        }}";
        
    }
    color: ${props => {
        if (props.result === 'draw') return colours.charcoal;
        return 'white';
    }};
    text-align: center;
    font-weight: bold;
`;

export const ResultText = styled.h2`
    &::before {
        content: "${props => {
            return `${props.lead}`;
        }}";
    }

    color: ${props => {
        if (props.winner === 'win') return colours.blue;
        return colours.red;
    }};

    text-align:right;
`;

export const DrawText = styled.h2`
    &::before {
        content: 'A/S';
    }
    color: ${colours.grey_draw};
    padding-right: 2px;
    text-align: right;
`;
