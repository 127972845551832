import React from 'react';
import { connect } from 'react-redux';
import Player from './player/Player';
import { MatchWrapper, VsSpan } from './PlayGroup.styles';
import {
    incrementShotCount,
    decrementShotCount,
    incrementShotCountStable,
    decrementShotCountStable
} from '../../../store/actions';
import {
    calculateShotModifier,
    calculateShotModifierStable,
    UsaResult,
    EuResult
} from '../../../utils';

export class DisconnectedPlayerGroup extends React.PureComponent {
    numberOfMatchesArray = () => {
        const { players, playersStable, gameType } = this.props;
        const playersType = gameType === 'stableford' ? playersStable : players;
        const matches = playersType.reduce((acc, player) => {
            acc.push(player.match);
            return acc;
        }, []);
        return [...new Set(matches)];
    };

    calculateTotalShots = () => {
        const { liveScoresStable } = this.props;

        //  console.log(liveScoresStable);

        const keyObj = {};
        Object.keys(liveScoresStable).map((key, index) => {
            const innerKey = liveScoresStable[key];
            const matchNumber = `match${index + 1}`;
            keyObj[matchNumber] = {};
            keyObj[matchNumber].shot1 = 0;
            keyObj[matchNumber].shot2 = 0;
            Object.keys(innerKey).map((newKey, newIndex) => {
                const holeNumber = `hole${newIndex + 1}`;
                const totalShot1 =
                    liveScoresStable[matchNumber][holeNumber].shot1;
                const totalShot2 =
                    liveScoresStable[matchNumber][holeNumber].shot2;
                if (
                    liveScoresStable[matchNumber][holeNumber].result !==
                    'undefined'
                ) {
                    keyObj[matchNumber].shot1 += totalShot1;
                    keyObj[matchNumber].shot2 += totalShot2;
                }

                return null;
            });
            return null;
        });

        return keyObj;
    };

    calculateStableFordScores = (score, par) => {
        const difference = score - par;
        if (difference > 1) return 0;
        if (difference === 1) return 1;
        if (difference === 0) return 2;
        if (difference === -1) return 3;
        if (difference === -2) return 4;
        if (difference === -3) return 5;
        if (difference === -4) return 6;
        return 0;
    };

    render() {
        const {
            handleIncrement,
            handleDecrement,
            handleIncrementStable,
            handleDecrementStable,
            activeHole,
            activeHoleStable,
            players,
            playersStable,
            holes,
            holesStable,
            liveScores,
            liveScoresStable,
            gameType,
            matchResultSection
        } = this.props;

        const holesType = gameType === 'stableford' ? holesStable : holes;
        const playersType = gameType === 'stableford' ? playersStable : players;
        const activeHoleType =
            gameType === 'stableford' ? activeHoleStable : activeHole;
        const currentHole = holesType[activeHoleType - 1];
        const liveScoresType =
            gameType === 'stableford' ? liveScoresStable : liveScores;

        return (
            <div data-test='component-player-group'>
                {this.numberOfMatchesArray(playersType).map((match, index) => {
                    const playersInMatch = playersType.filter(
                        player => player.match === match
                    );

                    const playerEu = playersInMatch[0];
                    const playerUsa = playersInMatch[1];

                    const euResult = EuResult(
                        playerEu,
                        playerUsa,
                        currentHole,
                        activeHoleType
                    );

                    const usaResult = UsaResult(
                        playerEu,
                        playerUsa,
                        currentHole,
                        activeHoleType
                    );

                    /*  INITIATING GARBAGE CODE SEQUENCE - BLEEP BLOOP - TO PRESERVE VITALS, SKIP TO LINE 190  */

                    const matchNumber = `match${match}`;
                    const holeNumber = `hole${currentHole.number}`;
                    const totalShotObj = this.calculateTotalShots();
                    const matchTotal = totalShotObj[matchNumber];

                    const liveShot1 =
                        liveScoresType[matchNumber][holeNumber].shot1;
                    const liveShot2 =
                        liveScoresType[matchNumber][holeNumber].shot2;

                    let score1 =
                        liveShot1 !== 0
                            ? liveShot1
                            : holesType[activeHoleType - 1].par;
                    let score2 =
                        liveShot2 !== 0
                            ? liveShot2
                            : holesType[activeHoleType - 1].par;

                    playerEu.shots[activeHoleType - 1] = score1;
                    playerUsa.shots[activeHoleType - 1] = score2;

                    const player1Modifier = calculateShotModifier(
                        playerEu,
                        playerUsa,
                        currentHole
                    );
                    const player1ModifierStable = calculateShotModifierStable(
                        playerEu,
                        currentHole,
                        holesStable.length
                    );

                    const player2Modifier = calculateShotModifier(
                        playerUsa,
                        playerEu,
                        currentHole
                    );
                    const player2ModifierStable = calculateShotModifierStable(
                        playerUsa,
                        currentHole,
                        holesStable.length
                    );
                    if (gameType === 'stableford') {
                        playerEu.shotsNet[
                            activeHoleType - 1
                        ] = this.calculateStableFordScores(
                            score1 - player1ModifierStable,
                            holesType[activeHoleType - 1].par
                        );
                        playerUsa.shotsNet[
                            activeHoleType - 1
                        ] = this.calculateStableFordScores(
                            score2 - player2ModifierStable,
                            holesType[activeHoleType - 1].par
                        );
                    }

                    liveScoresType[matchNumber][holeNumber].shot1 =
                        playerEu.shots[activeHoleType - 1];
                    liveScoresType[matchNumber][holeNumber].shot2 =
                        playerUsa.shots[activeHoleType - 1];

                    /*  VITALS NOMINAL, PROCEED WITH CAUTION, HUMAN - BLEEP BLOOP  */

                    return (
                        <MatchWrapper key={match}>
                            <Player
                                firstPlayer={index === 0}
                                firstPlayerSecondMatch={index === 1}
                                data-test='player'
                                key={playerEu.name}
                                {...playerEu}
                                shots={playerEu.shots[activeHoleType - 1]}
                                shotModifier={
                                    gameType !== 'stableford'
                                        ? player1Modifier
                                        : player1ModifierStable
                                }
                                onDecrement={() => {
                                    if (gameType === 'stableford')
                                        handleDecrementStable(playerEu.name);
                                    else handleDecrement(playerEu.name);
                                    const shot1Dec =
                                        score1 > 1 ? (score1 -= 1) : 1;
                                    liveScoresType[matchNumber][
                                        holeNumber
                                    ].shot1 = shot1Dec;
                                }}
                                onIncrement={() => {
                                    if (gameType === 'stableford')
                                        handleIncrementStable(playerEu.name);
                                    else handleIncrement(playerEu.name);
                                    liveScoresType[matchNumber][
                                        holeNumber
                                    ].shot1 += 1;
                                }}
                                editable={currentHole.editable}
                                result={euResult}
                                gt={gameType}
                                matchResultSection={matchResultSection}
                                matchTotalScore={matchTotal}
                            />
                            {gameType !== 'stableford' ? (
                                <VsSpan>vs.</VsSpan>
                            ) : null}
                            <Player
                                data-test='player'
                                key={playerUsa.name}
                                {...playerUsa}
                                shots={playerUsa.shots[activeHoleType - 1]}
                                shotModifier={
                                    gameType !== 'stableford'
                                        ? player2Modifier
                                        : player2ModifierStable
                                }
                                onDecrement={() => {
                                    if (gameType === 'stableford')
                                        handleDecrementStable(playerUsa.name);
                                    else handleDecrement(playerUsa.name);
                                    const shot2Dec =
                                        score2 > 1 ? (score2 -= 1) : 1;
                                    liveScoresType[matchNumber][
                                        holeNumber
                                    ].shot2 = shot2Dec;
                                }}
                                onIncrement={() => {
                                    if (gameType === 'stableford')
                                        handleIncrementStable(playerUsa.name);
                                    else handleIncrement(playerUsa.name);
                                    liveScoresType[matchNumber][
                                        holeNumber
                                    ].shot2 += 1;
                                }}
                                editable={currentHole.editable}
                                result={usaResult}
                                gt={gameType}
                                matchResultSection={matchResultSection}
                                matchTotalScore={matchTotal}
                            />
                        </MatchWrapper>
                    );
                })}
            </div>
        );
    }
}

const mapStateToProps = ({
    ryderCup: { activeHole, players, holes, liveScores },
    stableford: {
        activeHole: activeHoleStable,
        players: playersStable,
        holes: holesStable,
        liveScores: liveScoresStable
    }
}) => ({
    activeHole,
    players,
    holes,
    liveScores,
    activeHoleStable,
    playersStable,
    holesStable,
    liveScoresStable
});

const mapDispatchToProps = dispatch => ({
    handleIncrement: name => {
        dispatch(incrementShotCount(name));
    },
    handleDecrement: name => {
        dispatch(decrementShotCount(name));
    },
    handleIncrementStable: name => {
        dispatch(incrementShotCountStable(name));
    },
    handleDecrementStable: name => {
        dispatch(decrementShotCountStable(name));
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DisconnectedPlayerGroup);
