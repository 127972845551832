import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { BtnHomeImg, BtnItinImg, BtnScoreImg, BtnRulesImg } from '../../../ui';
import Flex from '../../../ui/Layout/Flex';
import { StyledNavButton, StyledNavImg } from './NavigationComponent.styles';

export class NavigationComponent extends React.PureComponent {
    render() {
        const { history } = this.props;

        if (history.location.pathname !== '/scoreboard') {
            return (
                <Flex
                    justifyContent='space-evenly'
                    position='fixed'
                    width='100%'
                    padding='0px'
                    margin='0px'
                    bottom='0'
                    backgroundColor='#fff'
                    height='110px'
                    zIndex='2'
                >
                    <StyledNavButton>
                        <NavLink activeClassName='is-active' exact to='/'>
                            <StyledNavImg src={BtnHomeImg} />
                            <p>Home</p>
                        </NavLink>
                    </StyledNavButton>
                    <StyledNavButton>
                        <NavLink
                            activeClassName='is-active'
                            isActive={(_, { pathname }) =>
                                [
                                    '/enter-scores',
                                    '/stableford',
                                    '/ryder-cup',
                                    '/texas-scramble'
                                ].includes(pathname)
                            }
                            to='/enter-scores'
                        >
                            <StyledNavImg src={BtnScoreImg} />
                            <p>Scoring</p>
                        </NavLink>
                    </StyledNavButton>
                    <StyledNavButton>
                        <NavLink activeClassName='is-active' to='/my-itinerary'>
                            <StyledNavImg src={BtnItinImg} />
                            <p>Itinerary</p>
                        </NavLink>
                    </StyledNavButton>
                    <StyledNavButton>
                        <NavLink
                            activeClassName='is-active'
                            to='/match-formats'
                        >
                            <StyledNavImg src={BtnRulesImg} />
                            <p>Rules</p>
                        </NavLink>
                    </StyledNavButton>
                </Flex>
            );
        }
        return null;
    }
}

export default withRouter(NavigationComponent);
