import styled from 'styled-components';
//  import colours from '../../global/colours';

export const StyledBackWrapper = styled.div`
    display: flex;
    position: absolute;
    padding: 0;
    margin: 0;
    bottom: 10px;
    background-color: #fff;
    height: 40px;
    z-index: 2;
    left: 13px;
`;

export const StyledBackImg = styled.img`
    text-align: center;
    width: 40px;
`;
