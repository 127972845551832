import styled from 'styled-components';
import { colours } from '../../../../global';

export const FlagImg = styled.img`
    margin-right: 10px;
`;
export const ScoreContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid ${colours.grey_light};
    height: 40px;
    width: 40px;
    margin: 4px 15px;
`;

export const RoundScoreContainer = styled.div`
    color: ${props => {
        switch (props.result) {
            case 'Europe':
            case 'America':
                return 'white';
            default:
                return 'black';
        }
    }};

    display: flex;
    justify-content: center;
    align-items: center;

    border: ${props =>
        props.result === 'blank' ? 'none' : `1px solid ${colours.grey_light}`};

    height: 40px;
    width: 40px;
    margin: 4px 15px;
    border-radius: 40px;
    background-color: ${props => {
        switch (props.result) {
            case 'Europe':
                return `${colours.blue}`;
            case 'America':
                return `${colours.red}`;
            case 'Draw':
                return `${colours.grey_light}`;
            default:
                return 'white';
        }
    }};
`;

export const NetSpan = styled.span`
    font-size: 14px;
    display: inline-block;
    width: 40px;
    margin: 4px 15px;
    text-align: center;
`;

export const GrossSpan = styled.span`
    font-size: 14px;
    display: inline-block;
    width: 40px;
    margin: 4px 15px;
    text-align: center;
`;

export const ShotModifierDiv = styled.div`
    display: inline-block;
    text-align: center;
    width: 15px;
    height: 15px;
    font-size: 10px;
    color: ${colours.green};
    border-radius: 20px;
    border: 1px solid ${colours.green};
    margin-left: 3px;
`;
