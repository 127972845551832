import React from 'react';
import { Span16px, MarginTop29px, RedText, VerticalDiv } from '../../../../ui';

export default ({ incompleteHoles }) => (
    <>
        <RedText>
            <Span16px>There was a problem</Span16px>
        </RedText>
        &nbsp;
        <Span16px>
            You have not saved all of your holes. The below holes have not been
            saved, please go back and check each score is correct and saved.
            <br />
            <br />
            Hole
        </Span16px>
        <MarginTop29px>
            <VerticalDiv>
                {incompleteHoles.map(hole => {
                    return (
                        <Span16px key={hole.number}>
                            {' '}
                            &#8226; {hole.number}
                        </Span16px>
                    );
                })}
            </VerticalDiv>
        </MarginTop29px>
    </>
);
