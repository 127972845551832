import styled from 'styled-components';
import colours from '../../../global/colours';

export const StyledDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 55px;
    line-height: 37px;
    margin-top: 10px;

    h1 {
        font-size: 60px;
        margin: 0 15px;
        line-height: 60px;
    }
    svg {
        vertical-align: middle;
        width: 300px;
        margin: 0 30px;
    }
`;
export const StyledHeader = styled.header`
    width: 1020px;
    display: flex;
    padding: 30px 50px 0 50px;
    justify-content: space-between;
    flex-direction: column;
    flex: 0 0 1024px;
    position: fixed;
    background-color: white;
    @media only screen and (min-width: 1020px) {
        flex: 1;
        width: 100%;
    }
    @media only screen and (min-width: 1410px) {
        flex-direction: row;
    }
`;
export const FlagImg = styled.img`
    margin: 0 15px;
`;
export const ScoreWrapper = styled.div`
    width: 280px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
`;

export const CountryScore = styled.h1`
    font-size: 55px;
`;
export const StyledP = styled.p`
    font-size: 24px;
`;
export const EuScore = styled(CountryScore)`
    color: ${colours.blue};
`;
export const UsScore = styled(CountryScore)`
    color: ${colours.red};
`;
