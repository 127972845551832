import React from 'react';
import { Link } from 'react-router-dom';
import {
    Span14px,
    MarginY10px,
    LinkWrapper,
    TableDiv,
    TableCell,
    TableCellMin
} from '../../../../ui';

export default ({ day, itinerary, playingWith }) => {
    return (
        <>
            <MarginY10px>
                <LinkWrapper>
                    <Link to={itinerary[day].rulesLink}>
                        {itinerary[day].rulesLinkLabel}
                    </Link>
                </LinkWrapper>
            </MarginY10px>
            <Span14px>
                <strong>Agenda: </strong>
            </Span14px>
            {itinerary[day].agenda.map(item => {
                const [date, agenda] = item.split(' - ');
                return (
                    <TableDiv key={item}>
                        <TableCellMin>
                            <strong>{date}</strong>
                        </TableCellMin>
                        <TableCell>{agenda}</TableCell>
                    </TableDiv>
                );
            })}

            <MarginY10px>
                <LinkWrapper>
                    <a
                        target='_blank'
                        rel='noopener noreferrer'
                        href={itinerary[day].restaurantLink}
                    >
                        {itinerary[day].restaurantLinkLabel}
                    </a>
                </LinkWrapper>
            </MarginY10px>

            <Span14px>
                <strong>Playing Group {itinerary[day].playingGroup}</strong>
            </Span14px>
            {playingWith(day, itinerary[day].playingGroupId).map(name => (
                <Span14px key={name}>{name}</Span14px>
            ))}
        </>
    );
};
