import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { RedDiv, Span20px } from '../../../ui';
import SubHeader from '../../../components/sub-header';
import Accordion from '../../../ui/Accordion';
import TravelContent from './accordion-content/Travel';
import AccommodationContent from './accordion-content/Accommodation';
import ItineraryContent from './accordion-content/Itinerary';

class ItineraryPage extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            guest: null
        };
    }

    componentDidMount() {
        const {
            match: { params }
        } = this.props;
        let { itinerary: playersItineraries } = this.props;
        if (!Array.isArray(playersItineraries)) {
            playersItineraries = Object.values(playersItineraries);
        }
        const guest = playersItineraries.filter(
            g => g.name.toLowerCase().replace(' ', '-') === params.guest
        )[0];

        this.setState({
            guest
        });
    }

    getPlayersFromGroupId = (day, groupId) => {
        let { itinerary: playersItineraries } = this.props;
        if (!Array.isArray(playersItineraries)) {
            playersItineraries = Object.values(playersItineraries);
        }

        const guestsInGroup = playersItineraries
            .filter(guest => guest.itinerary[day].playingGroupId === groupId)
            .map(guest => guest.name)
            .sort((a, b) => (a > b ? 1 : -1));

        return guestsInGroup;
    };

    render() {
        const { guest } = this.state;
        if (!guest) return <h2>Loading...</h2>;
        return (
            <RedDiv data-test='component-itinerary-landing-page'>
                <SubHeader data-test='component-subheader'>
                    <Span20px>Welcome, {guest.name}</Span20px>
                </SubHeader>
                <Accordion icon='✈️' title='Travel'>
                    <TravelContent {...guest.travel} />
                </Accordion>

                <Accordion icon='🏨' title='Accommodation'>
                    <AccommodationContent {...guest.accommodation} />
                </Accordion>

                <Accordion icon='⛳️' title='Friday: AND Open'>
                    <ItineraryContent
                        day='Friday'
                        itinerary={guest.itinerary}
                        playingWith={this.getPlayersFromGroupId}
                    />
                </Accordion>
                <Accordion icon='⛳️' title='Saturday: Texas Scramble'>
                    <ItineraryContent
                        day='Saturday'
                        itinerary={guest.itinerary}
                        playingWith={this.getPlayersFromGroupId}
                    />
                </Accordion>
                <Accordion icon='⛳️' title='Sunday: Ryder Cup'>
                    <ItineraryContent
                        day='Sunday'
                        itinerary={guest.itinerary}
                        playingWith={this.getPlayersFromGroupId}
                    />
                </Accordion>
            </RedDiv>
        );
    }
}

const mapStateToProps = ({ itinerary }) => ({
    itinerary
});

export default connect(mapStateToProps, null)(withRouter(ItineraryPage));
