import React from 'react';
import { SpaceAroundDiv } from '../../../../ui';
import { Grid, SummaryCircleScore } from './MatchSummaryStableford.styles';

const MatchSummary = ({ liveScores, index, editable }) => {
    const passScores = liveScores;
    const passIndex = (index + 8) * index;
    return (
        <Grid>
            <SpaceAroundDiv>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9].map(hole => (
                    <SummaryCircleScore
                        key={hole}
                        result={passScores[`hole${passIndex + hole}`].result}
                        editable={editable[passIndex + hole - 1].editable}
                    />
                ))}
            </SpaceAroundDiv>
        </Grid>
    );
};

export default MatchSummary;
