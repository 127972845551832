export const calculateShotModifier = (playerToModify, opponent, hole) => {
    const difference = playerToModify.handicap - opponent.handicap;
    if (difference < 0) return 0;
    if (hole.stroke <= difference) return 1;
    return 0;
};

export const calculateShotModifierStable = (
    playerToModify,
    hole,
    totalHoles
) => {
    const difference = playerToModify.handicap - totalHoles;
    if (difference < 0 && hole.stroke <= playerToModify.handicap) return 1;
    if (difference < 0 && hole.stroke > playerToModify.handicap) return 0;
    if (hole.stroke <= difference) return 2;
    return 1;
};

const ryderCupResultCalculator = (
    playerEu,
    playerUsa,
    currentHole,
    holeIndex
) => {
    const scoreDifference =
        playerEu.shots[holeIndex - 1] -
        calculateShotModifier(playerEu, playerUsa, currentHole) -
        (playerUsa.shots[holeIndex - 1] -
            calculateShotModifier(playerUsa, playerEu, currentHole));

    return scoreDifference < 0
        ? 'Europe'
        : scoreDifference > 0
        ? 'America'
        : 'Draw';
};

export const UsaResult = (playerEu, playerUsa, currentHole, holeIndex) => {
    switch (
        ryderCupResultCalculator(playerEu, playerUsa, currentHole, holeIndex)
    ) {
        case 'America':
            return 'America';
        case 'Draw':
            return 'Draw';
        default:
            return undefined;
    }
};

export const EuResult = (playerEu, playerUsa, currentHole, holeIndex) => {
    switch (
        ryderCupResultCalculator(playerEu, playerUsa, currentHole, holeIndex)
    ) {
        case 'Europe':
            return 'Europe';
        case 'Draw':
            return 'Draw';
        default:
            return undefined;
    }
};
