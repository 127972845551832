export const INCREMENT_SHOT_COUNT = 'INCREMENT_SHOT_COUNT';
export const DECREMENT_SHOT_COUNT = 'DECREMENT_SHOT_COUNT';
export const INCREMENT_ACTIVE_HOLE = 'INCREMENT_ACTIVE_HOLE';
export const DECREMENT_ACTIVE_HOLE = 'DECREMENT_ACTIVE_HOLE';
export const TOGGLE_SCORE_EDITABLE = 'TOGGLE_SCORE_EDITABLE';
export const INCREMENT_SHOT_COUNT_STABLE = 'INCREMENT_SHOT_COUNT_STABLE';
export const DECREMENT_SHOT_COUNT_STABLE = 'DECREMENT_SHOT_COUNT_STABLE';
export const INCREMENT_ACTIVE_HOLE_STABLE = 'INCREMENT_ACTIVE_HOLE_STABLE';
export const DECREMENT_ACTIVE_HOLE_STABLE = 'DECREMENT_ACTIVE_HOLE_STABLE';
export const TOGGLE_SCORE_EDITABLE_STABLE = 'TOGGLE_SCORE_EDITABLE_STABLE';
export const SELECT_GROUP = 'SELECT_GROUP';
export const GET_HOLES = 'GET_HOLES';
export const GET_PLAYERS = 'GET_PLAYERS';
export const INCREMENT_HEADER_SCORE = 'INCREMENT_HEADER_SCORE';
export const LOAD_ALL_SCORES = 'LOAD_ALL_SCORES';
export const LOAD_ALL_SCORES_STABLE = 'LOAD_ALL_SCORES_STABLE';
export const END_ROUND_START = 'END_ROUND_START';
export const END_ROUND_SUCCESS = 'END_ROUND_SUCCESS';
export const END_ROUND_START_STABLE = 'END_ROUND_START_STABLE';
export const END_ROUND_SUCCESS_STABLE = 'END_ROUND_SUCCESS_STABLE';
export const LOAD_ALL_DATA = 'LOAD_ALL_DATA';
export const INCREMENT_HOLE_SCORE = 'INCREMENT_HOLE_SCORE';
export const DECREMENT_HOLE_SCORE = 'DECREMENT_HOLE_SCORE';
