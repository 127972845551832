import React from 'react';
import {
    Accordion,
    VerticalDiv,
    RedDiv,
    Span16px,
    MarginY10px,
    Span20px
} from '../../../ui';
import SubHeader from '../../../components/sub-header';

const Bonus = () => {
    return (
        <RedDiv>
            <SubHeader data-test='component-subheader'>
                <Span20px>Bonus</Span20px>
            </SubHeader>

            <VerticalDiv>
                <Accordion icon='🏆' title='Lowest Gross Score'>
                    <MarginY10px>
                        <Span16px>
                            Awarded to the player that has the lowest gross
                            score over 18 holes played on Friday
                        </Span16px>
                    </MarginY10px>
                </Accordion>

                <Accordion icon='🏌️' title='Longest Drive'>
                    <Span16px>
                        Awarded to the player that drives the ball the furthest
                        on the nominated ‘longest drive’ competition hole. The
                        hole will be announced on the morning of the event.
                    </Span16px>
                </Accordion>

                <Accordion icon='⛳️' title='Closest to the Pin'>
                    <Span16px>
                        Awarded to the player that hits the ball closest to the
                        pin from their tee shot on the nominated ‘closest to the
                        pin’ competition hole.{' '}
                    </Span16px>
                    &nbsp;
                    <Span16px>
                        The hole will be announced on the morning of the round.
                    </Span16px>
                </Accordion>
            </VerticalDiv>
        </RedDiv>
    );
};

export default Bonus;
