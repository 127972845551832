import styled from 'styled-components';

export const MatchWrapper = styled.div``;

export const VsSpan = styled.span`
    font-size: 12px;
    position: absolute;
    left: 50px;
    transform: translateY(-10px);
`;
