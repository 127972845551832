import { LOAD_ALL_DATA } from '../types';

export const initialState = null;

export default (state = initialState, action) => {
    switch (action.type) {
        case LOAD_ALL_DATA:
            const {
                itinerary: { guests }
            } = action.payload;
            return guests;

        default:
            return { ...state };
    }
};
