import React from 'react';
import { TableDiv, TableCell } from '../../../../ui';

export default ({
    hotelName,
    checkInTime,
    checkInDate,
    stayingWith,
    checkOutTime,
    checkOutDate
}) => {
    return (
        <>
            <TableDiv>
                <TableCell>
                    <strong>Hotel Name:</strong>
                </TableCell>
                <TableCell>{hotelName}</TableCell>
            </TableDiv>
            <TableDiv>
                <TableCell>
                    <strong> Check In: </strong>
                </TableCell>
                <TableCell>
                    {checkInTime} {checkInDate}
                </TableCell>
            </TableDiv>
            <TableDiv>
                <TableCell>
                    <strong> Sharing Room With: </strong>
                </TableCell>
                <TableCell>{stayingWith}</TableCell>
            </TableDiv>
            <TableDiv>
                <TableCell>
                    <strong> Checkout: </strong>
                </TableCell>
                <TableCell>
                    {checkOutTime} {checkOutDate}
                </TableCell>
            </TableDiv>
        </>
    );
};
