/* eslint-disable no-underscore-dangle */
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { save, load } from 'redux-localstorage-simple';

import rootReducer from './reducers';

export default function configureStore(preloadedState = {}) {
    preloadedState = load({
        namespace: 'and-golf-app',
        preloadedState
    });
    const middlewares = [
        thunk,
        save({ debounce: 500, namespace: 'and-golf-app' })
    ];
    const middlewareEnhancer = applyMiddleware(...middlewares);

    const enhancers = [middlewareEnhancer];
    const composedEnhancers = composeWithDevTools(...enhancers);

    return createStore(rootReducer, preloadedState, composedEnhancers);
}
