import styled from 'styled-components';
import { colours } from '../../../../../../global';

export const ScoreContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: ${props => props.marginTop};
`;

export const RoundScoreContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border: 3px solid ${colours.black};
    height: 40px;
    width: 40px;
    margin: 4px 15px;
    border-radius: 40px;
    background-color: ${props => {
        switch (props.result) {
            case 'Europe':
                return `${colours.blue}`;
            case 'America':
                return `${colours.red}`;
            case 'Draw':
                return `${colours.grey_light}`;
            default:
                return 'white';
        }
    }};
`;

export const ShotModifierDiv = styled.div`
    display: inline-block;
    text-align: center;
    width: 15px;
    height: 15px;
    font-size: 10px;
    color: ${colours.green};
    border-radius: 20px;
    border: 1px solid ${colours.green};
    margin-left: 3px;
`;
