import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Div80, VerticalDiv } from './Layout';

const Label = styled.label`
    margin-top: 10px;
    color: white;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
`;

const Select = styled.select`
    margin-top: 10px;
    background: #ffffff;
    border: 2px solid #2897ff;
    font-size: 14px;
    line-height: 21px;
`;

const Option = styled.option`
    background-color: white;
`;

const OptionMultiline = styled.option`
    background-color: white;
    word-wrap: normal;
`;

const Dropdown = ({
    disabled,
    label,
    options,
    onChange,
    value,
    disabledOptions,
    multiline
}) => {
    if (!multiline) {
        options.unshift(' - ');
    }

    return (
        <Div80>
            <VerticalDiv>
                <Label data-test='dropdown-label' htmlFor={label}>
                    {label}
                </Label>
                <Select
                    aria-labelledby={label}
                    name={label}
                    data-test='component-dropdown'
                    disabled={disabled ? true : undefined}
                    placeholder={options && options.length > 0 ? ' - ' : null}
                    onChange={onChange}
                    value={value}
                >
                    {options && options.length > 0 ? (
                        options.map((option, index) =>
                            multiline ? (
                                <OptionMultiline
                                    data-test='dropdown-option'
                                    key={option}
                                    value={option}
                                    disabled={disabledOptions[index - 1]}
                                >
                                    {option}
                                </OptionMultiline>
                            ) : (
                                <Option
                                    data-test='dropdown-option'
                                    key={option}
                                    value={option}
                                    disabled={
                                        disabledOptions
                                            ? disabledOptions[index - 1]
                                            : null
                                    }
                                >
                                    {option}
                                </Option>
                            )
                        )
                    ) : (
                        <Option>No options available</Option>
                    )}
                </Select>
            </VerticalDiv>
        </Div80>
    );
};

Dropdown.propTypes = {
    disabled: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.string).isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired
};

export default Dropdown;
