import styled from 'styled-components';

export const StyledDiv = styled.div`
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: min-content;
    padding-bottom: 10px;
    width: 100%;
`;

export const y = 'y';
