export default {
    itinerary: {
        guests: [
            {
                accommodation: {
                    checkInDate: '08/05/2020',
                    checkInTime: '11:00',
                    checkOutDate: '10/05/2020',
                    checkOutTime: '10:00',
                    hotelName: 'La Manga Principe Filipe',
                    stayingWith: 'Matt A'
                },
                itinerary: {
                    Friday: {
                        agenda: [
                            '11:00 - Meet at hotel reception to get a transfer to the West Course',
                            '11:15 - Briefing at 1st tee',
                            '11:36 - Group 1 tee off',
                            "16:30 - Clubhouse Bar for a drink and rules explanation for Saturday's golf format",
                            '20:00 - Dinner in the Asia Restaurant with Prize Ceremony'
                        ],
                        playingGroup: '1',
                        playingGroupId: '11',
                        restaurantLink:
                            'https://lamangaclub.com/web/menu/asia.pdf',
                        restaurantLinkLabel: 'Asia Restaurant Menu',
                        rulesLink: '/match-formats/the-and-open',
                        rulesLinkLabel: 'Round Format & Rules',
                        title: '⛳ Friday: The AND Open'
                    },
                    Saturday: {
                        agenda: [
                            "07:30 - Breakfast in Luigi's Restaurant",
                            '07:50 - Meet at 1st tee on the North Course',
                            '08:18 - Group 3 tee off',
                            '13:00 - Time to relax and enjoy La Manga',
                            '18:30 - Dinner at La Cala - Ryan will book transfers from reception for 18:15'
                        ],
                        playingGroup: '3',
                        playingGroupId: '23',
                        restaurantLink:
                            'https://lamangaclub.com/web/menu/la-cala.pdf',
                        restaurantLinkLabel: 'La Cala Menu',
                        rulesLink: '/match-formats/texas-scramble',
                        rulesLinkLabel: 'Round Format & Rules',
                        title: '⛳ Saturday: Texas Scramble'
                    },
                    Sunday: {
                        agenda: [
                            "07:30 - Breakfast in Luigi's Restaurant",
                            "08:00 - Don't forget to wear your Ryder Cup polo",
                            '08:30 - Meet at 1st tee on the South Course',
                            '08:45 - Team photos',
                            '09:12 - Group 2 tee off',
                            '12:00 - Lunch in the 37 Spike and Sports Bar, and await your airport transfer'
                        ],
                        playingGroup: '2',
                        playingGroupId: '32',
                        rulesLink: '/match-formats/ryder-cup',
                        rulesLinkLabel: 'Match Format & Rules',
                        ryderCupMatch: '4',
                        ryderCupTeam: 'Europe',
                        title: '⛳ Sunday - The Ryder Cup'
                    }
                },
                name: "Ryan O'Connor",
                travel: {
                    arrival: {
                        'Transfer Companion': [
                            'Alec',
                            'Chris A',
                            'Matt A',
                            'Loui',
                            'Neil J',
                            'Gary'
                        ],
                        arrivalDate: '06/05/2020',
                        arrivalLocation: 'Alicante',
                        arrivalTime: '15:55',
                        pickUpPoint: 'TBC',
                        title: 'Arrival Details 🛬',
                        transferDetails: 'TBC',
                        transferTime: 'TBC'
                    },
                    departure: {
                        'Transfer Companion': [
                            'Alec',
                            'Chris A',
                            'Matt A',
                            'Louis',
                            'Neil J',
                            'Gary'
                        ],
                        departureDate: '11/05/2020',
                        departureLocation: 'Alicante',
                        departureTime: '12:40',
                        pickUpPoint: 'Hotel Reception',
                        title: 'Departure Details 🛫',
                        transferDetails: 'TBC',
                        transferTime: 'TBC'
                    }
                }
            },
            {
                accommodation: {
                    checkInDate: '08/05/2020',
                    checkInTime: '14:00',
                    checkOutDate: '10/05/2020',
                    checkOutTime: '10:00',
                    hotelName: 'La Manga Principe Filipe',
                    stayingWith: 'Nobody'
                },
                itinerary: {
                    Friday: {
                        agenda: [
                            '11:00 - Meet at hotel reception to get a transfer to the West Course',
                            '11:15 - Briefing at 1st tee',
                            '12:30 - Group 7 tee off',
                            "16:30 - Clubhouse Bar for a drink and rules explanation for Saturday's golf format",
                            '20:00 - Dinner in the Asia Restaurant with Prize Ceremony'
                        ],
                        playingGroup: '7',
                        playingGroupId: '17',
                        restaurantLink:
                            'https://lamangaclub.com/web/menu/asia.pdf',
                        restaurantLinkLabel: 'Asia Restaurant Menu',
                        rulesLink: '/match-formats/the-and-open',
                        rulesLinkLabel: 'Round Format & Rules',
                        title: '⛳ Friday: The AND Open'
                    },
                    Saturday: {
                        agenda: [
                            "07:30 - Breakfast in Luigi's Restaurant",
                            '07:50 - Meet at 1st tee on the North Course',
                            '08:00 - Group 1 tee off',
                            '13:00 - Time to relax and enjoy La Manga',
                            '18:30 - Dinner at La Cala - Ryan will book transfers from reception for 18:15'
                        ],
                        playingGroup: '1',
                        playingGroupId: '21',
                        restaurantLink:
                            'https://lamangaclub.com/web/menu/la-cala.pdf',
                        restaurantLinkLabel: 'La Cala Menu',
                        rulesLink: '/match-formats/texas-scramble',
                        rulesLinkLabel: 'Round Format & Rules',
                        title: '⛳ Saturday: Texas Scramble'
                    },
                    Sunday: {
                        agenda: [
                            "07:30 - Breakfast in Luigi's Restaurant",
                            "08:00 - Don't forget to wear your Ryder Cup polo",
                            '08:30 - Meet at 1st tee on the South Course',
                            '08:45 - Team photos',
                            '09:12 - Group 2 tee off',
                            '12:00 - Lunch in the 37 Spike and Sports Bar, and await your airport transfer'
                        ],
                        playingGroup: '2',
                        playingGroupId: '32',
                        rulesLink: '/match-formats/ryder-cup',
                        rulesLinkLabel: 'Match Format & Rules',
                        ryderCupMatch: '4',
                        ryderCupTeam: 'America',
                        title: '⛳ Sunday - The Ryder Cup'
                    }
                },
                name: 'Ajay Joshi',
                travel: {
                    arrival: {
                        'Transfer Companion': ['Nick', 'Tim'],
                        arrivalDate: '08/05/2020',
                        arrivalLocation: 'Alicante',
                        arrivalTime: '09:45',
                        pickUpPoint: 'TBC',
                        title: 'Arrival Details 🛬',
                        transferDetails: 'TBC',
                        transferTime: 'TBC'
                    },
                    departure: {
                        'Transfer Companion': ['Tony'],
                        departureDate: '10/05/2020',
                        departureLocation: 'Alicante',
                        departureTime: '21:20',
                        pickUpPoint: 'TBC',
                        title: 'Departure Details 🛫',
                        transferDetails: 'TBC',
                        transferTime: 'TBC'
                    }
                }
            },
            {
                accommodation: {
                    checkInDate: '08/05/2020',
                    checkInTime: '14:00',
                    checkOutDate: '10/05/2020',
                    checkOutTime: '10:00',
                    hotelName: 'La Manga Principe Filipe',
                    stayingWith: 'Chris A'
                },
                itinerary: {
                    Friday: {
                        agenda: [
                            '11:00 - Meet at hotel reception to get a transfer to the West Course',
                            '11:15 - Briefing at 1st tee',
                            '11:36 - Group 1 tee off',
                            "16:30 - Clubhouse Bar for a drink and rules explanation for Saturday's golf format",
                            '20:00 - Dinner in the Asia Restaurant with Prize Ceremony'
                        ],
                        playingGroup: '1',
                        playingGroupId: '11',
                        restaurantLink:
                            'https://lamangaclub.com/web/menu/asia.pdf',
                        restaurantLinkLabel: 'Asia Restaurant Menu',
                        rulesLink: '/match-formats/the-and-open',
                        rulesLinkLabel: 'Round Format & Rules',
                        title: '⛳ Friday: The AND Open'
                    },
                    Saturday: {
                        agenda: [
                            "07:30 - Breakfast in Luigi's Restaurant",
                            '07:50 - Meet at 1st tee on the North Course',
                            '08:27 - Group 4 tee off',
                            '13:00 - Time to relax and enjoy La Manga',
                            '18:30 - Dinner at La Cala - Ryan will book transfers from reception for 18:15'
                        ],
                        playingGroup: '4',
                        playingGroupId: '24',
                        restaurantLink:
                            'https://lamangaclub.com/web/menu/la-cala.pdf',
                        restaurantLinkLabel: 'La Cala Menu',
                        rulesLink: '/match-formats/texas-scramble',
                        rulesLinkLabel: 'Round Format & Rules',
                        title: '⛳ Saturday: Texas Scramble'
                    },
                    Sunday: {
                        agenda: [
                            "07:30 - Breakfast in Luigi's Restaurant",
                            "08:00 - Don't forget to wear your Ryder Cup polo",
                            '08:30 - Meet at 1st tee on the South Course',
                            '08:45 - Team photos',
                            '09:03 - Group 1 tee off',
                            '12:00 - Lunch in the 37 Spike and Sports Bar, and await your airport transfer'
                        ],
                        playingGroup: '1',
                        playingGroupId: '31',
                        rulesLink: '/match-formats/ryder-cup',
                        rulesLinkLabel: 'Match Format & Rules',
                        ryderCupMatch: '1',
                        ryderCupTeam: 'America',
                        title: '⛳ Sunday - The Ryder Cup'
                    }
                },
                name: 'Alec Buchanan',
                travel: {
                    arrival: {
                        'Transfer Companion': [
                            'Chris A',
                            'Gary',
                            'Louis',
                            'Matt A',
                            'Neil J',
                            'Ryan'
                        ],
                        arrivalDate: '06/05/2020',
                        arrivalLocation: 'Alicante',
                        arrivalTime: '15:55',
                        pickUpPoint: 'TBC',
                        title: 'Arrival Details 🛬',
                        transferDetails: 'TBC',
                        transferTime: 'TBC'
                    },
                    departure: {
                        'Transfer Companion': [
                            'Chris A',
                            'Gary',
                            'Louis',
                            'Matt A',
                            'Neil J',
                            'Ryan'
                        ],
                        departureDate: '11/05/2020',
                        departureLocation: 'Alicante',
                        departureTime: '12:40',
                        pickUpPoint: 'TBC',
                        title: 'Departure Details 🛫',
                        transferDetails: 'TBC',
                        transferTime: 'TBC'
                    }
                }
            },
            {
                accommodation: {
                    checkInDate: '08/05/2020',
                    checkInTime: '11:00',
                    checkOutDate: '10/05/2020',
                    checkOutTime: '10:00',
                    hotelName: 'La Manga Principe Filipe',
                    stayingWith: 'Nobody'
                },
                itinerary: {
                    Friday: {
                        agenda: [
                            '11:00 - Meet at hotel reception to get a transfer to the West Course',
                            '11:15 - Briefing at 1st tee',
                            '12:12 - Group 5 tee off',
                            "16:30 - Clubhouse Bar for a drink and rules explanation for Saturday's golf format",
                            '20:00 - Dinner in the Asia Restaurant with Prize Ceremony'
                        ],
                        playingGroup: '5',
                        playingGroupId: '15',
                        restaurantLink:
                            'https://lamangaclub.com/web/menu/asia.pdf',
                        restaurantLinkLabel: 'Asia Restaurant Menu',
                        rulesLink: '/match-formats/the-and-open',
                        rulesLinkLabel: 'Round Format & Rules',
                        title: '⛳ Friday: The AND Open'
                    },
                    Saturday: {
                        agenda: [
                            "07:30 - Breakfast in Luigi's Restaurant",
                            '07:50 - Meet at 1st tee on the North Course',
                            '08:54 - Group 7 tee off',
                            '13:00 - Time to relax and enjoy La Manga',
                            '18:30 - Dinner at La Cala - Ryan will book transfers from reception for 18:15'
                        ],
                        playingGroup: '7',
                        playingGroupId: '27',
                        restaurantLink:
                            'https://lamangaclub.com/web/menu/la-cala.pdf',
                        restaurantLinkLabel: 'La Cala Menu',
                        rulesLink: '/match-formats/texas-scramble',
                        rulesLinkLabel: 'Round Format & Rules',
                        title: '⛳ Saturday: Texas Scramble'
                    },
                    Sunday: {
                        agenda: [
                            "07:30 - Breakfast in Luigi's Restaurant",
                            "08:00 - Don't forget to wear your Ryder Cup polo",
                            '08:30 - Meet at 1st tee on the South Course',
                            '08:45 - Team photos',
                            '09:55 - Group 7 tee off',
                            '12:00 - Lunch in the 37 Spike and Sports Bar, and await your airport transfer'
                        ],
                        playingGroup: '7',
                        playingGroupId: '37',
                        rulesLink: '/match-formats/ryder-cup',
                        rulesLinkLabel: 'Match Format & Rules',
                        ryderCupMatch: '14',
                        ryderCupTeam: 'Europe',
                        title: '⛳ Sunday - The Ryder Cup'
                    }
                },
                name: 'Charlie-Sam Newman',
                travel: {
                    arrival: {
                        'Transfer Companion': ['Travlling Alone'],
                        arrivalDate: 'TBC',
                        arrivalLocation: 'TBC',
                        arrivalTime: 'TBC',
                        pickUpPoint: 'TBC',
                        title: 'Arrival Details 🛬',
                        transferDetails: 'TBC',
                        transferTime: 'TBC'
                    },
                    departure: {
                        'Transfer Companion': ['Travlling Alone'],
                        departureDate: 'TBC',
                        departureLocation: 'TBC',
                        departureTime: 'TBC',
                        pickUpPoint: 'Hotel Reception',
                        title: 'Departure Details 🛫',
                        transferDetails: 'TBC',
                        transferTime: 'TBC'
                    }
                }
            },
            {
                accommodation: {
                    checkInDate: '08/05/2020',
                    checkInTime: '14:00',
                    checkOutDate: '10/05/2020',
                    checkOutTime: '10:00',
                    hotelName: 'La Manga Principe Filipe',
                    stayingWith: 'Alec'
                },
                itinerary: {
                    Friday: {
                        agenda: [
                            '11:00 - Meet at hotel reception to get a transfer to the West Course',
                            '11:15 - Briefing at 1st tee',
                            '11:36 - Group 1 tee off',
                            "16:30 - Clubhouse Bar for a drink and rules explanation for Saturday's golf format",
                            '20:00 - Dinner in the Asia Restaurant with Prize Ceremony'
                        ],
                        playingGroup: '1',
                        playingGroupId: '11',
                        restaurantLink:
                            'https://lamangaclub.com/web/menu/asia.pdf',
                        restaurantLinkLabel: 'Asia Restaurant Menu',
                        rulesLink: '/match-formats/the-and-open',
                        rulesLinkLabel: 'Round Format & Rules',
                        title: '⛳ Friday: The AND Open'
                    },
                    Saturday: {
                        agenda: [
                            "07:30 - Breakfast in Luigi's Restaurant",
                            '07:50 - Meet at 1st tee on the North Course',
                            '08:27 - Group 4 tee off',
                            '13:00 - Time to relax and enjoy La Manga',
                            '18:30 - Dinner at La Cala - Ryan will book transfers from reception for 18:15'
                        ],
                        playingGroup: '4',
                        playingGroupId: '24',
                        restaurantLink:
                            'https://lamangaclub.com/web/menu/la-cala.pdf',
                        restaurantLinkLabel: 'La Cala Menu',
                        rulesLink: '/match-formats/texas-scramble',
                        rulesLinkLabel: 'Round Format & Rules',
                        title: '⛳ Saturday: Texas Scramble'
                    },
                    Sunday: {
                        agenda: [
                            "07:30 - Breakfast in Luigi's Restaurant",
                            "08:00 - Don't forget to wear your Ryder Cup polo",
                            '08:30 - Meet at 1st tee on the South Course',
                            '08:45 - Team photos',
                            '09:21 - Group 3 tee off',
                            '12:00 - Lunch in the 37 Spike and Sports Bar, and await your airport transfer'
                        ],
                        playingGroup: '3',
                        playingGroupId: '33',
                        rulesLink: '/match-formats/ryder-cup',
                        rulesLinkLabel: 'Match Format & Rules',
                        ryderCupMatch: '5',
                        ryderCupTeam: 'America',
                        title: '⛳ Sunday - The Ryder Cup'
                    }
                },
                name: 'Chris Alderson',
                travel: {
                    arrival: {
                        'Transfer Companion': [
                            'Alec',
                            'Gary',
                            'Louis',
                            'Matt A',
                            'Neil J',
                            'Ryan'
                        ],
                        arrivalDate: '06/05/2020',
                        arrivalLocation: 'Alicante',
                        arrivalTime: '15:55',
                        pickUpPoint: 'TBC',
                        title: 'Arrival Details 🛬',
                        transferDetails: 'TBC',
                        transferTime: 'TBC'
                    },
                    departure: {
                        'Transfer Companion': [
                            'Alec',
                            'Gary',
                            'Louis',
                            'Matt A',
                            'Neil J',
                            'Ryan'
                        ],
                        departureDate: '11/05/2020',
                        departureLocation: 'Alicante',
                        departureTime: '12:40',
                        pickUpPoint: 'TBC',
                        title: 'Departure Details 🛫',
                        transferDetails: 'TBC',
                        transferTime: 'TBC'
                    }
                }
            },
            {
                accommodation: {
                    checkInDate: '08/05/2020',
                    checkInTime: '14:00',
                    checkOutDate: '10/05/2020',
                    checkOutTime: '10:00',
                    hotelName: 'La Manga Principe Filipe',
                    stayingWith: 'Matt L'
                },
                itinerary: {
                    Friday: {
                        agenda: [
                            '11:00 - Meet at hotel reception to get a transfer to the West Course',
                            '11:15 - Briefing at 1st tee',
                            '12:21 - Group 6 tee off',
                            "16:30 - Clubhouse Bar for a drink and rules explanation for Saturday's golf format",
                            '20:00 - Dinner in the Asia Restaurant with Prize Ceremony'
                        ],
                        playingGroup: '6',
                        playingGroupId: '16',
                        restaurantLink:
                            'https://lamangaclub.com/web/menu/asia.pdf',
                        restaurantLinkLabel: 'Asia Restaurant Menu',
                        rulesLink: '/match-formats/the-and-open',
                        rulesLinkLabel: 'Round Format & Rules',
                        title: '⛳ Friday: The AND Open'
                    },
                    Saturday: {
                        agenda: [
                            "07:30 - Breakfast in Luigi's Restaurant",
                            '07:50 - Meet at 1st tee on the North Course',
                            '08:27 - Group 4 tee off',
                            '13:00 - Time to relax and enjoy La Manga',
                            '18:30 - Dinner at La Cala - Ryan will book transfers from reception for 18:15'
                        ],
                        playingGroup: '4',
                        playingGroupId: '24',
                        restaurantLink:
                            'https://lamangaclub.com/web/menu/la-cala.pdf',
                        restaurantLinkLabel: 'La Cala Menu',
                        rulesLink: '/match-formats/texas-scramble',
                        rulesLinkLabel: 'Round Format & Rules',
                        title: '⛳ Saturday: Texas Scramble'
                    },
                    Sunday: {
                        agenda: [
                            "07:30 - Breakfast in Luigi's Restaurant",
                            "08:00 - Don't forget to wear your Ryder Cup polo",
                            '08:30 - Meet at 1st tee on the South Course',
                            '08:45 - Team photos',
                            '09:46 - Group 6 tee off',
                            '12:00 - Lunch in the 37 Spike and Sports Bar, and await your airport transfer'
                        ],
                        playingGroup: '6',
                        playingGroupId: '36',
                        rulesLink: '/match-formats/ryder-cup',
                        rulesLinkLabel: 'Match Format & Rules',
                        ryderCupMatch: '11',
                        ryderCupTeam: 'America',
                        title: '⛳ Sunday - The Ryder Cup'
                    }
                },
                name: 'Chris Cahill',
                travel: {
                    arrival: {
                        'Transfer Companion': [
                            'Dave',
                            'James',
                            'Joe',
                            'Matt L',
                            'Tom H'
                        ],
                        arrivalDate: '07/05/2020',
                        arrivalLocation: 'Murcia',
                        arrivalTime: '14:40',
                        pickUpPoint: 'TBC',
                        title: 'Arrival Details 🛬',
                        transferDetails: 'TBC',
                        transferTime: 'TBC'
                    },
                    departure: {
                        'Transfer Companion': [
                            'Dave',
                            'James',
                            'Joe',
                            'Matt L',
                            'Tom H'
                        ],
                        departureDate: '10/05/2020',
                        departureLocation: 'Murcia',
                        departureTime: '21:45',
                        pickUpPoint: 'TBC',
                        title: 'Departure Details 🛫',
                        transferDetails: 'TBC',
                        transferTime: 'TBC'
                    }
                }
            },
            {
                accommodation: {
                    checkInDate: '08/05/2020',
                    checkInTime: '14:00',
                    checkOutDate: '10/05/2020',
                    checkOutTime: '10:00',
                    hotelName: 'La Manga Principe Filipe',
                    stayingWith: 'Tom H'
                },
                itinerary: {
                    Friday: {
                        agenda: [
                            '11:00 - Meet at hotel reception to get a transfer to the West Course',
                            '11:15 - Briefing at 1st tee',
                            '12:03 - Group 4 tee off',
                            "16:30 - Clubhouse Bar for a drink and rules explanation for Saturday's golf format",
                            '20:00 - Dinner in the Asia Restaurant with Prize Ceremony'
                        ],
                        playingGroup: '4',
                        playingGroupId: '14',
                        restaurantLink:
                            'https://lamangaclub.com/web/menu/asia.pdf',
                        restaurantLinkLabel: 'Asia Restaurant Menu',
                        rulesLink: '/match-formats/the-and-open',
                        rulesLinkLabel: 'Round Format & Rules',
                        title: '⛳ Friday: The AND Open'
                    },
                    Saturday: {
                        agenda: [
                            "07:30 - Breakfast in Luigi's Restaurant",
                            '07:50 - Meet at 1st tee on the North Course',
                            '08:45 - Group 6 tee off',
                            '13:00 - Time to relax and enjoy La Manga',
                            '18:30 - Dinner at La Cala - Ryan will book transfers from reception for 18:15'
                        ],
                        playingGroup: '6',
                        playingGroupId: '26',
                        restaurantLink:
                            'https://lamangaclub.com/web/menu/la-cala.pdf',
                        restaurantLinkLabel: 'La Cala Menu',
                        rulesLink: '/match-formats/texas-scramble',
                        rulesLinkLabel: 'Round Format & Rules',
                        title: '⛳ Saturday: Texas Scramble'
                    },
                    Sunday: {
                        agenda: [
                            "07:30 - Breakfast in Luigi's Restaurant",
                            "08:00 - Don't forget to wear your Ryder Cup polo",
                            '08:30 - Meet at 1st tee on the South Course',
                            '08:45 - Team photos',
                            '09:30 - Group 4 tee off',
                            '12:00 - Lunch in the 37 Spike and Sports Bar, and await your airport transfer'
                        ],
                        playingGroup: '4',
                        playingGroupId: '34',
                        rulesLink: '/match-formats/ryder-cup',
                        rulesLinkLabel: 'Match Format & Rules',
                        ryderCupMatch: '7',
                        ryderCupTeam: 'Europe',
                        title: '⛳ Sunday - The Ryder Cup'
                    }
                },
                name: 'Dave Gauntley',
                travel: {
                    arrival: {
                        'Transfer Companion': [
                            'Chris C',
                            'James',
                            'Joe',
                            'Matt L',
                            'Tom H'
                        ],
                        arrivalDate: '07/05/2020',
                        arrivalLocation: 'Murcia',
                        arrivalTime: '14:40',
                        pickUpPoint: 'TBC',
                        title: 'Arrival Details 🛬',
                        transferDetails: 'TBC',
                        transferTime: 'TBC'
                    },
                    departure: {
                        'Transfer Companion': [
                            'Chris C',
                            'James',
                            'Joe',
                            'Matt L',
                            'Tom H'
                        ],
                        departureDate: '10/05/2020',
                        departureLocation: 'Murcia',
                        departureTime: '21:45',
                        pickUpPoint: 'TBC',
                        title: 'Departure Details 🛫',
                        transferDetails: 'TBC',
                        transferTime: 'TBC'
                    }
                }
            },
            {
                accommodation: {
                    checkInDate: '08/05/2020',
                    checkInTime: '14:00',
                    checkOutDate: '10/05/2020',
                    checkOutTime: '10:00',
                    hotelName: 'La Manga Principe Filipe',
                    stayingWith: 'Louis'
                },
                itinerary: {
                    Friday: {
                        agenda: [
                            '11:00 - Meet at hotel reception to get a transfer to the West Course',
                            '11:15 - Briefing at 1st tee',
                            '11:54 - Group 3 tee off',
                            "16:30 - Clubhouse Bar for a drink and rules explanation for Saturday's golf format",
                            '20:00 - Dinner in the Asia Restaurant with Prize Ceremony'
                        ],
                        playingGroup: '3',
                        playingGroupId: '13',
                        restaurantLink:
                            'https://lamangaclub.com/web/menu/asia.pdf',
                        restaurantLinkLabel: 'Asia Restaurant Menu',
                        rulesLink: '/match-formats/the-and-open',
                        rulesLinkLabel: 'Round Format & Rules',
                        title: '⛳ Friday: The AND Open'
                    },
                    Saturday: {
                        agenda: [
                            "07:30 - Breakfast in Luigi's Restaurant",
                            '07:50 - Meet at 1st tee on the North Course',
                            '08:36 - Group 5 tee off',
                            '13:00 - Time to relax and enjoy La Manga',
                            '18:30 - Dinner at La Cala - Ryan will book transfers from reception for 18:15'
                        ],
                        playingGroup: '5',
                        playingGroupId: '25',
                        restaurantLink:
                            'https://lamangaclub.com/web/menu/la-cala.pdf',
                        restaurantLinkLabel: 'La Cala Menu',
                        rulesLink: '/match-formats/texas-scramble',
                        rulesLinkLabel: 'Round Format & Rules',
                        title: '⛳ Saturday: Texas Scramble'
                    },
                    Sunday: {
                        agenda: [
                            "07:30 - Breakfast in Luigi's Restaurant",
                            "08:00 - Don't forget to wear your Ryder Cup polo",
                            '08:30 - Meet at 1st tee on the South Course',
                            '08:45 - Team photos',
                            '09:21 - Group 3 tee off',
                            '12:00 - Lunch in the 37 Spike and Sports Bar, and await your airport transfer'
                        ],
                        playingGroup: '3',
                        playingGroupId: '33',
                        rulesLink: '/match-formats/ryder-cup',
                        rulesLinkLabel: 'Match Format & Rules',
                        ryderCupMatch: '6',
                        ryderCupTeam: 'Europe',
                        title: '⛳ Sunday - The Ryder Cup'
                    }
                },
                name: 'Gary Houlihan',
                travel: {
                    arrival: {
                        'Transfer Companion': [
                            'Alec',
                            'Chris A',
                            'Louis',
                            'Matt A',
                            'Neil J',
                            'Ryan'
                        ],
                        arrivalDate: '06/05/2020',
                        arrivalLocation: 'Alicante',
                        arrivalTime: '15:55',
                        pickUpPoint: 'TBC',
                        title: 'Arrival Details 🛬',
                        transferDetails: 'TBC',
                        transferTime: 'TBC'
                    },
                    departure: {
                        'Transfer Companion': [
                            'Alec',
                            'Chris A',
                            'Louis',
                            'Matt A',
                            'Neil J',
                            'Ryan'
                        ],
                        departureDate: '11/05/2020',
                        departureLocation: 'Alicante',
                        departureTime: '12:40',
                        pickUpPoint: 'TBC',
                        title: 'Departure Details 🛫',
                        transferDetails: 'TBC',
                        transferTime: 'TBC'
                    }
                }
            },
            {
                accommodation: {
                    checkInDate: '08/05/2020',
                    checkInTime: '14:00',
                    checkOutDate: '10/05/2020',
                    checkOutTime: '10:00',
                    hotelName: 'La Manga Principe Filipe',
                    stayingWith: 'Rich'
                },
                itinerary: {
                    Friday: {
                        agenda: [
                            '11:00 - Meet at hotel reception to get a transfer to the West Course',
                            '11:15 - Briefing at 1st tee',
                            '12:30 - Group 7 tee off',
                            "16:30 - Clubhouse Bar for a drink and rules explanation for Saturday's golf format",
                            '20:00 - Dinner in the Asia Restaurant with Prize Ceremony'
                        ],
                        playingGroup: '7',
                        playingGroupId: '17',
                        restaurantLink:
                            'https://lamangaclub.com/web/menu/asia.pdf',
                        restaurantLinkLabel: 'Asia Restaurant Menu',
                        rulesLink: '/match-formats/the-and-open',
                        rulesLinkLabel: 'Round Format & Rules',
                        title: '⛳ Friday: The AND Open'
                    },
                    Saturday: {
                        agenda: [
                            "07:30 - Breakfast in Luigi's Restaurant",
                            '07:50 - Meet at 1st tee on the North Course',
                            '08:45 - Group 6 tee off',
                            '13:00 - Time to relax and enjoy La Manga',
                            '18:30 - Dinner at La Cala - Ryan will book transfers from reception for 18:15'
                        ],
                        playingGroup: '6',
                        playingGroupId: '26',
                        restaurantLink:
                            'https://lamangaclub.com/web/menu/la-cala.pdf',
                        restaurantLinkLabel: 'La Cala Menu',
                        rulesLink: '/match-formats/texas-scramble',
                        rulesLinkLabel: 'Round Format & Rules',
                        title: '⛳ Saturday: Texas Scramble'
                    },
                    Sunday: {
                        agenda: [
                            "07:30 - Breakfast in Luigi's Restaurant",
                            "08:00 - Don't forget to wear your Ryder Cup polo",
                            '08:30 - Meet at 1st tee on the South Course',
                            '08:45 - Team photos',
                            '09:12 - Group 2 tee off',
                            '12:00 - Lunch in the 37 Spike and Sports Bar, and await your airport transfer'
                        ],
                        playingGroup: '2',
                        playingGroupId: '32',
                        rulesLink: '/match-formats/ryder-cup',
                        rulesLinkLabel: 'Match Format & Rules',
                        ryderCupMatch: '3',
                        ryderCupTeam: 'America',
                        title: '⛳ Sunday - The Ryder Cup'
                    }
                },
                name: 'Henry Joliffe',
                travel: {
                    arrival: {
                        'Transfer Companion': ['Rich'],
                        arrivalDate: '07/05/2020',
                        arrivalLocation: 'Murcia',
                        arrivalTime: '21:10',
                        pickUpPoint: 'TBC',
                        title: 'Arrival Details 🛬',
                        transferDetails: 'TBC',
                        transferTime: 'TBC'
                    },
                    departure: {
                        'Transfer Companion': ['Travelling alone'],
                        departureDate: '10/05/2020',
                        departureLocation: 'Murcia',
                        departureTime: '17:00',
                        pickUpPoint: 'TBC',
                        title: 'Departure Details 🛫',
                        transferDetails: 'TBC',
                        transferTime: 'TBC'
                    }
                }
            },
            {
                accommodation: {
                    checkInDate: '08/05/2020',
                    checkInTime: '11:00',
                    checkOutDate: '10/05/2020',
                    checkOutTime: '10:00',
                    hotelName: 'La Manga Principe Filipe',
                    stayingWith: 'James'
                },
                itinerary: {
                    Friday: {
                        agenda: [
                            '11:00 - Meet at hotel reception to get a transfer to the West Course',
                            '11:15 - Briefing at 1st tee',
                            '12:12 - Group 5 tee off',
                            "16:30 - Clubhouse Bar for a drink and rules explanation for Saturday's golf format",
                            '20:00 - Dinner in the Asia Restaurant with Prize Ceremony'
                        ],
                        playingGroup: '5',
                        playingGroupId: '15',
                        restaurantLink:
                            'https://lamangaclub.com/web/menu/asia.pdf',
                        restaurantLinkLabel: 'Asia Restaurant Menu',
                        rulesLink: '/match-formats/the-and-open',
                        rulesLinkLabel: 'Round Format & Rules',
                        title: '⛳ Friday: The AND Open'
                    },
                    Saturday: {
                        agenda: [
                            "07:30 - Breakfast in Luigi's Restaurant",
                            '07:50 - Meet at 1st tee on the North Course',
                            '08:09 - Group 2 tee off',
                            '13:00 - Time to relax and enjoy La Manga',
                            '18:30 - Dinner at La Cala - Ryan will book transfers from reception for 18:15'
                        ],
                        playingGroup: '2',
                        playingGroupId: '22',
                        restaurantLink:
                            'https://lamangaclub.com/web/menu/la-cala.pdf',
                        restaurantLinkLabel: 'La Cala Menu',
                        rulesLink: '/match-formats/texas-scramble',
                        rulesLinkLabel: 'Round Format & Rules',
                        title: '⛳ Saturday: Texas Scramble'
                    },
                    Sunday: {
                        agenda: [
                            "07:30 - Breakfast in Luigi's Restaurant",
                            "08:00 - Don't forget to wear your Ryder Cup polo",
                            '08:30 - Meet at 1st tee on the South Course',
                            '08:45 - Team photos',
                            '09:55 - Group 7 tee off',
                            '12:00 - Lunch in the 37 Spike and Sports Bar, and await your airport transfer'
                        ],
                        playingGroup: '7',
                        playingGroupId: '37',
                        rulesLink: '/match-formats/ryder-cup',
                        rulesLinkLabel: 'Match Format & Rules',
                        ryderCupMatch: '13',
                        ryderCupTeam: 'Europe',
                        title: '⛳ Sunday - The Ryder Cup'
                    }
                },
                name: 'Jack Aspden',
                travel: {
                    arrival: {
                        'Transfer Companion': ['Travlling Alone'],
                        arrivalDate: 'TBC',
                        arrivalLocation: 'TBC',
                        arrivalTime: 'TBC',
                        pickUpPoint: 'TBC',
                        title: 'Arrival Details 🛬',
                        transferDetails: 'TBC',
                        transferTime: 'TBC'
                    },
                    departure: {
                        'Transfer Companion': ['Travlling Alone'],
                        departureDate: 'TBC',
                        departureLocation: 'TBC',
                        departureTime: 'TBC',
                        pickUpPoint: 'Hotel Reception',
                        title: 'Departure Details 🛫',
                        transferDetails: 'TBC',
                        transferTime: 'TBC'
                    }
                }
            },
            {
                accommodation: {
                    checkInDate: '08/05/2020',
                    checkInTime: '14:00',
                    checkOutDate: '10/05/2020',
                    checkOutTime: '10:00',
                    hotelName: 'La Manga Principe Filipe',
                    stayingWith: 'Jack'
                },
                itinerary: {
                    Friday: {
                        agenda: [
                            '11:00 - Meet at hotel reception to get a transfer to the West Course',
                            '11:15 - Briefing at 1st tee',
                            '12:03 - Group 4 tee off',
                            "16:30 - Clubhouse Bar for a drink and rules explanation for Saturday's golf format",
                            '20:00 - Dinner in the Asia Restaurant with Prize Ceremony'
                        ],
                        playingGroup: '4',
                        playingGroupId: '14',
                        restaurantLink:
                            'https://lamangaclub.com/web/menu/asia.pdf',
                        restaurantLinkLabel: 'Asia Restaurant Menu',
                        rulesLink: '/match-formats/the-and-open',
                        rulesLinkLabel: 'Round Format & Rules',
                        title: '⛳ Friday: The AND Open'
                    },
                    Saturday: {
                        agenda: [
                            "07:30 - Breakfast in Luigi's Restaurant",
                            '07:50 - Meet at 1st tee on the North Course',
                            '08:09 - Group 2 tee off',
                            '13:00 - Time to relax and enjoy La Manga',
                            '18:30 - Dinner at La Cala - Ryan will book transfers from reception for 18:15'
                        ],
                        playingGroup: '2',
                        playingGroupId: '22',
                        restaurantLink:
                            'https://lamangaclub.com/web/menu/la-cala.pdf',
                        restaurantLinkLabel: 'La Cala Menu',
                        rulesLink: '/match-formats/texas-scramble',
                        rulesLinkLabel: 'Round Format & Rules',
                        title: '⛳ Saturday: Texas Scramble'
                    },
                    Sunday: {
                        agenda: [
                            "07:30 - Breakfast in Luigi's Restaurant",
                            "08:00 - Don't forget to wear your Ryder Cup polo",
                            '08:30 - Meet at 1st tee on the South Course',
                            '08:45 - Team photos',
                            '09:30 - Group 4 tee off',
                            '12:00 - Lunch in the 37 Spike and Sports Bar, and await your airport transfer'
                        ],
                        playingGroup: '4',
                        playingGroupId: '34',
                        rulesLink: '/match-formats/ryder-cup',
                        rulesLinkLabel: 'Match Format & Rules',
                        ryderCupMatch: '8',
                        ryderCupTeam: 'America',
                        title: '⛳ Sunday - The Ryder Cup'
                    }
                },
                name: 'James Locker',
                travel: {
                    arrival: {
                        'Transfer Companion': [
                            'Chris C',
                            'Dave',
                            'Joe',
                            'Matt L',
                            'Tom H'
                        ],
                        arrivalDate: '07/05/2020',
                        arrivalLocation: 'Murcia',
                        arrivalTime: '14:40',
                        pickUpPoint: 'TBC',
                        title: 'Arrival Details 🛬',
                        transferDetails: 'TBC',
                        transferTime: 'TBC'
                    },
                    departure: {
                        'Transfer Companion': [
                            'Chris C',
                            'Dave',
                            'Joe',
                            'Matt L',
                            'Tom H'
                        ],
                        departureDate: '10/05/2020',
                        departureLocation: 'Murcia',
                        departureTime: '21:45',
                        pickUpPoint: 'TBC',
                        title: 'Departure Details 🛫',
                        transferDetails: 'TBC',
                        transferTime: 'TBC'
                    }
                }
            },
            {
                accommodation: {
                    checkInDate: '08/05/2020',
                    checkInTime: '14:00',
                    checkOutDate: '10/05/2020',
                    checkOutTime: '10:00',
                    hotelName: 'La Manga Principe Filipe',
                    stayingWith: 'Nobody'
                },
                itinerary: {
                    Friday: {
                        agenda: [
                            '11:00 - Meet at hotel reception to get a transfer to the West Course',
                            '11:15 - Briefing at 1st tee',
                            '12:03 - Group 4 tee off',
                            "16:30 - Clubhouse Bar for a drink and rules explanation for Saturday's golf format",
                            '20:00 - Dinner in the Asia Restaurant with Prize Ceremony'
                        ],
                        playingGroup: '4',
                        playingGroupId: '14',
                        restaurantLink:
                            'https://lamangaclub.com/web/menu/asia.pdf',
                        restaurantLinkLabel: 'Asia Restaurant Menu',
                        rulesLink: '/match-formats/the-and-open',
                        rulesLinkLabel: 'Round Format & Rules',
                        title: '⛳ Friday: The AND Open'
                    },
                    Saturday: {
                        agenda: [
                            "07:30 - Breakfast in Luigi's Restaurant",
                            '07:50 - Meet at 1st tee on the North Course',
                            '08:54 - Group 7 tee off',
                            '13:00 - Time to relax and enjoy La Manga',
                            '18:30 - Dinner at La Cala - Ryan will book transfers from reception for 18:15'
                        ],
                        playingGroup: '7',
                        playingGroupId: '27',
                        restaurantLink:
                            'https://lamangaclub.com/web/menu/la-cala.pdf',
                        restaurantLinkLabel: 'La Cala Menu',
                        rulesLink: '/match-formats/texas-scramble',
                        rulesLinkLabel: 'Round Format & Rules',
                        title: '⛳ Saturday: Texas Scramble'
                    },
                    Sunday: {
                        agenda: [
                            "07:30 - Breakfast in Luigi's Restaurant",
                            "08:00 - Don't forget to wear your Ryder Cup polo",
                            '08:30 - Meet at 1st tee on the South Course',
                            '08:45 - Team photos',
                            '09:30 - Group 4 tee off',
                            '12:00 - Lunch in the 37 Spike and Sports Bar, and await your airport transfer'
                        ],
                        playingGroup: '4',
                        playingGroupId: '34',
                        rulesLink: '/match-formats/ryder-cup',
                        rulesLinkLabel: 'Match Format & Rules',
                        ryderCupMatch: '8',
                        ryderCupTeam: 'Europe',
                        title: '⛳ Sunday - The Ryder Cup'
                    }
                },
                name: 'Joe Ellis',
                travel: {
                    arrival: {
                        'Transfer Companion': [
                            'Chris C',
                            'James',
                            'James',
                            'Matt L',
                            'Tom H'
                        ],
                        arrivalDate: '07/05/2020',
                        arrivalLocation: 'Murcia',
                        arrivalTime: '14:40',
                        pickUpPoint: 'TBC',
                        title: 'Arrival Details 🛬',
                        transferDetails: 'TBC',
                        transferTime: 'TBC'
                    },
                    departure: {
                        'Transfer Companion': [
                            'Chris C',
                            'James',
                            'James',
                            'Matt L',
                            'Tom H'
                        ],
                        departureDate: '10/05/2020',
                        departureLocation: 'Murcia',
                        departureTime: '21:45',
                        pickUpPoint: 'TBC',
                        title: 'Departure Details 🛫',
                        transferDetails: 'TBC',
                        transferTime: 'TBC'
                    }
                }
            },
            {
                accommodation: {
                    checkInDate: '08/05/2020',
                    checkInTime: '11:00',
                    checkOutDate: '10/05/2020',
                    checkOutTime: '10:00',
                    hotelName: 'La Manga Principe Filipe',
                    stayingWith: 'Gary'
                },
                itinerary: {
                    Friday: {
                        agenda: [
                            '11:00 - Meet at hotel reception to get a transfer to the West Course',
                            '11:15 - Briefing at 1st tee',
                            '12:21 - Group 6 tee off',
                            "16:30 - Clubhouse Bar for a drink and rules explanation for Saturday's golf format",
                            '20:00 - Dinner in the Asia Restaurant with Prize Ceremony'
                        ],
                        playingGroup: '6',
                        playingGroupId: '16',
                        restaurantLink:
                            'https://lamangaclub.com/web/menu/asia.pdf',
                        restaurantLinkLabel: 'Asia Restaurant Menu',
                        rulesLink: '/match-formats/the-and-open',
                        rulesLinkLabel: 'Round Format & Rules',
                        title: '⛳ Friday: The AND Open'
                    },
                    Saturday: {
                        agenda: [
                            "07:30 - Breakfast in Luigi's Restaurant",
                            '07:50 - Meet at 1st tee on the North Course',
                            '08:09 - Group 2 tee off',
                            '13:00 - Time to relax and enjoy La Manga',
                            '18:30 - Dinner at La Cala - Ryan will book transfers from reception for 18:15'
                        ],
                        playingGroup: '2',
                        playingGroupId: '22',
                        restaurantLink:
                            'https://lamangaclub.com/web/menu/la-cala.pdf',
                        restaurantLinkLabel: 'La Cala Menu',
                        rulesLink: '/match-formats/texas-scramble',
                        rulesLinkLabel: 'Round Format & Rules',
                        title: '⛳ Saturday: Texas Scramble'
                    },
                    Sunday: {
                        agenda: [
                            "07:30 - Breakfast in Luigi's Restaurant",
                            "08:00 - Don't forget to wear your Ryder Cup polo",
                            '08:30 - Meet at 1st tee on the South Course',
                            '08:45 - Team photos',
                            '09:46 - Group 6 tee off',
                            '12:00 - Lunch in the 37 Spike and Sports Bar, and await your airport transfer'
                        ],
                        playingGroup: '6',
                        playingGroupId: '36',
                        rulesLink: '/match-formats/ryder-cup',
                        rulesLinkLabel: 'Match Format & Rules',
                        ryderCupMatch: '11',
                        ryderCupTeam: 'Europe',
                        title: '⛳ Sunday - The Ryder Cup'
                    }
                },
                name: 'Louis Bougeard',
                travel: {
                    arrival: {
                        'Transfer Companion': [
                            'Alec ',
                            'Chris A',
                            'Matt A',
                            'Ryan',
                            'Neil J',
                            'Gary'
                        ],
                        arrivalDate: '06/05/2020',
                        arrivalLocation: 'Alicante',
                        arrivalTime: '15:55',
                        pickUpPoint: 'TBC',
                        title: 'Arrival Details 🛬',
                        transferDetails: 'TBC',
                        transferTime: 'TBC'
                    },
                    departure: {
                        'Transfer Companion': [
                            'Alec ',
                            'Chris A',
                            'Matt A',
                            'Ryan',
                            'Neil J',
                            'Gary'
                        ],
                        departureDate: '11/05/2020',
                        departureLocation: 'Alicante',
                        departureTime: '12:40',
                        pickUpPoint: 'Hotel Reception',
                        title: 'Departure Details 🛫',
                        transferDetails: 'TBC',
                        transferTime: 'TBC'
                    }
                }
            },
            {
                accommodation: {
                    checkInDate: '08/05/2020',
                    checkInTime: '11:00',
                    checkOutDate: '10/05/2020',
                    checkOutTime: '10:00',
                    hotelName: 'La Manga Principe Filipe',
                    stayingWith: 'Nobody'
                },
                itinerary: {
                    Friday: {
                        agenda: [
                            '11:00 - Meet at hotel reception to get a transfer to the West Course',
                            '11:15 - Briefing at 1st tee',
                            '11:30 - No golf, so enjoy La Manga',
                            "16:30 - Clubhouse Bar for a drink and rules explanation for Saturday's golf format",
                            '20:00 - Dinner in the Asia Restaurant with Prize Ceremony'
                        ],
                        playingGroup: '',
                        playingGroupId: '',
                        restaurantLink:
                            'https://lamangaclub.com/web/menu/asia.pdf',
                        restaurantLinkLabel: 'Asia Restaurant Menu',
                        rulesLink: '/match-formats/the-and-open',
                        rulesLinkLabel: 'Round Format & Rules',
                        title: '⛳ Friday: The AND Open'
                    },
                    Saturday: {
                        agenda: [
                            "07:30 - Breakfast in Luigi's Restaurant",
                            '07:50 - Meet at 1st tee on the North Course',
                            '08:00 - No golf, so enjoy La Manga',
                            '13:00 - Time to relax and enjoy La Manga',
                            '18:30 - Dinner at La Cala - Ryan will book transfers from reception for 18:15'
                        ],
                        playingGroup: '',
                        playingGroupId: '',
                        restaurantLink:
                            'https://lamangaclub.com/web/menu/la-cala.pdf',
                        restaurantLinkLabel: 'La Cala Menu',
                        rulesLink: '/match-formats/texas-scramble',
                        rulesLinkLabel: 'Round Format & Rules',
                        title: '⛳ Saturday: Texas Scramble'
                    },
                    Sunday: {
                        agenda: [
                            "07:30 - Breakfast in Luigi's Restaurant",
                            "08:00 - Don't forget to wear your Ryder Cup polo",
                            '08:30 - Meet at 1st tee on the South Course',
                            '08:45 - Team photos',
                            '09:00 - No golf, so enjoy La Manga',
                            '12:00 - Lunch in the 37 Spike and Sports Bar, and await your airport transfer'
                        ],
                        playingGroup: '',
                        playingGroupId: '',
                        rulesLink: '/match-formats/ryder-cup',
                        rulesLinkLabel: 'Match Format & Rules',
                        ryderCupMatch: '',
                        ryderCupTeam: '',
                        title: '⛳ Sunday - The Ryder Cup'
                    }
                },
                name: 'Maggie Kelly ',
                travel: {
                    arrival: {
                        'Transfer Companion': ['Paramjit '],
                        arrivalDate: '07/05/2020',
                        arrivalLocation: 'Alicante',
                        arrivalTime: '12:50',
                        pickUpPoint: 'TBC',
                        title: 'Arrival Details 🛬',
                        transferDetails: 'TBC',
                        transferTime: 'TBC'
                    },
                    departure: {
                        'Transfer Companion': ['Paramjit ', 'Mike'],
                        departureDate: '10/05/2020',
                        departureLocation: 'Alicante',
                        departureTime: '18:05',
                        pickUpPoint: 'Hotel Reception',
                        title: 'Departure Details 🛫',
                        transferDetails: 'TBC',
                        transferTime: 'TBC'
                    }
                }
            },
            {
                accommodation: {
                    checkInDate: '08/05/2020',
                    checkInTime: '11:00',
                    checkOutDate: '10/05/2020',
                    checkOutTime: '10:00',
                    hotelName: 'La Manga Principe Filipe',
                    stayingWith: 'Ryan'
                },
                itinerary: {
                    Friday: {
                        agenda: [
                            '11:00 - Meet at hotel reception to get a transfer to the West Course',
                            '11:15 - Briefing at 1st tee',
                            '11:36 - Group 1 tee off',
                            "16:30 - Clubhouse Bar for a drink and rules explanation for Saturday's golf format",
                            '20:00 - Dinner in the Asia Restaurant with Prize Ceremony'
                        ],
                        playingGroup: '1',
                        playingGroupId: '11',
                        restaurantLink:
                            'https://lamangaclub.com/web/menu/asia.pdf',
                        restaurantLinkLabel: 'Asia Restaurant Menu',
                        rulesLink: '/match-formats/the-and-open',
                        rulesLinkLabel: 'Round Format & Rules',
                        title: '⛳ Friday: The AND Open'
                    },
                    Saturday: {
                        agenda: [
                            "07:30 - Breakfast in Luigi's Restaurant",
                            '07:50 - Meet at 1st tee on the North Course',
                            '08:36 - Group 5 tee off',
                            '13:00 - Time to relax and enjoy La Manga',
                            '18:30 - Dinner at La Cala - Ryan will book transfers from reception for 18:15'
                        ],
                        playingGroup: '5',
                        playingGroupId: '25',
                        restaurantLink:
                            'https://lamangaclub.com/web/menu/la-cala.pdf',
                        restaurantLinkLabel: 'La Cala Menu',
                        rulesLink: '/match-formats/texas-scramble',
                        rulesLinkLabel: 'Round Format & Rules',
                        title: '⛳ Saturday: Texas Scramble'
                    },
                    Sunday: {
                        agenda: [
                            "07:30 - Breakfast in Luigi's Restaurant",
                            "08:00 - Don't forget to wear your Ryder Cup polo",
                            '08:30 - Meet at 1st tee on the South Course',
                            '08:45 - Team photos',
                            '09:03 - Group 1 tee off',
                            '12:00 - Lunch in the 37 Spike and Sports Bar, and await your airport transfer'
                        ],
                        playingGroup: '1',
                        playingGroupId: '31',
                        rulesLink: '/match-formats/ryder-cup',
                        rulesLinkLabel: 'Match Format & Rules',
                        ryderCupMatch: '2',
                        ryderCupTeam: 'Europe',
                        title: '⛳ Sunday - The Ryder Cup'
                    }
                },
                name: 'Matt Archer',
                travel: {
                    arrival: {
                        'Transfer Companion': [
                            'Alec',
                            'Chris A ',
                            'Ryan',
                            'Louis',
                            'Neil',
                            'Gary'
                        ],
                        arrivalDate: '06/05/2020',
                        arrivalLocation: 'Alicante',
                        arrivalTime: '15:55',
                        pickUpPoint: 'TBC',
                        title: 'Arrival Details 🛬',
                        transferDetails: 'TBC',
                        transferTime: 'TBC'
                    },
                    departure: {
                        'Transfer Companion': [
                            'Alec',
                            'Chris A ',
                            'Ryan',
                            'Louis',
                            'Neil',
                            'Gary'
                        ],
                        departureDate: '11/05/2020',
                        departureLocation: 'Alicante',
                        departureTime: '12:40',
                        pickUpPoint: 'Hotel Reception',
                        title: 'Departure Details 🛫',
                        transferDetails: 'TBC',
                        transferTime: 'TBC'
                    }
                }
            },
            {
                accommodation: {
                    checkInDate: '08/05/2020',
                    checkInTime: '14:00',
                    checkOutDate: '10/05/2020',
                    checkOutTime: '10:00',
                    hotelName: 'La Manga Principe Filipe',
                    stayingWith: 'Chris C'
                },
                itinerary: {
                    Friday: {
                        agenda: [
                            '11:00 - Meet at hotel reception to get a transfer to the West Course',
                            '11:15 - Briefing at 1st tee',
                            '12:12 - Group 6 tee off',
                            "16:30 - Clubhouse Bar for a drink and rules explanation for Saturday's golf format",
                            '20:00 - Dinner in the Asia Restaurant with Prize Ceremony'
                        ],
                        playingGroup: '5',
                        playingGroupId: '15',
                        restaurantLink:
                            'https://lamangaclub.com/web/menu/asia.pdf',
                        restaurantLinkLabel: 'Asia Restaurant Menu',
                        rulesLink: '/match-formats/the-and-open',
                        rulesLinkLabel: 'Round Format & Rules',
                        title: '⛳ Friday: The AND Open'
                    },
                    Saturday: {
                        agenda: [
                            "07:30 - Breakfast in Luigi's Restaurant",
                            '07:50 - Meet at 1st tee on the North Course',
                            '08:36 - Group 5 tee off',
                            '13:00 - Time to relax and enjoy La Manga',
                            '18:30 - Dinner at La Cala - Ryan will book transfers from reception for 18:15'
                        ],
                        playingGroup: '5',
                        playingGroupId: '25',
                        restaurantLink:
                            'https://lamangaclub.com/web/menu/la-cala.pdf',
                        restaurantLinkLabel: 'La Cala Menu',
                        rulesLink: '/match-formats/texas-scramble',
                        rulesLinkLabel: 'Round Format & Rules',
                        title: '⛳ Saturday: Texas Scramble'
                    },
                    Sunday: {
                        agenda: [
                            "07:30 - Breakfast in Luigi's Restaurant",
                            "08:00 - Don't forget to wear your Ryder Cup polo",
                            '08:30 - Meet at 1st tee on the South Course',
                            '08:45 - Team photos',
                            '09:55 - Group 7 tee off',
                            '12:00 - Lunch in the 37 Spike and Sports Bar, and await your airport transfer'
                        ],
                        playingGroup: '7',
                        playingGroupId: '37',
                        rulesLink: '/match-formats/ryder-cup',
                        rulesLinkLabel: 'Match Format & Rules',
                        ryderCupMatch: '13',
                        ryderCupTeam: 'America',
                        title: '⛳ Sunday - The Ryder Cup'
                    }
                },
                name: 'Matt Lee',
                travel: {
                    arrival: {
                        'Transfer Companion': [
                            'Dave',
                            'James',
                            'Joe',
                            'Chris C',
                            'Tom H'
                        ],
                        arrivalDate: '07/05/2020',
                        arrivalLocation: 'Murcia',
                        arrivalTime: '14:40',
                        pickUpPoint: 'TBC',
                        title: 'Arrival Details 🛬',
                        transferDetails: 'TBC',
                        transferTime: 'TBC'
                    },
                    departure: {
                        'Transfer Companion': [
                            'Dave',
                            'James',
                            'Joe',
                            'Chris C',
                            'Tom H'
                        ],
                        departureDate: '10/05/2020',
                        departureLocation: 'Murcia',
                        departureTime: '21:45',
                        pickUpPoint: 'TBC',
                        title: 'Departure Details 🛫',
                        transferDetails: 'TBC',
                        transferTime: 'TBC'
                    }
                }
            },
            {
                accommodation: {
                    checkInDate: '08/05/2020',
                    checkInTime: '14:00',
                    checkOutDate: '10/05/2020',
                    checkOutTime: '10:00',
                    hotelName: 'La Manga Principe Filipe',
                    stayingWith: 'Nobody'
                },
                itinerary: {
                    Friday: {
                        agenda: [
                            '11:00 - Meet at hotel reception to get a transfer to the West Course',
                            '11:15 - Briefing at 1st tee',
                            '12:21 - Group 6 tee off',
                            "16:30 - Clubhouse Bar for a drink and rules explanation for Saturday's golf format",
                            '20:00 - Dinner in the Asia Restaurant with Prize Ceremony'
                        ],
                        playingGroup: '6',
                        playingGroupId: '16',
                        restaurantLink:
                            'https://lamangaclub.com/web/menu/asia.pdf',
                        restaurantLinkLabel: 'Asia Restaurant Menu',
                        rulesLink: '/match-formats/the-and-open',
                        rulesLinkLabel: 'Round Format & Rules',
                        title: '⛳ Friday: The AND Open'
                    },
                    Saturday: {
                        agenda: [
                            "07:30 - Breakfast in Luigi's Restaurant",
                            '07:50 - Meet at 1st tee on the North Course',
                            '08:36 - Group 5 tee off',
                            '13:00 - Time to relax and enjoy La Manga',
                            '18:30 - Dinner at La Cala - Ryan will book transfers from reception for 18:15'
                        ],
                        playingGroup: '5',
                        playingGroupId: '25',
                        restaurantLink:
                            'https://lamangaclub.com/web/menu/la-cala.pdf',
                        restaurantLinkLabel: 'La Cala Menu',
                        rulesLink: '/match-formats/texas-scramble',
                        rulesLinkLabel: 'Round Format & Rules',
                        title: '⛳ Saturday: Texas Scramble'
                    },
                    Sunday: {
                        agenda: [
                            "07:30 - Breakfast in Luigi's Restaurant",
                            "08:00 - Don't forget to wear your Ryder Cup polo",
                            '08:30 - Meet at 1st tee on the South Course',
                            '08:45 - Team photos',
                            '09:46 - Group 6 tee off',
                            '12:00 - Lunch in the 37 Spike and Sports Bar, and await your airport transfer'
                        ],
                        playingGroup: '6',
                        playingGroupId: '36',
                        rulesLink: '/match-formats/ryder-cup',
                        rulesLinkLabel: 'Match Format & Rules',
                        ryderCupMatch: '12',
                        ryderCupTeam: 'Europe',
                        title: '⛳ Sunday - The Ryder Cup'
                    }
                },
                name: 'Mike Pohlschmidt',
                travel: {
                    arrival: {
                        'Transfer Companion': ['Travelling alone'],
                        arrivalDate: '07/05/2020',
                        arrivalLocation: 'Alicante',
                        arrivalTime: '14:30',
                        pickUpPoint: 'TBC',
                        title: 'Arrival Details 🛬',
                        transferDetails: 'TBC',
                        transferTime: 'TBC'
                    },
                    departure: {
                        'Transfer Companion': ['Paramjit', 'Maggie'],
                        departureDate: '10/05/2020',
                        departureLocation: 'Alicante',
                        departureTime: '17:25',
                        pickUpPoint: 'TBC',
                        title: 'Departure Details 🛫',
                        transferDetails: 'TBC',
                        transferTime: 'TBC'
                    }
                }
            },
            {
                accommodation: {
                    checkInDate: '08/05/2020',
                    checkInTime: '11:00',
                    checkOutDate: '10/05/2020',
                    checkOutTime: '10:00',
                    hotelName: 'La Manga Principe Filipe',
                    stayingWith: 'Nobody'
                },
                itinerary: {
                    Friday: {
                        agenda: [
                            '11:00 - Meet at hotel reception to get a transfer to the West Course',
                            '11:15 - Briefing at 1st tee',
                            '11:54 - Group 3 tee off',
                            "16:30 - Clubhouse Bar for a drink and rules explanation for Saturday's golf format",
                            '20:00 - Dinner in the Asia Restaurant with Prize Ceremony'
                        ],
                        playingGroup: '3',
                        playingGroupId: '13',
                        restaurantLink:
                            'https://lamangaclub.com/web/menu/asia.pdf',
                        restaurantLinkLabel: 'Asia Restaurant Menu',
                        rulesLink: '/match-formats/the-and-open',
                        rulesLinkLabel: 'Round Format & Rules',
                        title: '⛳ Friday: The AND Open'
                    },
                    Saturday: {
                        agenda: [
                            "07:30 - Breakfast in Luigi's Restaurant",
                            '07:50 - Meet at 1st tee on the North Course',
                            '08:18 - Group 3 tee off',
                            '13:00 - Time to relax and enjoy La Manga',
                            '18:30 - Dinner at La Cala - Ryan will book transfers from reception for 18:15'
                        ],
                        playingGroup: '3',
                        playingGroupId: '23',
                        restaurantLink:
                            'https://lamangaclub.com/web/menu/la-cala.pdf',
                        restaurantLinkLabel: 'La Cala Menu',
                        rulesLink: '/match-formats/texas-scramble',
                        rulesLinkLabel: 'Round Format & Rules',
                        title: '⛳ Saturday: Texas Scramble'
                    },
                    Sunday: {
                        agenda: [
                            "07:30 - Breakfast in Luigi's Restaurant",
                            "08:00 - Don't forget to wear your Ryder Cup polo",
                            '08:30 - Meet at 1st tee on the South Course',
                            '08:45 - Team photos',
                            '09:39 - Group 5 tee off',
                            '12:00 - Lunch in the 37 Spike and Sports Bar, and await your airport transfer'
                        ],
                        playingGroup: '5',
                        playingGroupId: '35',
                        rulesLink: '/match-formats/ryder-cup',
                        rulesLinkLabel: 'Match Format & Rules',
                        ryderCupMatch: '9',
                        ryderCupTeam: 'America',
                        title: '⛳ Sunday - The Ryder Cup'
                    }
                },
                name: 'Nathan Giles',
                travel: {
                    arrival: {
                        'Transfer Companion': ['Tom G'],
                        arrivalDate: '06/05/2020',
                        arrivalLocation: 'Murcia',
                        arrivalTime: '20:35',
                        pickUpPoint: 'TBC',
                        title: 'Arrival Details 🛬',
                        transferDetails: 'TBC',
                        transferTime: 'TBC'
                    },
                    departure: {
                        'Transfer Companion': ['Tom G'],
                        departureDate: '11/05/2020',
                        departureLocation: 'Murcia',
                        departureTime: '11:45',
                        pickUpPoint: 'Hotel Reception',
                        title: 'Departure Details 🛫',
                        transferDetails: 'TBC',
                        transferTime: 'TBC'
                    }
                }
            },
            {
                accommodation: {
                    checkInDate: '08/05/2020',
                    checkInTime: '11:00',
                    checkOutDate: '10/05/2020',
                    checkOutTime: '10:00',
                    hotelName: 'La Manga Principe Filipe',
                    stayingWith: 'Tom G'
                },
                itinerary: {
                    Friday: {
                        agenda: [
                            '11:00 - Meet at hotel reception to get a transfer to the West Course',
                            '11:15 - Briefing at 1st tee',
                            '11:45 - Group 2 tee off',
                            "16:30 - Clubhouse Bar for a drink and rules explanation for Saturday's golf format",
                            '20:00 - Dinner in the Asia Restaurant with Prize Ceremony'
                        ],
                        playingGroup: '2',
                        playingGroupId: '12',
                        restaurantLink:
                            'https://lamangaclub.com/web/menu/asia.pdf',
                        restaurantLinkLabel: 'Asia Restaurant Menu',
                        rulesLink: '/match-formats/the-and-open',
                        rulesLinkLabel: 'Round Format & Rules',
                        title: '⛳ Friday: The AND Open'
                    },
                    Saturday: {
                        agenda: [
                            "07:30 - Breakfast in Luigi's Restaurant",
                            '07:50 - Meet at 1st tee on the North Course',
                            '08:45 - Group 6 tee off',
                            '13:00 - Time to relax and enjoy La Manga',
                            '18:30 - Dinner at La Cala - Ryan will book transfers from reception for 18:15'
                        ],
                        playingGroup: '6',
                        playingGroupId: '26',
                        restaurantLink:
                            'https://lamangaclub.com/web/menu/la-cala.pdf',
                        restaurantLinkLabel: 'La Cala Menu',
                        rulesLink: '/match-formats/texas-scramble',
                        rulesLinkLabel: 'Round Format & Rules',
                        title: '⛳ Saturday: Texas Scramble'
                    },
                    Sunday: {
                        agenda: [
                            "07:30 - Breakfast in Luigi's Restaurant",
                            "08:00 - Don't forget to wear your Ryder Cup polo",
                            '08:30 - Meet at 1st tee on the South Course',
                            '08:45 - Team photos',
                            '09:39 - Group 5 tee off',
                            '12:00 - Lunch in the 37 Spike and Sports Bar, and await your airport transfer'
                        ],
                        playingGroup: '5',
                        playingGroupId: '35',
                        rulesLink: '/match-formats/ryder-cup',
                        rulesLinkLabel: 'Match Format & Rules',
                        ryderCupMatch: '9',
                        ryderCupTeam: 'Europe',
                        title: '⛳ Sunday - The Ryder Cup'
                    }
                },
                name: 'Neil Japhtha',
                travel: {
                    arrival: {
                        'Transfer Companion': [
                            'Alec ',
                            'Chris A',
                            'Matt A',
                            'Louis',
                            'Nathan',
                            'Gary'
                        ],
                        arrivalDate: '06/05/2020',
                        arrivalLocation: 'Alicante',
                        arrivalTime: '15:55',
                        pickUpPoint: 'TBC',
                        title: 'Arrival Details 🛬',
                        transferDetails: 'TBC',
                        transferTime: 'TBC'
                    },
                    departure: {
                        'Transfer Companion': [
                            'Alec ',
                            'Chris A',
                            'Matt A',
                            'Louis',
                            'Nathan',
                            'Gary'
                        ],
                        departureDate: '11/05/2020',
                        departureLocation: 'Alicante',
                        departureTime: '12:40',
                        pickUpPoint: 'Hotel Reception',
                        title: 'Departure Details 🛫',
                        transferDetails: 'TBC',
                        transferTime: 'TBC'
                    }
                }
            },
            {
                accommodation: {
                    checkInDate: '08/05/2020',
                    checkInTime: '11:00',
                    checkOutDate: '10/05/2020',
                    checkOutTime: '10:00',
                    hotelName: 'La Manga Principe Filipe',
                    stayingWith: 'Nick'
                },
                itinerary: {
                    Friday: {
                        agenda: [
                            '11:00 - Meet at hotel reception to get a transfer to the West Course',
                            '11:15 - Briefing at 1st tee',
                            '11:54 - Group 3 tee off',
                            "16:30 - Clubhouse Bar for a drink and rules explanation for Saturday's golf format",
                            '20:00 - Dinner in the Asia Restaurant with Prize Ceremony'
                        ],
                        playingGroup: '3',
                        playingGroupId: '13',
                        restaurantLink:
                            'https://lamangaclub.com/web/menu/asia.pdf',
                        restaurantLinkLabel: 'Asia Restaurant Menu',
                        rulesLink: '/match-formats/the-and-open',
                        rulesLinkLabel: 'Round Format & Rules',
                        title: '⛳ Friday: The AND Open'
                    },
                    Saturday: {
                        agenda: [
                            "07:30 - Breakfast in Luigi's Restaurant",
                            '07:50 - Meet at 1st tee on the North Course',
                            '08:54 - Group 7 tee off',
                            '13:00 - Time to relax and enjoy La Manga',
                            '18:30 - Dinner at La Cala - Ryan will book transfers from reception for 18:15'
                        ],
                        playingGroup: '7',
                        playingGroupId: '27',
                        restaurantLink:
                            'https://lamangaclub.com/web/menu/la-cala.pdf',
                        restaurantLinkLabel: 'La Cala Menu',
                        rulesLink: '/match-formats/texas-scramble',
                        rulesLinkLabel: 'Round Format & Rules',
                        title: '⛳ Saturday: Texas Scramble'
                    },
                    Sunday: {
                        agenda: [
                            "07:30 - Breakfast in Luigi's Restaurant",
                            "08:00 - Don't forget to wear your Ryder Cup polo",
                            '08:30 - Meet at 1st tee on the South Course',
                            '08:45 - Team photos',
                            '09:03 - Group 1 tee off',
                            '12:00 - Lunch in the 37 Spike and Sports Bar, and await your airport transfer'
                        ],
                        playingGroup: '1',
                        playingGroupId: '31',
                        rulesLink: '/match-formats/ryder-cup',
                        rulesLinkLabel: 'Match Format & Rules',
                        ryderCupMatch: '2',
                        ryderCupTeam: 'America',
                        title: '⛳ Sunday - The Ryder Cup'
                    }
                },
                name: 'Neil McFarlane',
                travel: {
                    arrival: {
                        'Transfer Companion': ['TBC '],
                        arrivalDate: 'TBC',
                        arrivalLocation: 'TBC',
                        arrivalTime: 'TBC',
                        pickUpPoint: 'TBC',
                        title: 'Arrival Details 🛬',
                        transferDetails: 'TBC',
                        transferTime: 'TBC'
                    },
                    departure: {
                        'Transfer Companion': ['TBC '],
                        departureDate: 'TBC',
                        departureLocation: 'TBC',
                        departureTime: 'TBC',
                        pickUpPoint: 'Hotel Reception',
                        title: 'Departure Details 🛫',
                        transferDetails: 'TBC',
                        transferTime: 'TBC'
                    }
                }
            },
            {
                accommodation: {
                    checkInDate: '08/05/2020',
                    checkInTime: '11:00',
                    checkOutDate: '10/05/2020',
                    checkOutTime: '10:00',
                    hotelName: 'La Manga Principe Filipe',
                    stayingWith: 'Neil M'
                },
                itinerary: {
                    Friday: {
                        agenda: [
                            '11:00 - Meet at hotel reception to get a transfer to the West Course',
                            '11:15 - Briefing at 1st tee',
                            '12:30 - Group 7 tee off',
                            "16:30 - Clubhouse Bar for a drink and rules explanation for Saturday's golf format",
                            '20:00 - Dinner in the Asia Restaurant with Prize Ceremony'
                        ],
                        playingGroup: '7',
                        playingGroupId: '17',
                        restaurantLink:
                            'https://lamangaclub.com/web/menu/asia.pdf',
                        restaurantLinkLabel: 'Asia Restaurant Menu',
                        rulesLink: '/match-formats/the-and-open',
                        rulesLinkLabel: 'Round Format & Rules',
                        title: '⛳ Friday: The AND Open'
                    },
                    Saturday: {
                        agenda: [
                            "07:30 - Breakfast in Luigi's Restaurant",
                            '07:50 - Meet at 1st tee on the North Course',
                            '08:18 - Group 3 tee off',
                            '13:00 - Time to relax and enjoy La Manga',
                            '18:30 - Dinner at La Cala - Ryan will book transfers from reception for 18:15'
                        ],
                        playingGroup: '3',
                        playingGroupId: '23',
                        restaurantLink:
                            'https://lamangaclub.com/web/menu/la-cala.pdf',
                        restaurantLinkLabel: 'La Cala Menu',
                        rulesLink: '/match-formats/texas-scramble',
                        rulesLinkLabel: 'Round Format & Rules',
                        title: '⛳ Saturday: Texas Scramble'
                    },
                    Sunday: {
                        agenda: [
                            "07:30 - Breakfast in Luigi's Restaurant",
                            "08:00 - Don't forget to wear your Ryder Cup polo",
                            '08:30 - Meet at 1st tee on the South Course',
                            '08:45 - Team photos',
                            '09:21 - Group 3 tee off',
                            '12:00 - Lunch in the 37 Spike and Sports Bar, and await your airport transfer'
                        ],
                        playingGroup: '3',
                        playingGroupId: '33',
                        rulesLink: '/match-formats/ryder-cup',
                        rulesLinkLabel: 'Match Format & Rules',
                        ryderCupMatch: '5',
                        ryderCupTeam: 'Europe',
                        title: '⛳ Sunday - The Ryder Cup'
                    }
                },
                name: 'Nick Voss',
                travel: {
                    arrival: {
                        'Transfer Companion': ['Ajay ', 'Tim'],
                        arrivalDate: '08/05/2020',
                        arrivalLocation: 'Alicante',
                        arrivalTime: '09:45',
                        pickUpPoint: 'TBC',
                        title: 'Arrival Details 🛬',
                        transferDetails: 'TBC',
                        transferTime: 'TBC'
                    },
                    departure: {
                        'Transfer Companion': ['Rich ', 'Tim'],
                        departureDate: '10/05/2020',
                        departureLocation: 'Alicante',
                        departureTime: '15:55',
                        pickUpPoint: 'Hotel Reception',
                        title: 'Departure Details 🛫',
                        transferDetails: 'TBC',
                        transferTime: 'TBC'
                    }
                }
            },
            {
                accommodation: {
                    checkInDate: '08/05/2020',
                    checkInTime: '11:00',
                    checkOutDate: '10/05/2020',
                    checkOutTime: '10:00',
                    hotelName: 'La Manga Principe Filipe',
                    stayingWith: 'Nobody'
                },
                itinerary: {
                    Friday: {
                        agenda: [
                            '11:00 - Meet at hotel reception to get a transfer to the West Course',
                            '11:15 - Briefing at 1st tee',
                            '11:54 - Group 3 tee off',
                            "16:30 - Clubhouse Bar for a drink and rules explanation for Saturday's golf format",
                            '20:00 - Dinner in the Asia Restaurant with Prize Ceremony'
                        ],
                        playingGroup: '3',
                        playingGroupId: '13',
                        restaurantLink:
                            'https://lamangaclub.com/web/menu/asia.pdf',
                        restaurantLinkLabel: 'Asia Restaurant Menu',
                        rulesLink: '/match-formats/the-and-open',
                        rulesLinkLabel: 'Round Format & Rules',
                        title: '⛳ Friday: The AND Open'
                    },
                    Saturday: {
                        agenda: [
                            "07:30 - Breakfast in Luigi's Restaurant",
                            '07:50 - Meet at 1st tee on the North Course',
                            '08:00 - Group 1 tee off',
                            '13:00 - Time to relax and enjoy La Manga',
                            '18:30 - Dinner at La Cala - Ryan will book transfers from reception for 18:15'
                        ],
                        playingGroup: '1',
                        playingGroupId: '21',
                        restaurantLink:
                            'https://lamangaclub.com/web/menu/la-cala.pdf',
                        restaurantLinkLabel: 'La Cala Menu',
                        rulesLink: '/match-formats/texas-scramble',
                        rulesLinkLabel: 'Round Format & Rules',
                        title: '⛳ Saturday: Texas Scramble'
                    },
                    Sunday: {
                        agenda: [
                            "07:30 - Breakfast in Luigi's Restaurant",
                            "08:00 - Don't forget to wear your Ryder Cup polo",
                            '08:30 - Meet at 1st tee on the South Course',
                            '08:45 - Team photos',
                            '09:30 - Group 4 tee off',
                            '12:00 - Lunch in the 37 Spike and Sports Bar, and await your airport transfer'
                        ],
                        playingGroup: '4',
                        playingGroupId: '34',
                        rulesLink: '/match-formats/ryder-cup',
                        rulesLinkLabel: 'Match Format & Rules',
                        ryderCupMatch: '7',
                        ryderCupTeam: 'America',
                        title: '⛳ Sunday - The Ryder Cup'
                    }
                },
                name: 'Paramjit Uppal',
                travel: {
                    arrival: {
                        'Transfer Companion': ['Maggie '],
                        arrivalDate: '07/05/2020',
                        arrivalLocation: 'Alicante',
                        arrivalTime: '12:50',
                        pickUpPoint: 'TBC',
                        title: 'Arrival Details 🛬',
                        transferDetails: 'TBC',
                        transferTime: 'TBC'
                    },
                    departure: {
                        'Transfer Companion': ['Maggie ', 'Mike'],
                        departureDate: '10/05/2020',
                        departureLocation: 'Alicante',
                        departureTime: '18:05',
                        pickUpPoint: 'Hotel Reception',
                        title: 'Departure Details 🛫',
                        transferDetails: 'TBC',
                        transferTime: 'TBC'
                    }
                }
            },
            {
                accommodation: {
                    checkInDate: '08/05/2020',
                    checkInTime: '11:00',
                    checkOutDate: '10/05/2020',
                    checkOutTime: '10:00',
                    hotelName: 'La Manga Principe Filipe',
                    stayingWith: 'Nobody'
                },
                itinerary: {
                    Friday: {
                        agenda: [
                            '11:00 - Meet at hotel reception to get a transfer to the West Course',
                            '11:15 - Briefing at 1st tee',
                            '12:03 - Group 4 tee off',
                            "16:30 - Clubhouse Bar for a drink and rules explanation for Saturday's golf format",
                            '20:00 - Dinner in the Asia Restaurant with Prize Ceremony'
                        ],
                        playingGroup: '4',
                        playingGroupId: '14',
                        restaurantLink:
                            'https://lamangaclub.com/web/menu/asia.pdf',
                        restaurantLinkLabel: 'Asia Restaurant Menu',
                        rulesLink: '/match-formats/the-and-open',
                        rulesLinkLabel: 'Round Format & Rules',
                        title: '⛳ Friday: The AND Open'
                    },
                    Saturday: {
                        agenda: [
                            "07:30 - Breakfast in Luigi's Restaurant",
                            '07:50 - Meet at 1st tee on the North Course',
                            '08:45 - Group 6 tee off',
                            '13:00 - Time to relax and enjoy La Manga',
                            '18:30 - Dinner at La Cala - Ryan will book transfers from reception for 18:15'
                        ],
                        playingGroup: '6',
                        playingGroupId: '26',
                        restaurantLink:
                            'https://lamangaclub.com/web/menu/la-cala.pdf',
                        restaurantLinkLabel: 'La Cala Menu',
                        rulesLink: '/match-formats/texas-scramble',
                        rulesLinkLabel: 'Round Format & Rules',
                        title: '⛳ Saturday: Texas Scramble'
                    },
                    Sunday: {
                        agenda: [
                            "07:30 - Breakfast in Luigi's Restaurant",
                            "08:00 - Don't forget to wear your Ryder Cup polo",
                            '08:30 - Meet at 1st tee on the South Course',
                            '08:45 - Team photos',
                            '09:45 - Group 6 tee off',
                            '12:00 - Lunch in the 37 Spike and Sports Bar, and await your airport transfer'
                        ],
                        playingGroup: '6',
                        playingGroupId: '36',
                        rulesLink: '/match-formats/ryder-cup',
                        rulesLinkLabel: 'Match Format & Rules',
                        ryderCupMatch: '12',
                        ryderCupTeam: 'America',
                        title: '⛳ Sunday - The Ryder Cup'
                    }
                },
                name: 'Prag Patel',
                travel: {
                    arrival: {
                        'Transfer Companion': ['TBC'],
                        arrivalDate: 'TBC',
                        arrivalLocation: 'TBC',
                        arrivalTime: 'TBC',
                        pickUpPoint: 'TBC',
                        title: 'Arrival Details 🛬',
                        transferDetails: 'TBC',
                        transferTime: 'TBC'
                    },
                    departure: {
                        'Transfer Companion': ['Travlling Alone'],
                        departureDate: 'TBC',
                        departureLocation: 'TBC',
                        departureTime: 'TBC',
                        pickUpPoint: 'Hotel Reception',
                        title: 'Departure Details 🛫',
                        transferDetails: 'TBC',
                        transferTime: 'TBC'
                    }
                }
            },
            {
                accommodation: {
                    checkInDate: '08/05/2020',
                    checkInTime: '14:00',
                    checkOutDate: '10/05/2020',
                    checkOutTime: '10:00',
                    hotelName: 'La Manga Principe Filipe',
                    stayingWith: 'Henry'
                },
                itinerary: {
                    Friday: {
                        agenda: [
                            '11:00 - Meet at hotel reception to get a transfer to the West Course',
                            '11:15 - Briefing at 1st tee',
                            '12:12 - Group 5 tee off',
                            "16:30 - Clubhouse Bar for a drink and rules explanation for Saturday's golf format",
                            '20:00 - Dinner in the Asia Restaurant with Prize Ceremony'
                        ],
                        playingGroup: '5',
                        playingGroupId: '15',
                        restaurantLink:
                            'https://lamangaclub.com/web/menu/asia.pdf',
                        restaurantLinkLabel: 'Asia Restaurant Menu',
                        rulesLink: '/match-formats/the-and-open',
                        rulesLinkLabel: 'Round Format & Rules',
                        title: '⛳ Friday: The AND Open'
                    },
                    Saturday: {
                        agenda: [
                            "07:30 - Breakfast in Luigi's Restaurant",
                            '07:50 - Meet at 1st tee on the North Course',
                            '08:18 - Group 3 tee off',
                            '13:00 - Time to relax and enjoy La Manga',
                            '18:30 - Dinner at La Cala - Ryan will book transfers from reception for 18:15'
                        ],
                        playingGroup: '3',
                        playingGroupId: '23',
                        restaurantLink:
                            'https://lamangaclub.com/web/menu/la-cala.pdf',
                        restaurantLinkLabel: 'La Cala Menu',
                        rulesLink: '/match-formats/texas-scramble',
                        rulesLinkLabel: 'Round Format & Rules',
                        title: '⛳ Saturday: Texas Scramble'
                    },
                    Sunday: {
                        agenda: [
                            "07:30 - Breakfast in Luigi's Restaurant",
                            "08:00 - Don't forget to wear your Ryder Cup polo",
                            '08:30 - Meet at 1st tee on the South Course',
                            '08:45 - Team photos',
                            '09:12 - Group 2 tee off',
                            '12:00 - Lunch in the 37 Spike and Sports Bar, and await your airport transfer'
                        ],
                        playingGroup: '2',
                        playingGroupId: '32',
                        rulesLink: '/match-formats/ryder-cup',
                        rulesLinkLabel: 'Match Format & Rules',
                        ryderCupMatch: '4',
                        ryderCupTeam: 'Europe',
                        title: '⛳ Sunday - The Ryder Cup'
                    }
                },
                name: 'Richard Boyd',
                travel: {
                    arrival: {
                        'Transfer Companion': ['Henry'],
                        arrivalDate: '07/05/2020',
                        arrivalLocation: 'Murcia',
                        arrivalTime: '21:10',
                        pickUpPoint: 'TBC',
                        title: 'Arrival Details 🛬',
                        transferDetails: 'TBC',
                        transferTime: 'TBC'
                    },
                    departure: {
                        'Transfer Companion': ['Nick', 'Tim'],
                        departureDate: '16/05/2020',
                        departureLocation: 'Barcelona',
                        departureTime: '21:50',
                        pickUpPoint: 'TBC',
                        title: 'Departure Details 🛫',
                        transferDetails:
                            'Transfer with Tim and Nick to Alicante airport',
                        transferTime: 'TBC'
                    }
                }
            },
            {
                accommodation: {
                    checkInDate: '08/05/2020',
                    checkInTime: '14:00',
                    checkOutDate: '10/05/2020',
                    checkOutTime: '10:00',
                    hotelName: 'La Manga Principe Filipe',
                    stayingWith: 'Nobody'
                },
                itinerary: {
                    Friday: {
                        agenda: [
                            '11:00 - Meet at hotel reception to get a transfer to the West Course',
                            '11:15 - Briefing at 1st tee',
                            '12:30 - Group 7 tee off',
                            "16:30 - Clubhouse Bar for a drink and rules explanation for Saturday's golf format",
                            '20:00 - Dinner in the Asia Restaurant with Prize Ceremony'
                        ],
                        playingGroup: '7',
                        playingGroupId: '17',
                        restaurantLink:
                            'https://lamangaclub.com/web/menu/asia.pdf',
                        restaurantLinkLabel: 'Asia Restaurant Menu',
                        rulesLink: '/match-formats/the-and-open',
                        rulesLinkLabel: 'Round Format & Rules',
                        title: '⛳ Friday: The AND Open'
                    },
                    Saturday: {
                        agenda: [
                            "07:30 - Breakfast in Luigi's Restaurant",
                            '07:50 - Meet at 1st tee on the North Course',
                            '08:27 - Group 4 tee off',
                            '13:00 - Time to relax and enjoy La Manga',
                            '18:30 - Dinner at La Cala - Ryan will book transfers from reception for 18:15'
                        ],
                        playingGroup: '4',
                        playingGroupId: '24',
                        restaurantLink:
                            'https://lamangaclub.com/web/menu/la-cala.pdf',
                        restaurantLinkLabel: 'La Cala Menu',
                        rulesLink: '/match-formats/texas-scramble',
                        rulesLinkLabel: 'Round Format & Rules',
                        title: '⛳ Saturday: Texas Scramble'
                    },
                    Sunday: {
                        agenda: [
                            "07:30 - Breakfast in Luigi's Restaurant",
                            "08:00 - Don't forget to wear your Ryder Cup polo",
                            '08:30 - Meet at 1st tee on the South Course',
                            '08:45 - Team photos',
                            '09:12 - Group 2 tee off',
                            '12:00 - Lunch in the 37 Spike and Sports Bar, and await your airport transfer'
                        ],
                        playingGroup: '3',
                        playingGroupId: '33',
                        rulesLink: '/match-formats/ryder-cup',
                        rulesLinkLabel: 'Match Format & Rules',
                        ryderCupMatch: '6',
                        ryderCupTeam: 'America',
                        title: '⛳ Sunday - The Ryder Cup'
                    }
                },
                name: 'Tim Hatton',
                travel: {
                    arrival: {
                        'Transfer Companion': ['Ajay', 'Nick'],
                        arrivalDate: '08/05/2020',
                        arrivalLocation: 'Alicante',
                        arrivalTime: '09:20',
                        pickUpPoint: 'TBC',
                        title: 'Arrival Details 🛬',
                        transferDetails: 'TBC',
                        transferTime: 'TBC'
                    },
                    departure: {
                        'Transfer Companion': ['Nick', 'Rich'],
                        departureDate: '10/05/2020',
                        departureLocation: 'Alicante',
                        departureTime: '15:55',
                        pickUpPoint: 'TBC',
                        title: 'Departure Details 🛫',
                        transferDetails: 'TBC',
                        transferTime: 'TBC'
                    }
                }
            },
            {
                accommodation: {
                    checkInDate: '08/05/2020',
                    checkInTime: '11:00',
                    checkOutDate: '10/05/2020',
                    checkOutTime: '10:00',
                    hotelName: 'La Manga Principe Filipe',
                    stayingWith: 'Neil J'
                },
                itinerary: {
                    Friday: {
                        agenda: [
                            '11:00 - Meet at hotel reception to get a transfer to the West Course',
                            '11:15 - Briefing at 1st tee',
                            '12:21 - Group 6 tee off',
                            "16:30 - Clubhouse Bar for a drink and rules explanation for Saturday's golf format",
                            '20:00 - Dinner in the Asia Restaurant with Prize Ceremony'
                        ],
                        playingGroup: '6',
                        playingGroupId: '16',
                        restaurantLink:
                            'https://lamangaclub.com/web/menu/asia.pdf',
                        restaurantLinkLabel: 'Asia Restaurant Menu',
                        rulesLink: '/match-formats/the-and-open',
                        rulesLinkLabel: 'Round Format & Rules',
                        title: '⛳ Friday: The AND Open'
                    },
                    Saturday: {
                        agenda: [
                            "07:30 - Breakfast in Luigi's Restaurant",
                            '07:50 - Meet at 1st tee on the North Course',
                            '08:09 - Group 2 tee off',
                            '13:00 - Time to relax and enjoy La Manga',
                            '18:30 - Dinner at La Cala - Ryan will book transfers from reception for 18:15'
                        ],
                        playingGroup: '2',
                        playingGroupId: '22',
                        restaurantLink:
                            'https://lamangaclub.com/web/menu/la-cala.pdf',
                        restaurantLinkLabel: 'La Cala Menu',
                        rulesLink: '/match-formats/texas-scramble',
                        rulesLinkLabel: 'Round Format & Rules',
                        title: '⛳ Saturday: Texas Scramble'
                    },
                    Sunday: {
                        agenda: [
                            "07:30 - Breakfast in Luigi's Restaurant",
                            "08:00 - Don't forget to wear your Ryder Cup polo",
                            '08:30 - Meet at 1st tee on the South Course',
                            '08:45 - Team photos',
                            '09:39 - Group 5 tee off',
                            '12:00 - Lunch in the 37 Spike and Sports Bar, and await your airport transfer'
                        ],
                        playingGroup: '5',
                        playingGroupId: '35',
                        rulesLink: '/match-formats/ryder-cup',
                        rulesLinkLabel: 'Match Format & Rules',
                        ryderCupMatch: '10',
                        ryderCupTeam: 'Europe',
                        title: '⛳ Sunday - The Ryder Cup'
                    }
                },
                name: 'Tom Gallagher',
                travel: {
                    arrival: {
                        'Transfer Companion': ['Tom G'],
                        arrivalDate: '06/05/2020',
                        arrivalLocation: 'Murcia',
                        arrivalTime: '20:35',
                        pickUpPoint: 'TBC',
                        title: 'Arrival Details 🛬',
                        transferDetails: 'TBC',
                        transferTime: 'TBC'
                    },
                    departure: {
                        'Transfer Companion': ['Tom G'],
                        departureDate: '11/05/2020',
                        departureLocation: 'Murcia',
                        departureTime: '11:45',
                        pickUpPoint: 'Hotel Reception',
                        title: 'Departure Details 🛫',
                        transferDetails: 'TBC',
                        transferTime: 'TBC'
                    }
                }
            },
            {
                accommodation: {
                    checkInDate: '08/05/2020',
                    checkInTime: '14:00',
                    checkOutDate: '10/05/2020',
                    checkOutTime: '10:00',
                    hotelName: 'La Manga Principe Filipe',
                    stayingWith: 'Dave'
                },
                itinerary: {
                    Friday: {
                        agenda: [
                            '11:00 - Meet at hotel reception to get a transfer to the West Course',
                            '11:15 - Briefing at 1st tee',
                            '11:45 - Group 2 tee off',
                            "16:30 - Clubhouse Bar for a dBriefingrink and rules explanation for Saturday's golf format",
                            '20:00 - Dinner in the Asia Restaurant with Prize Ceremony'
                        ],
                        playingGroup: '2',
                        playingGroupId: '12',
                        restaurantLink:
                            'https://lamangaclub.com/web/menu/asia.pdf',
                        restaurantLinkLabel: 'Asia Restaurant Menu',
                        rulesLink: '/match-formats/the-and-open',
                        rulesLinkLabel: 'Round Format & Rules',
                        title: '⛳ Friday: The AND Open'
                    },
                    Saturday: {
                        agenda: [
                            "07:30 - Breakfast in Luigi's Restaurant",
                            '07:50 - Meet at 1st tee on the North Course',
                            '08:00 - Group 1 tee off',
                            '13:00 - Time to relax and enjoy La Manga',
                            '18:30 - Dinner at La Cala - Ryan will book transfers from reception for 18:15'
                        ],
                        playingGroup: '1',
                        playingGroupId: '21',
                        restaurantLink:
                            'https://lamangaclub.com/web/menu/la-cala.pdf',
                        restaurantLinkLabel: 'La Cala Menu',
                        rulesLink: '/match-formats/texas-scramble',
                        rulesLinkLabel: 'Round Format & Rules',
                        title: '⛳ Saturday: Texas Scramble'
                    },
                    Sunday: {
                        agenda: [
                            "07:30 - Breakfast in Luigi's Restaurant",
                            "08:00 - Don't forget to wear your Ryder Cup polo",
                            '08:30 - Meet at 1st tee on the South Course',
                            '08:45 - Team photos',
                            '09:55 - Group 7 tee off',
                            '12:00 - Lunch in the 37 Spike and Sports Bar, and await your airport transfer'
                        ],
                        playingGroup: '7',
                        playingGroupId: '37',
                        rulesLink: '/match-formats/ryder-cup',
                        rulesLinkLabel: 'Match Format & Rules',
                        ryderCupMatch: '14',
                        ryderCupTeam: 'America',
                        title: '⛳ Sunday - The Ryder Cup'
                    }
                },
                name: 'Tom Hanson',
                travel: {
                    arrival: {
                        'Transfer Companion': [
                            'Dave',
                            'James',
                            'Joe',
                            'Matt L',
                            'Chris C'
                        ],
                        arrivalDate: '07/05/2020',
                        arrivalLocation: 'Murcia',
                        arrivalTime: '14:40',
                        pickUpPoint: 'TBC',
                        title: 'Arrival Details 🛬',
                        transferDetails: 'TBC',
                        transferTime: 'TBC'
                    },
                    departure: {
                        'Transfer Companion': [
                            'Dave',
                            'James',
                            'Joe',
                            'Matt L',
                            'Chris C'
                        ],
                        departureDate: '10/05/2020',
                        departureLocation: 'Murcia',
                        departureTime: '21:45',
                        pickUpPoint: 'TBC',
                        title: 'Departure Details 🛫',
                        transferDetails: 'TBC',
                        transferTime: 'TBC'
                    }
                }
            },
            {
                accommodation: {
                    checkInDate: '08/05/2020',
                    checkInTime: '14:00',
                    checkOutDate: '10/05/2020',
                    checkOutTime: '10:00',
                    hotelName: 'La Manga Principe Filipe',
                    stayingWith: 'Nobody'
                },
                itinerary: {
                    Friday: {
                        agenda: [
                            '11:00 - Meet at hotel reception to get a transfer to the West Course',
                            '11:15 - Briefing at 1st tee',
                            '11:45 - Group 2 tee off',
                            "16:30 - Clubhouse Bar for a drink and rules explanation for Saturday's golf format",
                            '20:00 - Dinner in the Asia Restaurant with Prize Ceremony'
                        ],
                        playingGroup: '2',
                        playingGroupId: '12',
                        restaurantLink:
                            'https://lamangaclub.com/web/menu/asia.pdf',
                        restaurantLinkLabel: 'Asia Restaurant Menu',
                        rulesLink: '/match-formats/the-and-open',
                        rulesLinkLabel: 'Round Format & Rules',
                        title: '⛳ Friday: The AND Open'
                    },
                    Saturday: {
                        agenda: [
                            "07:30 - Breakfast in Luigi's Restaurant",
                            '07:50 - Meet at 1st tee on the North Course',
                            '08:00 - Group 1 tee off',
                            '13:00 - Time to relax and enjoy La Manga',
                            '18:30 - Dinner at La Cala - Ryan will book transfers from reception for 18:15'
                        ],
                        playingGroup: '1',
                        playingGroupId: '21',
                        restaurantLink:
                            'https://lamangaclub.com/web/menu/la-cala.pdf',
                        restaurantLinkLabel: 'La Cala Menu',
                        rulesLink: '/match-formats/texas-scramble',
                        rulesLinkLabel: 'Round Format & Rules',
                        title: '⛳ Saturday: Texas Scramble'
                    },
                    Sunday: {
                        agenda: [
                            "07:30 - Breakfast in Luigi's Restaurant",
                            "08:00 - Don't forget to wear your Ryder Cup polo",
                            '08:30 - Meet at 1st tee on the South Course',
                            '08:45 - Team photos',
                            '09:03 - Group 1 tee off',
                            '12:00 - Lunch in the 37 Spike and Sports Bar, and await your airport transfer'
                        ],
                        playingGroup: '1',
                        playingGroupId: '31',
                        rulesLink: '/match-formats/ryder-cup',
                        rulesLinkLabel: 'Match Format & Rules',
                        ryderCupMatch: '1',
                        ryderCupTeam: 'Europe',
                        title: '⛳ Sunday - The Ryder Cup'
                    }
                },
                name: 'Tony Young',
                travel: {
                    arrival: {
                        'Transfer Companion': ['Travelling alone'],
                        arrivalDate: '07/05/2020',
                        arrivalLocation: 'Alicante',
                        arrivalTime: '10:05',
                        pickUpPoint: 'TBC',
                        title: 'Arrival Details 🛬',
                        transferDetails: 'TBC',
                        transferTime: 'TBC'
                    },
                    departure: {
                        'Transfer Companion': ['Ajay'],
                        departureDate: '10/05/2020',
                        departureLocation: 'Alicante',
                        departureTime: '20:30',
                        pickUpPoint: 'TBC',
                        title: 'Departure Details 🛫',
                        transferDetails: 'TBC',
                        transferTime: 'TBC'
                    }
                }
            },
            {
                accommodation: {
                    checkInDate: '08/05/2020',
                    checkInTime: '14:00',
                    checkOutDate: '10/05/2020',
                    checkOutTime: '10:00',
                    hotelName: 'La Manga Principe Filipe',
                    stayingWith: 'Nobody'
                },
                itinerary: {
                    Friday: {
                        agenda: [
                            '11:00 - Meet at hotel reception to get a transfer to the West Course',
                            '11:15 - Briefing at 1st tee',
                            '11:45 - Group 2 tee off',
                            "16:30 - Clubhouse Bar for a drink and rules explanation for Saturday's golf format",
                            '20:00 - Dinner in the Asia Restaurant with Prize Ceremony'
                        ],
                        playingGroup: '2',
                        playingGroupId: '12',
                        restaurantLink:
                            'https://lamangaclub.com/web/menu/asia.pdf',
                        restaurantLinkLabel: 'Asia Restaurant Menu',
                        rulesLink: '/match-formats/the-and-open',
                        rulesLinkLabel: 'Round Format & Rules',
                        title: '⛳ Friday: The AND Open'
                    },
                    Saturday: {
                        agenda: [
                            "07:30 - Breakfast in Luigi's Restaurant",
                            '07:50 - Meet at 1st tee on the North Course',
                            '08:54 - Group 1 tee off',
                            '13:00 - Time to relax and enjoy La Manga',
                            '18:30 - Dinner at La Cala - Ryan will book transfers from reception for 18:15'
                        ],
                        playingGroup: '7',
                        playingGroupId: '27',
                        restaurantLink:
                            'https://lamangaclub.com/web/menu/la-cala.pdf',
                        restaurantLinkLabel: 'La Cala Menu',
                        rulesLink: '/match-formats/texas-scramble',
                        rulesLinkLabel: 'Round Format & Rules',
                        title: '⛳ Saturday: Texas Scramble'
                    },
                    Sunday: {
                        agenda: [
                            "07:30 - Breakfast in Luigi's Restaurant",
                            "08:00 - Don't forget to wear your Ryder Cup polo",
                            '08:30 - Meet at 1st tee on the South Course',
                            '08:45 - Team photos',
                            '09:39 - Group 5 tee off',
                            '12:00 - Lunch in the 37 Spike and Sports Bar, and await your airport transfer'
                        ],
                        playingGroup: '5',
                        playingGroupId: '35',
                        rulesLink: '/match-formats/ryder-cup',
                        rulesLinkLabel: 'Match Format & Rules',
                        ryderCupMatch: '10',
                        ryderCupTeam: 'America',
                        title: '⛳ Sunday - The Ryder Cup'
                    }
                },
                name: 'Will Sargeant',
                travel: {
                    arrival: {
                        'Transfer Companion': ['TBC'],
                        arrivalDate: 'TBC',
                        arrivalLocation: 'TBC',
                        arrivalTime: 'TBC',
                        pickUpPoint: 'TBC',
                        title: 'Arrival Details 🛬',
                        transferDetails: 'TBC',
                        transferTime: 'TBC'
                    },
                    departure: {
                        'Transfer Companion': ['TBC'],
                        departureDate: 'TBC',
                        departureLocation: 'TBC',
                        departureTime: 'TBC',
                        pickUpPoint: 'TBC',
                        title: 'Departure Details 🛫',
                        transferDetails: 'TBC',
                        transferTime: 'TBC'
                    }
                }
            }
        ]
    },
    ryderCup: {
        allPlayers: [
            {
                group: 1,
                handicap: 10,
                match: 1,
                matchComplete: false,
                name: 'Tony Young ',
                team: 'Europe',
                teeOff: '0903'
            },
            {
                group: 1,
                handicap: 12,
                match: 2,
                matchComplete: false,
                name: 'Matt Archer',
                team: 'Europe',
                teeOff: '0903'
            },
            {
                group: 1,
                handicap: 12,
                match: 1,
                matchComplete: false,
                name: 'Alec Buchanan',
                team: 'America',
                teeOff: '0903'
            },
            {
                group: 1,
                handicap: 18,
                match: 2,
                matchComplete: false,
                name: 'Neil McFarlane',
                team: 'America',
                teeOff: '0903'
            },
            {
                group: 2,
                handicap: 20,
                match: 3,
                matchComplete: false,
                name: 'Richard Boyd',
                team: 'Europe'
            },
            {
                group: 2,
                handicap: 21,
                match: 4,
                matchComplete: false,
                name: "Ryan O'Connor",
                team: 'Europe'
            },
            {
                group: 2,
                handicap: 24,
                match: 3,
                matchComplete: false,
                name: 'Henry Joliffe',
                team: 'America'
            },
            {
                group: 2,
                handicap: 27,
                match: 4,
                matchComplete: false,
                name: 'Ajay Joshi',
                team: 'America'
            },
            {
                group: 3,
                handicap: 24,
                match: 5,
                matchComplete: false,
                name: 'Jack Aspden',
                team: 'Europe'
            },
            {
                group: 3,
                handicap: 28,
                match: 6,
                matchComplete: false,
                name: 'Gary Houlihan',
                team: 'Europe'
            },
            {
                group: 3,
                handicap: 28,
                match: 5,
                matchComplete: false,
                name: 'Chris Alderson',
                team: 'America'
            },
            {
                group: 3,
                handicap: 28,
                match: 6,
                matchComplete: false,
                name: 'Tim Hatton',
                team: 'America'
            },
            {
                group: 4,
                handicap: 28,
                match: 7,
                matchComplete: false,
                name: 'Louis Bougeard',
                team: 'Europe'
            },
            {
                group: 4,
                handicap: 28,
                match: 8,
                matchComplete: false,
                name: 'Mike Pohlschmidt',
                team: 'Europe'
            },
            {
                group: 4,
                handicap: 28,
                match: 7,
                matchComplete: false,
                name: 'Chris Cahill',
                team: 'America'
            },
            {
                group: 4,
                handicap: 28,
                match: 8,
                matchComplete: false,
                name: 'Prag Patel',
                team: 'America'
            },
            {
                group: 5,
                handicap: 28,
                match: 9,
                matchComplete: false,
                name: 'Neil Japhtha',
                team: 'Europe'
            },
            {
                group: 5,
                handicap: 28,
                match: 10,
                matchComplete: false,
                name: 'Tom Gallagher',
                team: 'Europe'
            },
            {
                group: 5,
                handicap: 28,
                match: 9,
                matchComplete: false,
                name: 'Nathan Giles',
                team: 'America'
            },
            {
                group: 5,
                handicap: 28,
                match: 10,
                matchComplete: false,
                name: 'Will Sargeant',
                team: 'America'
            },
            {
                group: 6,
                handicap: 28,
                match: 11,
                matchComplete: false,
                name: 'Dave Gauntley',
                team: 'Europe'
            },
            {
                group: 6,
                handicap: 28,
                match: 12,
                matchComplete: false,
                name: 'Joe Ellis',
                team: 'Europe'
            },
            {
                group: 6,
                handicap: 28,
                match: 11,
                matchComplete: false,
                name: 'Paramjit Uppal',
                team: 'America'
            },
            {
                group: 6,
                handicap: 28,
                match: 12,
                matchComplete: false,
                name: 'James Locker',
                team: 'America'
            },
            {
                group: 7,
                handicap: 28,
                match: 13,
                matchComplete: false,
                name: 'Nick Voss',
                team: 'Europe'
            },
            {
                group: 7,
                handicap: 36,
                match: 14,
                matchComplete: false,
                name: 'Charlie-Sam Newman',
                team: 'Europe'
            },
            {
                group: 7,
                handicap: 28,
                match: 13,
                matchComplete: false,
                name: 'Matt Lee',
                team: 'America'
            },
            {
                group: 7,
                handicap: 28,
                match: 14,
                matchComplete: false,
                name: 'Tom Hanson',
                team: 'America'
            }
        ],
        holes: [
            {
                distance: 414,
                editable: true,
                number: 1,
                par: 4,
                stroke: 4
            },
            {
                distance: 183,
                editable: true,
                number: 2,
                par: 3,
                stroke: 18
            },
            {
                distance: 378,
                editable: true,
                number: 3,
                par: 4,
                stroke: 8
            },
            {
                distance: 484,
                editable: true,
                number: 4,
                par: 5,
                stroke: 6
            },
            {
                distance: 193,
                editable: true,
                number: 5,
                par: 3,
                stroke: 16
            },
            {
                distance: 563,
                editable: true,
                number: 6,
                par: 5,
                stroke: 2
            },
            {
                distance: 381,
                editable: true,
                number: 7,
                par: 4,
                stroke: 12
            },
            {
                distance: 368,
                editable: true,
                number: 8,
                par: 4,
                stroke: 14
            },
            {
                distance: 516,
                editable: true,
                number: 9,
                par: 5,
                stroke: 10
            }
        ],
        liveScores: {
            match1: {
                hole1: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole2: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole3: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole4: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole5: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole6: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole7: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole8: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole9: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                }
            },
            match10: {
                hole1: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole2: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole3: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole4: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole5: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole6: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole7: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole8: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole9: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                }
            },
            match11: {
                hole1: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole2: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole3: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole4: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole5: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole6: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole7: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole8: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole9: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                }
            },
            match12: {
                hole1: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole2: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole3: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole4: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole5: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole6: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole7: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole8: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole9: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                }
            },
            match13: {
                hole1: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole2: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole3: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole4: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole5: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole6: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole7: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole8: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole9: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                }
            },
            match14: {
                hole1: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole2: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole3: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole4: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole5: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole6: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole7: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole8: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole9: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                }
            },
            match2: {
                hole1: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole2: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole3: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole4: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole5: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole6: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole7: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole8: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole9: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                }
            },
            match3: {
                hole1: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole2: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole3: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole4: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole5: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole6: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole7: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole8: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole9: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                }
            },
            match4: {
                hole1: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole2: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole3: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole4: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole5: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole6: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole7: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole8: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole9: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                }
            },
            match5: {
                hole1: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole2: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole3: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole4: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole5: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole6: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole7: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole8: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole9: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                }
            },
            match6: {
                hole1: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole2: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole3: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole4: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole5: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole6: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole7: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole8: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole9: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                }
            },
            match7: {
                hole1: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole2: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole3: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole4: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole5: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole6: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole7: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole8: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole9: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                }
            },
            match8: {
                hole1: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole2: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole3: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole4: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole5: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole6: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole7: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole8: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole9: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                }
            },
            match9: {
                hole1: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole2: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole3: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole4: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole5: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole6: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole7: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole8: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                },
                hole9: {
                    result: 'undefined',
                    shot1: 0,
                    shot2: 0
                }
            }
        }
    },
    stableford: {
        allPlayers: [
            {
                group: 1,
                handicap: 12,
                match: 1,
                matchComplete: false,
                name: 'Matt Archer',
                team: 'Europe',
                teeOff: '0903'
            },
            {
                group: 1,
                handicap: 21,
                match: 2,
                matchComplete: false,
                name: "Ryan O'Connor",
                team: 'Europe'
            },
            {
                group: 1,
                handicap: 28,
                match: 1,
                matchComplete: false,
                name: 'Chris Alderson',
                team: 'America'
            },
            {
                group: 1,
                handicap: 12,
                match: 2,
                matchComplete: false,
                name: 'Alec Buchanan',
                team: 'America',
                teeOff: '0903'
            },
            {
                group: 2,
                handicap: 10,
                match: 3,
                matchComplete: false,
                name: 'Tony Young ',
                team: 'Europe',
                teeOff: '0903'
            },
            {
                group: 2,
                handicap: 28,
                match: 4,
                matchComplete: false,
                name: 'Neil Japhtha',
                team: 'Europe'
            },
            {
                group: 2,
                handicap: 28,
                match: 3,
                matchComplete: false,
                name: 'Will Sargeant',
                team: 'America'
            },
            {
                group: 2,
                handicap: 28,
                match: 4,
                matchComplete: false,
                name: 'Tom Hanson',
                team: 'America'
            },
            {
                group: 3,
                handicap: 18,
                match: 5,
                matchComplete: false,
                name: 'Neil McFarlane',
                team: 'America',
                teeOff: '0903'
            },
            {
                group: 3,
                handicap: 28,
                match: 6,
                matchComplete: false,
                name: 'Gary Houlihan',
                team: 'Europe'
            },
            {
                group: 3,
                handicap: 28,
                match: 5,
                matchComplete: false,
                name: 'Nathan Giles',
                team: 'America'
            },
            {
                group: 3,
                handicap: 28,
                match: 6,
                matchComplete: false,
                name: 'Paramjit Uppal',
                team: 'America'
            },
            {
                group: 4,
                handicap: 28,
                match: 7,
                matchComplete: false,
                name: 'Prag Patel',
                team: 'America'
            },
            {
                group: 4,
                handicap: 28,
                match: 8,
                matchComplete: false,
                name: 'Dave Gauntley',
                team: 'Europe'
            },
            {
                group: 4,
                handicap: 28,
                match: 7,
                matchComplete: false,
                name: 'Joe Ellis',
                team: 'Europe'
            },
            {
                group: 4,
                handicap: 28,
                match: 8,
                matchComplete: false,
                name: 'James Locker',
                team: 'America'
            },
            {
                group: 5,
                handicap: 20,
                match: 9,
                matchComplete: false,
                name: 'Richard Boyd',
                team: 'Europe'
            },
            {
                group: 5,
                handicap: 24,
                match: 10,
                matchComplete: false,
                name: 'Jack Aspden',
                team: 'Europe'
            },
            {
                group: 5,
                handicap: 36,
                match: 9,
                matchComplete: false,
                name: 'Charlie-Sam Newman',
                team: 'Europe'
            },
            {
                group: 5,
                handicap: 28,
                match: 10,
                matchComplete: false,
                name: 'Matt Lee',
                team: 'America'
            },
            {
                group: 6,
                handicap: 28,
                match: 11,
                matchComplete: false,
                name: 'Louis Bougeard',
                team: 'Europe'
            },
            {
                group: 6,
                handicap: 28,
                match: 12,
                matchComplete: false,
                name: 'Mike Pohlschmidt',
                team: 'Europe'
            },
            {
                group: 6,
                handicap: 28,
                match: 11,
                matchComplete: false,
                name: 'Chris Cahill',
                team: 'America'
            },
            {
                group: 6,
                handicap: 28,
                match: 12,
                matchComplete: false,
                name: 'Tom Gallagher',
                team: 'Europe'
            },
            {
                group: 7,
                handicap: 24,
                match: 13,
                matchComplete: false,
                name: 'Henry Joliffe',
                team: 'America'
            },
            {
                group: 7,
                handicap: 27,
                match: 14,
                matchComplete: false,
                name: 'Ajay Joshi',
                team: 'America'
            },
            {
                group: 7,
                handicap: 28,
                match: 13,
                matchComplete: false,
                name: 'Tim Hatton',
                team: 'America'
            },
            {
                group: 7,
                handicap: 28,
                match: 14,
                matchComplete: false,
                name: 'Nick Voss',
                team: 'Europe'
            }
        ],
        holes: [
            {
                distance: 326,
                editable: true,
                number: 1,
                par: 4,
                stroke: 12
            },
            {
                distance: 165,
                editable: true,
                number: 2,
                par: 3,
                stroke: 16
            },
            {
                distance: 467,
                editable: true,
                number: 3,
                par: 5,
                stroke: 6
            },
            {
                distance: 137,
                editable: true,
                number: 4,
                par: 3,
                stroke: 18
            },
            {
                distance: 314,
                editable: true,
                number: 5,
                par: 4,
                stroke: 10
            },
            {
                distance: 477,
                editable: true,
                number: 6,
                par: 5,
                stroke: 4
            },
            {
                distance: 163,
                editable: true,
                number: 7,
                par: 3,
                stroke: 14
            },
            {
                distance: 377,
                editable: true,
                number: 8,
                par: 4,
                stroke: 2
            },
            {
                distance: 476,
                editable: true,
                number: 9,
                par: 5,
                stroke: 8
            },
            {
                distance: 338,
                editable: true,
                number: 10,
                par: 4,
                stroke: 3
            },
            {
                distance: 374,
                editable: true,
                number: 11,
                par: 4,
                stroke: 15
            },
            {
                distance: 381,
                editable: true,
                number: 12,
                par: 4,
                stroke: 1
            },
            {
                distance: 184,
                editable: true,
                number: 13,
                par: 3,
                stroke: 5
            },
            {
                distance: 330,
                editable: true,
                number: 14,
                par: 4,
                stroke: 17
            },
            {
                distance: 476,
                editable: true,
                number: 15,
                par: 5,
                stroke: 9
            },
            {
                distance: 194,
                editable: true,
                number: 16,
                par: 3,
                stroke: 7
            },
            {
                distance: 476,
                editable: true,
                number: 17,
                par: 5,
                stroke: 13
            },
            {
                distance: 398,
                editable: true,
                number: 18,
                par: 4,
                stroke: 11
            }
        ],
        liveScores: {
            match1: {
                hole1: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole2: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole3: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole4: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole5: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole6: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole7: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole8: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole9: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole10: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole11: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole12: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole13: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole14: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole15: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole16: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole17: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole18: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                }
            },
            match10: {
                hole1: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole2: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole3: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole4: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole5: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole6: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole7: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole8: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole9: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole10: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole11: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole12: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole13: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole14: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole15: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole16: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole17: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole18: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                }
            },
            match11: {
                hole1: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole2: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole3: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole4: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole5: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole6: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole7: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole8: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole9: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole10: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole11: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole12: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole13: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole14: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole15: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole16: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole17: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole18: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                }
            },
            match12: {
                hole1: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole2: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole3: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole4: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole5: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole6: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole7: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole8: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole9: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole10: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole11: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole12: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole13: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole14: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole15: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole16: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole17: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole18: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                }
            },
            match13: {
                hole1: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole2: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole3: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole4: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole5: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole6: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole7: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole8: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole9: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole10: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole11: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole12: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole13: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole14: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole15: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole16: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole17: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole18: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                }
            },
            match14: {
                hole1: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole2: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole3: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole4: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole5: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole6: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole7: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole8: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole9: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole10: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole11: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole12: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole13: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole14: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole15: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole16: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole17: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole18: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                }
            },
            match2: {
                hole1: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole2: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole3: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole4: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole5: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole6: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole7: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole8: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole9: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole10: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole11: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole12: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole13: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole14: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole15: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole16: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole17: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole18: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                }
            },
            match3: {
                hole1: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole2: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole3: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole4: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole5: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole6: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole7: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole8: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole9: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole10: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole11: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole12: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole13: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole14: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole15: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole16: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole17: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole18: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                }
            },
            match4: {
                hole1: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole2: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole3: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole4: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole5: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole6: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole7: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole8: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole9: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole10: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole11: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole12: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole13: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole14: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole15: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole16: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole17: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole18: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                }
            },
            match5: {
                hole1: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole2: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole3: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole4: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole5: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole6: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole7: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole8: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole9: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole10: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole11: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole12: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole13: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole14: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole15: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole16: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole17: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole18: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                }
            },
            match6: {
                hole1: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole2: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole3: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole4: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole5: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole6: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole7: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole8: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole9: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole10: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole11: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole12: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole13: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole14: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole15: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole16: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole17: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole18: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                }
            },
            match7: {
                hole1: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole2: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole3: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole4: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole5: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole6: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole7: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole8: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole9: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole10: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole11: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole12: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole13: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole14: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole15: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole16: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole17: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole18: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                }
            },
            match8: {
                hole1: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole2: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole3: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole4: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole5: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole6: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole7: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole8: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole9: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole10: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole11: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole12: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole13: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole14: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole15: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole16: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole17: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole18: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                }
            },
            match9: {
                hole1: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole2: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole3: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole4: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole5: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole6: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole7: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole8: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole9: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole10: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole11: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole12: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole13: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole14: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole15: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole16: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole17: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                },
                hole18: {
                    result: 'undefined',
                    shot1: 0,
                    shot1Net: 0,
                    shot2: 0,
                    shot2Net: 0
                }
            }
        }
    },
    texasScramble: {
        allPlayers: [
            {
                group: 1,
                handicap: 27,
                name: 'Ajay Yoshi ',
                teeOff: '0800'
            },
            {
                group: 1,
                handicap: 28,
                name: 'Paramjit Uppal ',
                teeOff: '0800'
            },
            {
                group: 1,
                handicap: 28,
                name: 'Tom Hanson',
                teeOff: '0800'
            },
            {
                group: 1,
                handicap: 10,
                name: 'Tony Young',
                teeOff: '0800'
            },
            {
                group: 2,
                handicap: 28,
                name: 'Tom Gallagher',
                teeOff: '0809'
            },
            {
                group: 2,
                handicap: 24,
                name: 'Jack Aspden',
                teeOff: '0809'
            },
            {
                group: 2,
                handicap: 24,
                name: 'James Locker',
                teeOff: '0809'
            },
            {
                group: 2,
                handicap: 28,
                name: 'Louis Bougeard',
                teeOff: '0809'
            },
            {
                group: 3,
                handicap: 28,
                name: 'Nathan Giles',
                teeOff: '0818'
            },
            {
                group: 3,
                handicap: 28,
                name: 'Nick Voss',
                teeOff: '0818'
            },
            {
                group: 3,
                handicap: 20,
                name: 'Richard Boyd',
                teeOff: '0818'
            },
            {
                group: 3,
                handicap: 21,
                name: "Ryan O'Connor",
                teeOff: '0818'
            },
            {
                group: 4,
                handicap: 12,
                name: 'Alec Buchanan',
                teeOff: '0827'
            },
            {
                group: 4,
                handicap: 28,
                name: 'Chris Alderson',
                teeOff: '0827'
            },
            {
                group: 4,
                handicap: 28,
                name: 'Chris Cahill',
                teeOff: '0827'
            },
            {
                group: 4,
                handicap: 28,
                name: 'Tim Hatton',
                teeOff: '0827'
            },
            {
                group: 5,
                handicap: 28,
                name: 'Gary Houlihan',
                teeOff: '0836'
            },
            {
                group: 5,
                handicap: 12,
                name: 'Matt Archer',
                teeOff: '0836'
            },
            {
                group: 5,
                handicap: 28,
                name: 'Matt Lee',
                teeOff: '0836'
            },
            {
                group: 5,
                handicap: 28,
                name: 'Mike Pohlschmidt',
                teeOff: '0836'
            },
            {
                group: 6,
                handicap: 28,
                name: 'Dave Gauntley',
                teeOff: '0845'
            },
            {
                group: 6,
                handicap: 24,
                name: 'Henry Jolliffe',
                teeOff: '0845'
            },
            {
                group: 6,
                handicap: 28,
                name: 'Neil Japhtha',
                teeOff: '0845'
            },
            {
                group: 6,
                handicap: 28,
                name: 'Prag Patel',
                teeOff: '0845'
            },
            {
                group: 7,
                handicap: 28,
                name: 'Joe Ellis',
                teeOff: '0854'
            },
            {
                group: 7,
                handicap: 36,
                name: 'Charlie-Sam Newman',
                teeOff: '0854'
            },
            {
                group: 7,
                handicap: 18,
                name: 'Neil McFarlane',
                teeOff: '0854'
            },
            {
                group: 7,
                handicap: 28,
                name: 'Will Sargeant',
                teeOff: '0854'
            }
        ],
        holes: [
            {
                distance: 326,
                editable: true,
                number: 1,
                par: 4,
                stroke: 12
            },
            {
                distance: 165,
                editable: true,
                number: 2,
                par: 3,
                stroke: 16
            },
            {
                distance: 467,
                editable: true,
                number: 3,
                par: 5,
                stroke: 6
            },
            {
                distance: 137,
                editable: true,
                number: 4,
                par: 3,
                stroke: 18
            },
            {
                distance: 314,
                editable: true,
                number: 5,
                par: 4,
                stroke: 10
            },
            {
                distance: 477,
                editable: true,
                number: 6,
                par: 5,
                stroke: 4
            },
            {
                distance: 163,
                editable: true,
                number: 7,
                par: 3,
                stroke: 14
            },
            {
                distance: 377,
                editable: true,
                number: 8,
                par: 4,
                stroke: 2
            },
            {
                distance: 476,
                editable: true,
                number: 9,
                par: 5,
                stroke: 8
            },
            {
                distance: 338,
                editable: true,
                number: 10,
                par: 4,
                stroke: 3
            },
            {
                distance: 374,
                editable: true,
                number: 11,
                par: 4,
                stroke: 15
            },
            {
                distance: 381,
                editable: true,
                number: 12,
                par: 4,
                stroke: 1
            },
            {
                distance: 184,
                editable: true,
                number: 13,
                par: 3,
                stroke: 5
            },
            {
                distance: 330,
                editable: true,
                number: 14,
                par: 4,
                stroke: 17
            },
            {
                distance: 476,
                editable: true,
                number: 15,
                par: 5,
                stroke: 9
            },
            {
                distance: 194,
                editable: true,
                number: 16,
                par: 3,
                stroke: 7
            },
            {
                distance: 476,
                editable: true,
                number: 17,
                par: 5,
                stroke: 13
            },
            {
                distance: 398,
                editable: true,
                number: 18,
                par: 4,
                stroke: 11
            }
        ]
    }
};
