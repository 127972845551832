import React from 'react';
import { Span16px, MarginY10px, TableDiv, TableCell } from '../../../../ui';

export default ({ arrival, departure }) => {
    return (
        <>
            <MarginY10px>
                <Span16px>
                    <strong>Arrival Details</strong>
                    <span role='img' aria-label='img'>
                        🛬
                    </span>
                </Span16px>
            </MarginY10px>
            <TableDiv>
                <TableCell>
                    <strong>Date & Time:</strong>
                </TableCell>
                <TableCell>
                    {arrival.arrivalDate} {arrival.arrivalTime}
                </TableCell>
            </TableDiv>
            <TableDiv>
                <TableCell>
                    <strong>Arrival Location:</strong>
                </TableCell>
                <TableCell>{arrival.arrivalLocation}</TableCell>
            </TableDiv>
            <TableDiv>
                <TableCell>
                    <strong>Transfer Time:</strong>
                </TableCell>
                <TableCell>{arrival.transferTime}</TableCell>
            </TableDiv>
            <TableDiv>
                <TableCell>
                    <strong>Pick Up Point:</strong>
                </TableCell>
                <TableCell>{arrival.pickUpPoint}</TableCell>
            </TableDiv>
            <TableDiv>
                <TableCell>
                    <strong>Transfer Details:</strong>
                </TableCell>
                <TableCell>{arrival.transferDetails}</TableCell>
            </TableDiv>
            <TableDiv>
                <TableCell>
                    <strong>Travelling with:</strong>
                </TableCell>
                <TableCell>{arrival['Transfer Companion'].join(',')}</TableCell>
            </TableDiv>

            <MarginY10px>
                <Span16px>
                    <strong>Departure Details</strong>
                    <span role='img' aria-label='img'>
                        🛫
                    </span>
                </Span16px>
            </MarginY10px>
            <TableDiv>
                <TableCell>
                    <strong>Date & Time:</strong>
                </TableCell>
                <TableCell>
                    {departure.departureDate} {departure.departureTime}
                </TableCell>
            </TableDiv>

            <TableDiv>
                <TableCell>
                    <strong>Departure Location:</strong>
                </TableCell>
                <TableCell>{departure.departureLocation}</TableCell>
            </TableDiv>

            <TableDiv>
                <TableCell>
                    <strong>Transfer Time:</strong>
                </TableCell>
                <TableCell>{departure.transferTime}</TableCell>
            </TableDiv>

            <TableDiv>
                <TableCell>
                    <strong>Pick Up Point:</strong>
                </TableCell>
                <TableCell>{departure.pickUpPoint}</TableCell>
            </TableDiv>

            <TableDiv>
                <TableCell>
                    <strong>Transfer Details:</strong>
                </TableCell>
                <TableCell>{departure.transferDetails}</TableCell>
            </TableDiv>

            <TableDiv>
                <TableCell>
                    <strong>Travelling with:</strong>
                </TableCell>
                <TableCell>
                    {departure['Transfer Companion'].join(', ')}
                </TableCell>
            </TableDiv>
        </>
    );
};
