import {
    INCREMENT_SHOT_COUNT,
    DECREMENT_ACTIVE_HOLE,
    DECREMENT_SHOT_COUNT,
    INCREMENT_ACTIVE_HOLE,
    TOGGLE_SCORE_EDITABLE,
    SELECT_GROUP,
    INCREMENT_HEADER_SCORE,
    LOAD_ALL_SCORES,
    END_ROUND_SUCCESS,
    END_ROUND_START,
    LOAD_ALL_DATA
} from '../types';

export const initialState = {
    holes: null,
    allPlayers: null,
    players: null,
    activeHole: null
};

export default (state = initialState, action) => {
    let activeHole;
    let players;
    let name;
    let holes;

    switch (action.type) {
        case INCREMENT_ACTIVE_HOLE:
            activeHole =
                state.activeHole === state.holes.length
                    ? state.activeHole
                    : state.activeHole + 1;
            players = [...state.players];
            players.map(player => {
                if (player.shots.length < activeHole) {
                    player.shots = [
                        ...player.shots,
                        state.holes[activeHole - 1].par
                    ];
                }
                return player;
            });

            return {
                ...state,
                activeHole,
                players
            };

        case DECREMENT_ACTIVE_HOLE:
            activeHole =
                state.activeHole === 1
                    ? state.activeHole
                    : state.activeHole - 1;

            return {
                ...state,
                activeHole
            };

        case INCREMENT_SHOT_COUNT:
            name = action.payload;
            players = [...state.players];
            players.map(player => {
                if (player.name === name) {
                    player.shots = [...player.shots];
                    player.shots[state.activeHole - 1] += 1;
                }
                return player;
            });

            return {
                ...state,
                players
            };

        case DECREMENT_SHOT_COUNT:
            name = action.payload;
            players = [...state.players];
            players.map(player => {
                if (player.name === name) {
                    player.shots = [...player.shots];
                    player.shots[state.activeHole - 1] =
                        player.shots[state.activeHole - 1] > 1
                            ? player.shots[state.activeHole - 1] - 1
                            : player.shots[state.activeHole - 1];
                }
                return player;
            });

            return {
                ...state,
                players
            };

        case TOGGLE_SCORE_EDITABLE:
            holes = [...state.holes];
            holes.map(hole => {
                if (hole.number === state.activeHole) {
                    hole.editable = !hole.editable;
                }
                return hole;
            });

            return {
                ...state,
                holes
            };

        case SELECT_GROUP:
            const group = Number.parseInt(action.payload, 10);
            const { par } = state.holes[0];

            const playersToAdd = [
                ...state.allPlayers.filter(player => player.group === group)
            ].map(player => {
                const shots = [par];
                return {
                    ...player,
                    shots
                };
            });

            return {
                ...state,
                players: playersToAdd,
                activeHole: 1
            };

        case LOAD_ALL_DATA:
            const {
                ryderCup: { liveScores, allPlayers, holes: allHoles }
            } = action.payload;

            return {
                ...state,
                liveScores,
                holes: allHoles,
                allPlayers
            };

        case END_ROUND_START:
            return {
                ...state,
                loading: true
            };

        case END_ROUND_SUCCESS:
            return {
                ...state,
                loading: false,
                allPlayers: [...action.payload]
            };

        case LOAD_ALL_SCORES:
            const { liveScores: scores } = action.payload;
            return {
                ...state,
                liveScores: scores
            };

        case INCREMENT_HEADER_SCORE:
            const { euScore } = action.payload;
            const { usScore } = action.payload;
            return {
                ...state,
                euScore,
                usScore
            };

        default:
            return { ...state };
    }
};
