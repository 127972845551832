import React from 'react';
import { withRouter } from 'react-router-dom';
import BtnBackImg from '../../../ui/back.png';
import { StyledBackImg } from './BackComponent.styles';
import Flex from '../../../ui/Layout/Flex';

class BackComponentWithoutRouter extends React.PureComponent {
    render() {
        const { history } = this.props;
        return (
            <Flex
                position='absolute'
                padding='0'
                margin='0'
                bottom='10px'
                background-color='#fff'
                height='40px'
                z-index='2'
                left='13px'
            >
                {history.location.pathname !== '/' &&
                history.location.pathname !== '/scoreboard' ? (
                    <StyledBackImg src={BtnBackImg} onClick={history.goBack} />
                ) : null}
            </Flex>
        );
    }
}

export default withRouter(BackComponentWithoutRouter);
