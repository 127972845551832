/* eslint-disable dot-notation */
import {
    INCREMENT_SHOT_COUNT,
    DECREMENT_ACTIVE_HOLE,
    DECREMENT_SHOT_COUNT,
    INCREMENT_ACTIVE_HOLE,
    TOGGLE_SCORE_EDITABLE,
    INCREMENT_SHOT_COUNT_STABLE,
    DECREMENT_ACTIVE_HOLE_STABLE,
    DECREMENT_SHOT_COUNT_STABLE,
    INCREMENT_ACTIVE_HOLE_STABLE,
    TOGGLE_SCORE_EDITABLE_STABLE,
    SELECT_GROUP,
    INCREMENT_HEADER_SCORE,
    LOAD_ALL_SCORES,
    LOAD_ALL_SCORES_STABLE,
    END_ROUND_START,
    END_ROUND_SUCCESS,
    END_ROUND_START_STABLE,
    END_ROUND_SUCCESS_STABLE,
    LOAD_ALL_DATA
} from './types';

import RESET_DATA from './data';

import firebase from '../firebase';
//  firebase import

export const incrementShotCount = payload => ({
    type: INCREMENT_SHOT_COUNT,
    payload
});

export const incrementShotCountStable = payload => ({
    type: INCREMENT_SHOT_COUNT_STABLE,
    payload
});

export const decrementShotCount = payload => ({
    type: DECREMENT_SHOT_COUNT,
    payload
});

export const decrementShotCountStable = payload => ({
    type: DECREMENT_SHOT_COUNT_STABLE,
    payload
});

export const headerScoresIncrement = payload => ({
    type: INCREMENT_HEADER_SCORE,
    payload
});

export const incrementActiveHole = () => ({
    type: INCREMENT_ACTIVE_HOLE
});

export const incrementActiveHoleStable = () => ({
    type: INCREMENT_ACTIVE_HOLE_STABLE
});

export const decrementActiveHole = () => ({
    type: DECREMENT_ACTIVE_HOLE
});

export const decrementActiveHoleStable = () => ({
    type: DECREMENT_ACTIVE_HOLE_STABLE
});

export const toggleScoreEditable = () => ({
    type: TOGGLE_SCORE_EDITABLE
});

export const toggleScoreEditableStable = () => ({
    type: TOGGLE_SCORE_EDITABLE_STABLE
});

export const selectGroup = groupSelected => {
    const group = groupSelected.match(/Group (\d)/)[1]; // Extract the group number from the selected string
    return {
        type: SELECT_GROUP,
        payload: group
    };
};

export const selectGroupById = group => {
    return {
        type: SELECT_GROUP,
        payload: group
    };
};

const loadAllDataSuccess = data => {
    return { type: LOAD_ALL_DATA, payload: data };
};

const loadAllScoresSuccess = data => {
    return { type: LOAD_ALL_SCORES, payload: data };
};
const loadAllScoresSuccessStable = data => {
    return { type: LOAD_ALL_SCORES_STABLE, payload: data };
};

const endRoundStart = data => ({
    type: END_ROUND_START,
    payload: data
});

const endRoundSuccess = data => ({
    type: END_ROUND_SUCCESS,
    payload: data
});

const endRoundStartStable = data => ({
    type: END_ROUND_START_STABLE,
    payload: data
});

const endRoundSuccessStable = data => ({
    type: END_ROUND_SUCCESS_STABLE,
    payload: data
});

export function endRound(db) {
    return (dispatch, getState) => {
        if (db === 'ryderCup') {
            dispatch(endRoundStart());
        } else {
            dispatch(endRoundStartStable());
        }

        const { players, allPlayers } =
            db === 'ryderCup' ? getState().ryderCup : getState().stableford;
        const groupNumber = players[0].group;

        const newPlayers = allPlayers.map(player => {
            let newPlayer = { ...player };
            if (player.group === groupNumber) {
                newPlayer = {
                    ...newPlayer,
                    matchComplete: true
                };
            }
            return newPlayer;
        });

        firebase
            .firestore()
            .collection('golf-trip')
            .doc(db)
            .update({ allPlayers: newPlayers })
            .then(() => {
                if (db === 'ryderCup') {
                    dispatch(endRoundSuccess(newPlayers));
                } else {
                    dispatch(endRoundSuccessStable(newPlayers));
                }
            });
    };
}

export const resetData = () => {
    firebase
        .firestore()
        .collection('golf-trip')
        .doc('ryderCup')
        .set({ ...RESET_DATA.ryderCup });
    firebase
        .firestore()
        .collection('golf-trip')
        .doc('stableford')
        .set({ ...RESET_DATA.stableford });
};

export const getAllData = () => {
    return dispatch => {
        firebase
            .firestore()
            .collection('golf-trip')
            .get()
            .then(querySnapshot => {
                const allData = {};
                querySnapshot.forEach(doc => {
                    allData[doc.id] = doc.data();
                });

                dispatch(loadAllDataSuccess(allData));
            });
    };
};

export const getLiveScores = db => {
    return dispatch => {
        firebase
            .firestore()
            .collection('golf-trip')
            .doc(db)
            .onSnapshot(doc => {
                const docData = doc.data();
                if (db === 'ryderCup') {
                    dispatch(loadAllScoresSuccess(docData));
                } else {
                    dispatch(loadAllScoresSuccessStable(docData));
                }
            });
    };
};

export const saveData = (match1, match2, players, currentHole, db) => {
    const match1Path = `liveScores.match${players[0].match}.hole${currentHole.number}.result`;
    const match2Path = `liveScores.match${players[1].match}.hole${currentHole.number}.result`;
    const match1Result =
        match1 !== undefined ? (match1 === 'Europe' ? 'win' : 'draw') : 'lose';
    const match2Result =
        match2 !== undefined ? (match2 === 'Europe' ? 'win' : 'draw') : 'lose';

    const match1Player1 = `liveScores.match${players[0].match}.hole${currentHole.number}.shot1`;
    const match1Player2 = `liveScores.match${players[2].match}.hole${currentHole.number}.shot2`;
    const match2Player1 = `liveScores.match${players[1].match}.hole${currentHole.number}.shot1`;
    const match2Player2 = `liveScores.match${players[3].match}.hole${currentHole.number}.shot2`;

    const match1NetPlayer1 = `liveScores.match${players[0].match}.hole${currentHole.number}.shot1Net`;
    const match1NetPlayer2 = `liveScores.match${players[2].match}.hole${currentHole.number}.shot2Net`;
    const match2NetPlayer1 = `liveScores.match${players[1].match}.hole${currentHole.number}.shot1Net`;
    const match2NetPlayer2 = `liveScores.match${players[3].match}.hole${currentHole.number}.shot2Net`;

    const player1Score = players[0].shots[currentHole.number - 1];
    const player2Score = players[2].shots[currentHole.number - 1];
    const player3Score = players[1].shots[currentHole.number - 1];
    const player4Score = players[3].shots[currentHole.number - 1];

    const player1NetScore =
        db === 'stableford'
            ? players[0].shotsNet[currentHole.number - 1]
            : null;
    const player2NetScore =
        db === 'stableford'
            ? players[2].shotsNet[currentHole.number - 1]
            : null;
    const player3NetScore =
        db === 'stableford'
            ? players[1].shotsNet[currentHole.number - 1]
            : null;
    const player4NetScore =
        db === 'stableford'
            ? players[3].shotsNet[currentHole.number - 1]
            : null;

    const stableDataPackage = {
        [match1Path]: match1Result,
        [match2Path]: match2Result,
        [match1Player1]: player1Score,
        [match1Player2]: player2Score,
        [match2Player1]: player3Score,
        [match2Player2]: player4Score,
        [match1NetPlayer1]: player1NetScore,
        [match1NetPlayer2]: player2NetScore,
        [match2NetPlayer1]: player3NetScore,
        [match2NetPlayer2]: player4NetScore
    };

    const ryderDataPackage = {
        [match1Path]: match1Result,
        [match2Path]: match2Result,
        [match1Player1]: player1Score,
        [match1Player2]: player2Score,
        [match2Player1]: player3Score,
        [match2Player2]: player4Score
    };

    const dataPackage =
        db === 'stableford' ? stableDataPackage : ryderDataPackage;

    return dispatch => {
        firebase
            .firestore()
            .collection('golf-trip')
            .doc(db)
            .update(dataPackage)
            .then(() => {
                dispatch(getLiveScores(db));
            });
    };
};
