import React from 'react';
import PropTypes from 'prop-types';
import HoleSummary from './hole-summary/HoleSummary';
import { WhiteCard } from '../../ui';
import PlayerGroup from './player-group/PlayerGroup';

class HoleDetails extends React.PureComponent {
    render() {
        // TODO: Change this Mock data to real data
        const { hole, type } = this.props;

        return (
            <WhiteCard data-test='component-hole-details'>
                <HoleSummary {...hole} data-test='component-hole-summary' />
                <PlayerGroup
                    gameType={type}
                    data-test='component-player-group'
                />
            </WhiteCard>
        );
    }
}

HoleDetails.propTypes = {
    hole: PropTypes.shape({
        name: PropTypes.string,
        number: PropTypes.number,
        par: PropTypes.number,
        stroke: PropTypes.number,
        distance: PropTypes.number
    })
};

HoleDetails.defaultProps = {
    hole: {
        name: '-',
        number: 0,
        par: 0,
        stroke: 0,
        distance: 0
    }
};

export default HoleDetails;
