import styled from 'styled-components';
import { colours } from '../global';

export const RedText = styled.span`
    color: ${colours.red};
    font-weight: bold;
`;

export const Span20px = styled.span`
    font-size: 20px;
    line-height: 30px;
`;

export const Span16px = styled.span`
    font-size: 16px;
    line-height: 24px;
`;

export const Span14px = styled.span`
    font-size: 14px;
    line-height: 21px;
`;

export const LinkWrapper = styled.div`
    display: flex;
    justify-content: center;
    a {
        font-size: 16px;
        color: ${colours.blue};
    }
`;

export const CloseIcon = styled.button`
    font-size: 23px;
    position: absolute;
    top: 6px;
    right: 10px;
    border: none;
    padding: 0;
    background: none;
    line-height: 23px;
`;
