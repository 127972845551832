import React, { useState } from 'react';
import { SliderContent, SlideContainer, Slide } from './HintOverlay.styles';
import HintPortal from './HintPortal';
import { setCookie } from '../../utils/cookies.util';

import TexasScrambleHint1 from './img/TexasScramble/Hint1.png';
import TexasScrambleHint3 from './img/TexasScramble/Hint3.png';
import TexasScrambleHint4 from './img/TexasScramble/Hint4.png';
import TexasScrambleHint5 from './img/TexasScramble/Hint5.png';
import TexasScrambleHint6 from './img/TexasScramble/Hint6.png';

import StablefordHint1 from './img/Stableford/Hint1.png';
import StablefordHint2 from './img/Stableford/Hint2.png';
import StablefordHint3 from './img/Stableford/Hint3.png';
import StablefordHint4 from './img/Stableford/Hint4.png';
import StablefordHint5 from './img/Stableford/Hint5.png';

import RyderCupHint1 from './img/RyderCup/Hint1.png';
import RyderCupHint2 from './img/RyderCup/Hint2.png';
import RyderCupHint3 from './img/RyderCup/Hint3.png';
import RyderCupHint4 from './img/RyderCup/Hint4.png';

const hintImages = {
    'Texas Scramble': [
        TexasScrambleHint1,
        TexasScrambleHint3,
        TexasScrambleHint4,
        TexasScrambleHint5,
        TexasScrambleHint6
    ],
    Stableford: [
        StablefordHint1,
        StablefordHint2,
        StablefordHint3,
        StablefordHint4,
        StablefordHint5
    ],
    'Ryder Cup': [RyderCupHint1, RyderCupHint2, RyderCupHint3, RyderCupHint4]
};

const HintOverlay = props => {
    const { gameType } = props;
    const getWidth = () => window.innerWidth;

    const [state, setState] = useState({
        activeIndex: 0,
        translate: 0,
        transition: 0.45
    });

    const { translate, transition, activeIndex } = state;

    // eslint-disable-next-line consistent-return
    const nextSlide = () => {
        if (activeIndex === hintImages[gameType].length - 1) {
            setState({
                ...state,
                translate: 0,
                activeIndex: 0
            });
            // Set cookie to indicate that user has viewed all hint slides
            setCookie(`AND Open ${gameType} Hint`, 'seen', 10);
            // eslint-disable-next-line no-restricted-globals
            location.reload();
            return;
        }

        setState({
            ...state,
            activeIndex: activeIndex + 1,
            translate: (activeIndex + 1) * getWidth()
        });
    };

    return (
        <HintPortal gameType={gameType} data-testid='hint-portal'>
            <SlideContainer onClick={nextSlide}>
                {hintImages[gameType] && (
                    <SliderContent
                        translate={translate}
                        transition={transition}
                        width={getWidth() * hintImages[gameType].length}
                    >
                        {hintImages[gameType].map((slide, i) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <Slide key={slide + i} content={slide} />
                        ))}
                    </SliderContent>
                )}
            </SlideContainer>
        </HintPortal>
    );
};

export default HintOverlay;
