import styled from 'styled-components';

export const StyledH1 = styled.h1`
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-size: 25px;
    line-height: 37px;
`;

export const StyledHeader = styled.header`
    padding-top: 25px;
    width: 100%;
    display: flex;
`;
