import styled from 'styled-components';
import { colours } from '../../../../global';

export const Grid = styled.div`
    padding: 0 50px;
    margin-top: 20px;
    display: grid;
`;

export const SummaryCircleScore = styled.div`
    width: 22px;
    height: 22px;
    border-radius: 25px;
    margin-bottom: 15px;
    background-color: ${props => {
        if (props.result !== 'undefined' && !props.editable)
            return colours.green;
        return colours.charcoal;
    }};

    border: 1px solid
        ${props => {
            if (props.result !== 'undefined' && !props.editable)
                return colours.green;
            return colours.charcoal;
        }};
`;
