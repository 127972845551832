import * as firebase from 'firebase';

const config = {
    apiKey: 'AIzaSyAse7bjGnsvkWm-BdZ51U1gEPS0ypiPd-g',
    authDomain: 'and-golf-app.firebaseapp.com',
    databaseURL: 'https://and-golf-app.firebaseio.com',
    projectId: 'and-golf-app',
    storageBucket: 'and-golf-app.appspot.com',
    messagingSenderId: '741482030325',
    appId: '1:741482030325:web:eeb205ad6a0bbfa46a51bc'
};
firebase.initializeApp(config);

export default firebase;
