import styled from 'styled-components';
import { colours } from '../global';

export const Button = styled.button`
    background: #ffffff;
    border: 2px solid ${colours.green};
    border-radius: 25px;
    transition: all 0.1s ease-in;
    text-transform: uppercase;
    padding: 13px 28px;
    width: 50%;
`;

export const SmallButton = styled.button`
    background: #ffffff;
    border: 2px solid ${props => props.color};
    border-radius: 25px;
    transition: all 0.1s ease-in;
    text-transform: uppercase;
    padding: 6px 28px;
`;

export const ScoreButton = styled.button`
    background: #000;
    border-radius: 5px;
    transition: all 0.1s ease-in;
    text-transform: uppercase;
    height: 30px;
    width: 30px;
    color: white;
    border: none;
    font-size: large;
`;
