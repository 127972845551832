import styled from 'styled-components';

export const MatchWrapper = styled.div`
    width: 50%;
`;

export const VsSpan = styled.span`
    font-size: 12px;
    position: absolute;
    left: 50px;
    transform: translateY(-10px);
`;

export const ScoreContainer = styled.div`
    margin: 0 25px;
`;

export const HoleScoreWrapper = styled.div`
    position: absolute;
    right: 3%;
    top: 48%;
`;
