import React from 'react';
import { useStore } from 'react-redux';
import {
    Accordion,
    VerticalDiv,
    RedDiv,
    Span14px,
    Span16px,
    MarginY10px,
    Span20px,
    TableDiv,
    TableCell,
    TableCellMin
} from '../../../ui';
import SubHeader from '../../../components/sub-header';
import AccordionCards from '../../../ui/AccordionCards';
import UsaFlag from '../../../ui/usa-flag.png';
import EuFlag from '../../../ui/eu-flag.png';
import { FlagImg } from '../../hole-details/player-group/player/Player.styles';

const RyderCupRules = () => {
    const {
        ryderCup: { allPlayers }
    } = useStore().getState();
    let { itinerary } = useStore().getState();

    if (!Array.isArray(itinerary)) {
        itinerary = Object.values(itinerary);
    }

    const groups = [];
    const numberOfGroups = [
        ...new Set(itinerary.map(g => +g.itinerary.Sunday.playingGroup))
    ];
    numberOfGroups
        .sort((a, b) => (a > b ? 1 : -1))
        // eslint-disable-next-line array-callback-return
        .map(groupNumber => {
            const playersInGroup = allPlayers
                .filter(player => player.group === groupNumber)
                .map(player => ({
                    team: player.team,
                    name: player.name,
                    handicap: player.handicap,
                    teeOff: player.teeOff,
                    group: player.group,
                    match: +player.match
                }))
                .sort((a, b) => (a.match > b.match ? 1 : -1));
            if (playersInGroup.length > 0) {
                groups.push({
                    number: playersInGroup[0].group,
                    teeOff: playersInGroup[0].teeOff,
                    playersInGroup
                });
            }
        });

    return (
        <RedDiv>
            <SubHeader data-test='component-subheader'>
                <Span20px>Day 3 Match Format: Ryder Cup</Span20px>
            </SubHeader>

            <VerticalDiv>
                <Accordion icon='📕' title='Rules'>
                    <MarginY10px>
                        <Span16px>
                            The AND Ryder Cup, 28 players into 2 teams. Each 4
                            ball will have 2 matches in it, battling it out in
                            singles match play, for either Europe or America.
                        </Span16px>
                    </MarginY10px>
                    <MarginY10px>
                        <Span16px>
                            A player is awarded one point for beating their
                            opponent on a hole. If the hole is a tie, the hole
                            is halved and the score remains the same. If a
                            player ‘receives a shot’, a stroke should be removed
                            from that players score at the end of the hole where
                            the shot was received, and the result of the hole
                            calculated on the net score. Each winning match will
                            be awarded 1 point, a draw will be awarded ½ a point
                            each.
                        </Span16px>
                    </MarginY10px>
                    <MarginY10px>
                        <VerticalDiv>
                            <Span16px>
                                <strong>A playing example</strong>
                            </Span16px>
                            <Span16px>
                                - Will gets 1 shot from Gary on hole 1. Will
                                scores 5 and Gary scores 5. Will wins the hole
                                and goes ‘1 up’ as he gets 1 shot from Gary.
                            </Span16px>
                            <Span16px>
                                - Gary pulls it back and wins the next three
                                holes, Gary is now ‘2 up’
                            </Span16px>
                            <Span16px>
                                - Gary feels the pressure and loses the next 4
                                holes, Will is now 2 up with 1 hole to play.
                            </Span16px>
                            <Span16px>
                                - Will wins the match ‘2&1’, as there are not
                                enough holes left for Gary to take back the lead
                                or tie.
                            </Span16px>
                        </VerticalDiv>
                    </MarginY10px>
                </Accordion>

                <AccordionCards icon='😎' title='Groups'>
                    {groups.map(group => (
                        <VerticalDiv>
                            <Span20px>
                                <span role='img' aria-label='Golf pin emoji'>
                                    ⛳️
                                </span>{' '}
                                Group {group.number} - Tee Off {group.teeOff}
                            </Span20px>
                            <TableDiv>
                                <TableCellMin />
                                <TableCell>
                                    <strong>Name</strong>
                                </TableCell>
                                <TableCell>
                                    <strong>Handicap</strong>
                                </TableCell>
                            </TableDiv>
                            {group.playersInGroup.map(player => (
                                <TableDiv>
                                    <TableCellMin>
                                        {' '}
                                        {player.team === 'America' ? (
                                            <FlagImg
                                                src={UsaFlag}
                                                height='20'
                                                data-test='usa-flag'
                                            />
                                        ) : (
                                            <FlagImg
                                                src={EuFlag}
                                                height='20'
                                                data-test='eu-flag'
                                            />
                                        )}
                                    </TableCellMin>
                                    <TableCell>
                                        <Span14px>{player.name}</Span14px>
                                    </TableCell>
                                    <TableCell>
                                        <Span14px>{player.handicap}</Span14px>
                                    </TableCell>
                                </TableDiv>
                            ))}
                        </VerticalDiv>
                    ))}
                </AccordionCards>
            </VerticalDiv>
        </RedDiv>
    );
};

export default RyderCupRules;
