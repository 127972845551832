import React from 'react';
import PropTypes from 'prop-types';

import { Span14px, Span20px } from '../../../ui/Typography';
import { VerticalDiv } from '../../../ui';

const HoleSummary = props => {
    const { number, par, stroke, distance } = props;
    return (
        <VerticalDiv data-test='component-hole-summary'>
            <Span20px>Hole {number}</Span20px>
            {/* <Span20px>{name}</Span20px> */}
            <Span14px>
                Par {par} | Stroke {stroke} | {distance} Yards
            </Span14px>
        </VerticalDiv>
    );
};

HoleSummary.propTypes = {
    // name: PropTypes.string,
    number: PropTypes.number,
    par: PropTypes.number,
    stroke: PropTypes.number,
    distance: PropTypes.number
};

HoleSummary.defaultProps = {
    // name: '-',
    number: 0,
    par: 0,
    stroke: 0,
    distance: 0
};

export default HoleSummary;
