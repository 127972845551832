import React from 'react';
import { SpaceAroundDiv } from '../../../../ui';
import { Grid, SummaryCircleScore } from './MatchSummaryStableford.styles';

const MatchSummary = () => {
    return (
        <Grid>
            <SpaceAroundDiv>
                {[0, 1, 2, 3, 4, 5, 6, 7, 8].map(hole => (
                    <SummaryCircleScore key={hole} result='win' />
                ))}
            </SpaceAroundDiv>
            <SpaceAroundDiv>
                {[0, 1, 2, 3, 4, 5, 6, 7, 8].map(hole => (
                    <SummaryCircleScore key={hole} result='win' />
                ))}
            </SpaceAroundDiv>
        </Grid>
    );
};

export default MatchSummary;
