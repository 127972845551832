import React from 'react';
import { connect } from 'react-redux';
import UsaFlag from '../../../ui/usa-flag.png';
import EuFlag from '../../../ui/eu-flag.png';
import { ReactComponent as Logo } from '../Logo.svg';
import {
    StyledHeader,
    StyledDiv,
    StyledP,
    FlagImg,
    ScoreWrapper,
    EuScore,
    UsScore
} from './ResultsHeader.styles';

export class ResultsHeader extends React.PureComponent {
    render() {
        const { euScore } = this.props;
        const { usScore } = this.props;

        return (
            <StyledHeader data-test='component-header'>
                <StyledDiv>
                    <h1>
                        The
                        <Logo data-test='header-logo' />
                        Ryder Cup
                    </h1>
                </StyledDiv>
                <StyledDiv>
                    <FlagImg src={EuFlag} height='170' data-test='usa-flag' />
                    <ScoreWrapper>
                        <EuScore>{euScore || 0}</EuScore>
                        <StyledP>VS</StyledP>
                        <UsScore>{usScore || 0}</UsScore>
                    </ScoreWrapper>
                    <FlagImg src={UsaFlag} height='170' data-test='eu-flag' />
                </StyledDiv>
            </StyledHeader>
        );
    }
}

const mapStateToProps = ({ ryderCup: { euScore, usScore } }) => ({
    euScore,
    usScore
});

export default connect(mapStateToProps)(ResultsHeader);
