import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import SubHeader from '../../../components/sub-header';
import { Button, RedDiv, Span20px, Dropdown } from '../../../ui';
import { selectGroup } from '../../../store/actions';
import { CenteredDiv } from './ScoringLandingPage.styles';

export class DisconnectedScoringLandingPage extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            dropdownOneValue: '',
            dropdownTwoValue: '',
            dropdownTwoDisabled: true,
            buttonDisabled: true
        };
    }

    dropdownOneOnChange = event => {
        const { dropdownTwoDisabled } = this.state;

        if (dropdownTwoDisabled) {
            this.setState({
                dropdownOneValue: event.target.value,
                dropdownTwoDisabled: false
            });
        } else {
            this.setState({
                dropdownOneValue: event.target.value
            });
        }
    };

    dropdownTwoOnChange = event => {
        const { buttonDisabled } = this.state;
        if (buttonDisabled) {
            this.setState({
                dropdownTwoValue: event.target.value,
                buttonDisabled: false
            });
        } else {
            this.setState({
                dropdownTwoValue: event.target.value
            });
        }
    };

    handleSubmit = () => {
        const { dropdownOneValue, dropdownTwoValue } = this.state;
        const { history, groupSelected } = this.props;
        if (!(dropdownOneValue === ' - ' || dropdownTwoValue === ' - ')) {
            groupSelected(dropdownTwoValue);
            switch (dropdownOneValue) {
                case 'Friday': {
                    history.push('/stableford');
                    break;
                }
                case 'Saturday': {
                    history.push('/texas-scramble');
                    break;
                }
                case 'Sunday': {
                    history.push('/ryder-cup');
                    break;
                }
                default: {
                    history.push('/ryder-cup');
                    break;
                }
            }
        }
    };

    groupPlayersIntoMatches = players => {
        const groups = [];
        const numberOfGroups = [...new Set(players.map(p => p.group))];
        const { dropdownOneValue } = this.state;
        const vsString = dropdownOneValue === 'Sunday' ? 'vs' : ' ';

        numberOfGroups.map(groupNumber => {
            const playersInGroup = players.filter(
                player => player.group === groupNumber
            );

            const matchNumbers = [...new Set(playersInGroup.map(p => p.match))];
            const matchesInGroup = [];
            matchNumbers.map(match => {
                const playersInMatch = players.filter(
                    player => player.match === match
                );

                matchesInGroup.push(
                    `${playersInMatch[0].name} ${vsString} ${playersInMatch[1].name}`
                );
                return null;
            });

            groups.push(
                `Group ${groupNumber} - \n ${matchesInGroup[0]} \n ${matchesInGroup[1]}`
            );
            return null;
        });

        return groups;
    };

    sortByGroup = players => {
        const newGroup = players.reduce((newObj, obj) => {
            newObj[obj.group] = newObj[obj.group] || [];
            newObj[obj.group].push(obj);
            return newObj;
        }, Object.create(null));

        return newGroup;
    };

    sortDisabledGroup = players => {
        const returnArray = [];
        const sortedGroup = this.sortByGroup(players);
        const allEqual = arr => arr.every(v => v === arr[0]);

        Object.keys(sortedGroup).map(key => {
            const equalArray = [];

            sortedGroup[key].map((innerKey, innerIndex) => {
                equalArray.push(sortedGroup[key][innerIndex].matchComplete);
                return null;
            });

            if (allEqual(equalArray)) {
                returnArray.push(sortedGroup[key][0].matchComplete);
            } else {
                returnArray.push(false);
            }
            return null;
        });

        return returnArray;
    };

    render() {
        const {
            dropdownOneValue,
            dropdownTwoValue,
            dropdownTwoDisabled,
            buttonDisabled
        } = this.state;

        const { allPlayers, allPlayersStable } = this.props;

        return (
            <RedDiv>
                <SubHeader data-test='component-subheader'>
                    <Span20px>May 8th - 10th</Span20px>
                    <Span20px>La Manga Club Resort</Span20px>
                </SubHeader>
                <CenteredDiv>
                    <Dropdown
                        data-test='component-dropdown'
                        disabled={false}
                        label='What day are you scoring for?'
                        options={['Friday', 'Saturday', 'Sunday']}
                        onChange={this.dropdownOneOnChange}
                        value={dropdownOneValue}
                    />

                    <Dropdown
                        data-test='component-multiline-dropdown'
                        disabled={dropdownTwoDisabled}
                        label='Who are you scoring for?'
                        options={
                            dropdownOneValue === 'Sunday'
                                ? this.groupPlayersIntoMatches(allPlayers)
                                : this.groupPlayersIntoMatches(allPlayersStable)
                        }
                        onChange={this.dropdownTwoOnChange}
                        disabledOptions={
                            dropdownOneValue === 'Sunday'
                                ? this.sortDisabledGroup(allPlayers)
                                : this.sortDisabledGroup(allPlayersStable)
                        }
                        value={dropdownTwoValue}
                    />
                    <Button
                        onClick={this.handleSubmit}
                        data-test='component-button'
                        style={{ marginTop: '10px' }}
                        disabled={buttonDisabled ? 'disabled' : undefined}
                    >
                        Submit
                    </Button>
                </CenteredDiv>
            </RedDiv>
        );
    }
}

const mapStateToProps = ({
    ryderCup: { allPlayers },
    stableford: { allPlayers: allPlayersStable }
}) => ({
    allPlayers,
    allPlayersStable
});

const mapDispatchToProps = dispatch => ({
    groupSelected: groupNumber => dispatch(selectGroup(groupNumber))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(DisconnectedScoringLandingPage));
