import React from 'react';
import SubHeader from '../../components/sub-header';
import Panel from '../../components/panel/Panel';

import { RedDiv, Span20px } from '../../ui';

import EnterScores from '../../shared/images/EnterScores.svg';
import MyItinerary from '../../shared/images/MyItinerary.svg';
import MatchFormats from '../../shared/images/MatchFormats.svg';
import RyderCup from '../../shared/images/RyderCup.svg';

const homeDetails = [
    {
        id: 'a1',
        image: EnterScores,
        name: 'Enter Scores',
        link: '/enter-scores'
    },
    {
        id: 'a2',
        image: MyItinerary,
        name: 'My Itinerary',
        link: '/my-itinerary'
    },
    {
        id: 'a3',
        image: MatchFormats,
        name: 'Match Formats',
        link: '/match-formats'
    },
    {
        id: 'a4',
        image: RyderCup,
        name: 'RyderCup ScoreBoard',
        link: '/scoreboard'
    }
];

export default () => (
    <RedDiv>
        <SubHeader data-test='component-subheader'>
            <Span20px>May 8th - 10th</Span20px>
            <Span20px>La Manga Club Resort</Span20px>
        </SubHeader>
        <Panel list={homeDetails} />
    </RedDiv>
);
