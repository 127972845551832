import React from 'react';
import styled from 'styled-components';
import { CenterDiv, MarginX10px } from './Layout';
import { colours } from '../global';

const Chevron = styled.div`
    position: relative;
    display: block;
    height: 30px; /*height should be double border*/
    transform: rotate(
        ${props => (props.direction === 'left' ? '90deg' : '-90deg')}
    );
    left: ${props => (props.direction === 'left' ? '-15px' : '15px')};
    top: ${props => (props.direction === 'left' ? '-15px' : '15px')};
    z-index: 0;

    ::before,
    ::after {
        position: absolute;
        display: block;
        content: '';
        border: 15px solid transparent; /*adjust size*/
    }
    /*Change the four instances of 'top' below to rotate (top/right/bottom/left)*/
    ::before {
        top: 0;
        border-top-color: ${colours.charcoal};
    }
    ::after {
        top: -7px; /*adjust thickness*/
        border-top-color: ${colours.grey_light}; /*Match background colour*/
    }
`;

export const RightArrow = ({ onClick, hidden, children }) => {
    return (
        <CenterDiv hidden={hidden} onClick={onClick}>
            <MarginX10px>{children} </MarginX10px>
            <Chevron direction='right' />
        </CenterDiv>
    );
};

export const LeftArrow = ({ onClick, hidden, children }) => {
    return (
        <CenterDiv hidden={hidden} onClick={onClick}>
            <Chevron direction='left' />
            <MarginX10px>{children} </MarginX10px>
        </CenterDiv>
    );
};
