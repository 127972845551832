import React, { useState } from 'react';
import styled from 'styled-components';
import { ScoreButton } from './Buttons';
import { WhiteCard, VerticalDiv } from './Layout';
import { Span20px } from './Typography';
import { colours } from '../global';

const FlexDiv = styled.div`
    display: flex;
    justify-content: space-between;
`;

const AccordionContent = styled.div`
    height: auto;
    max-height: 0;
    transition: max-height 0.1s ease-in;
    overflow: hidden;

    &.open {
        max-height: 900px;
        border-top: 2px solid ${colours.grey_mid};
        margin-top: 10px;
    }
`;

const Accordion = ({ icon, title, children }) => {
    const [expanded, setExpanded] = useState(false);
    return (
        <WhiteCard>
            <FlexDiv onClick={() => setExpanded(!expanded)}>
                <Span20px>
                    {icon} {title.toUpperCase()}
                </Span20px>
                {!expanded ? (
                    <ScoreButton>+</ScoreButton>
                ) : (
                    <ScoreButton>-</ScoreButton>
                )}
            </FlexDiv>
            <AccordionContent className={expanded ? 'open' : null}>
                <VerticalDiv>{children}</VerticalDiv>
            </AccordionContent>
        </WhiteCard>
    );
};

export default Accordion;
