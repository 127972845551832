import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import { EuResult } from '../../../utils';
import {
    LightGreyDiv,
    CenterDiv,
    Button,
    RightArrow,
    LeftArrow,
    WhiteCard,
    VerticalDiv
} from '../../../ui';
import Flex from '../../../ui/Layout/Flex';
import {
    incrementActiveHole,
    decrementActiveHole,
    toggleScoreEditable,
    saveData,
    endRound
} from '../../../store/actions';
import Modal from '../../../components/modal';
import EndRoundModalContent from './model-content/end-round.modal';
import EndRoundErrorModalContent from './model-content/end-round-error.modal';
import MatchSummary from './match-summary/MatchSummaryStableford';
import HoleDetails from './hole-details/HoleDetails';
import PlayerGroup from './hole-details/player-group/PlayerGroup';
import { getCookie } from '../../../utils/cookies.util';

import HintOverlay from '../../hint-overlay/HintOverlay';

export class DisconnectedStablefordLandingPage extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false
        };
    }

    submitData = () => {
        const {
            handleChangeEditState,
            players,
            holes,
            activeHole,
            saveData: saveHoleData
        } = this.props;
        const currentHole = holes[activeHole - 1];
        const holeEditable = currentHole.editable;
        const match1 = EuResult(
            players[0],
            players[2],
            currentHole,
            activeHole
        );
        const match2 = EuResult(
            players[1],
            players[3],
            currentHole,
            activeHole
        );

        handleChangeEditState();
        if (holeEditable) saveHoleData(match1, match2, players, currentHole);
    };

    toggleModal = () => {
        const { showModal } = this.state;
        this.setState({ showModal: !showModal });
    };

    getUnsubmittedHoles = () => {
        const { holes } = this.props;
        return holes.filter(hole => hole.editable);
    };

    isMatchCompleted = () => {
        const { allPlayers, players } = this.props;

        const groupNumber = players ? players[0].group : 0;
        const group = allPlayers.filter(player => player.group === groupNumber);

        if (group.length === 0) return false;

        return group[0].matchComplete || false;
    };

    render() {
        const { showModal } = this.state;
        const {
            holes,
            activeHole,
            handleNavigateLeft,
            handleNavigateRight,
            // eslint-disable-next-line no-shadow
            endRound
        } = this.props;

        if (this.isMatchCompleted()) return <Redirect to='/' />;
        if (!activeHole) return <Redirect to='/' />;
        if (!getCookie('AND Open Texas Scramble Hint')) {
            return <HintOverlay gameType='Texas Scramble' />;
        }

        const holeEditable = holes[activeHole - 1].editable;
        return (
            <>
                <LightGreyDiv data-test='component-ryder-cup'>
                    <HoleDetails
                        hole={holes.find(hole => hole.number === activeHole)}
                        data-test='hole-details'
                    />

                    <VerticalDiv>
                        <CenterDiv>
                            <LeftArrow
                                hidden={activeHole === 1}
                                data-test='left-arrow'
                                onClick={handleNavigateLeft}
                            >
                                {activeHole - 1}
                            </LeftArrow>
                            {activeHole !== holes.length ? (
                                <Button
                                    data-test='change-score-state-button'
                                    onClick={() => {
                                        this.submitData();
                                    }}
                                >
                                    {holeEditable ? 'Save Score' : 'Edit Score'}
                                </Button>
                            ) : (
                                <Button
                                    data-test='change-score-state-button'
                                    onClick={() => {
                                        this.submitData();
                                        this.toggleModal();
                                    }}
                                >
                                    {holeEditable
                                        ? 'Save and End Round'
                                        : 'Edit Score'}
                                </Button>
                            )}

                            <RightArrow
                                hidden={activeHole === holes.length}
                                data-test='right-arrow'
                                onClick={handleNavigateRight}
                            >
                                {activeHole + 1}
                            </RightArrow>
                        </CenterDiv>
                    </VerticalDiv>
                    <VerticalDiv>
                        <WhiteCard>
                            <Flex
                                justifyContent='space-between'
                                fontWeight='bold'
                                margin='15px 20px 15px 0px'
                            >
                                <Flex>Drives taken</Flex>
                                <Flex width='150px'>Team Score</Flex>
                            </Flex>
                            <PlayerGroup data-test='component-player-group' />
                            <MatchSummary />
                        </WhiteCard>
                    </VerticalDiv>
                </LightGreyDiv>

                {showModal ? (
                    <Modal error={this.getUnsubmittedHoles().length > 0}>
                        {this.getUnsubmittedHoles().length > 0 ? (
                            <EndRoundErrorModalContent
                                toggleModal={() => {
                                    const {
                                        handleChangeEditState
                                    } = this.props;
                                    this.toggleModal();
                                    handleChangeEditState();
                                }}
                                incompleteHoles={this.getUnsubmittedHoles()}
                            />
                        ) : (
                            <EndRoundModalContent
                                toggleModal={() => {
                                    const {
                                        handleChangeEditState
                                    } = this.props;
                                    this.toggleModal();
                                    handleChangeEditState();
                                }}
                                endRound={() => {
                                    this.submitData();
                                    endRound();
                                }}
                            />
                        )}
                    </Modal>
                ) : null}
            </>
        );
    }
}

const mapStateToProps = ({
    ryderCup: { holes, allPlayers, activeHole, players, liveScores }
}) => ({
    holes,
    activeHole,
    allPlayers,
    players,
    liveScores
});

const mapDispatchToProps = dispatch => {
    return {
        saveData: (match1, match2, players, currentHole) =>
            dispatch(
                saveData(match1, match2, players, currentHole, 'stableford')
            ),
        handleNavigateLeft: () => dispatch(decrementActiveHole()),
        handleNavigateRight: () => dispatch(incrementActiveHole()),
        handleChangeEditState: () => dispatch(toggleScoreEditable()),
        endRound: () => dispatch(endRound())
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DisconnectedStablefordLandingPage);
