import React from 'react';
import SubHeader from '../../components/sub-header';
import { RedDiv, Span20px } from '../../ui';
import Panel from '../../components/panel/Panel';
import AndOpen from '../../shared/images/AndOpen.svg';
import TexasScramble from '../../shared/images/TexasScramble.svg';
import RyderCup from '../../shared/images/RyderCup.svg';
import BonusRounds from '../../shared/images/BonusRounds.svg';

const matchDetails = [
    {
        id: 'a11',
        image: AndOpen,
        name: 'And Open',
        link: '/match-formats/the-and-open'
    },
    {
        id: 'a12',
        image: TexasScramble,
        name: 'Texas Scramble',
        link: '/match-formats/texas-scramble'
    },
    {
        id: 'a13',
        image: RyderCup,
        name: 'Ryder Cup',
        link: '/match-formats/ryder-cup'
    },
    {
        id: 'a14',
        image: BonusRounds,
        name: 'Bonus Rounds',
        link: '/match-formats/bonus-rounds'
    }
];

export default () => (
    <RedDiv>
        <SubHeader data-test='component-subheader'>
            <Span20px>Match Formats</Span20px>
        </SubHeader>
        <Panel list={matchDetails} />
    </RedDiv>
);
