export default {
    red: '#D21717',
    green: '#5AC328',
    blue: '#2897FF',
    charcoal: '#323232',
    grey_light: '#F2F2F2',
    grey_mid: '#D3D4D8',
    grey_dark: '#E5E5E5',
    grey_draw: '#8f8f8f',
    black: '#000'
};
