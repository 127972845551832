import React from 'react';
import { Link } from 'react-router-dom';
import {
    MainSectionContainer,
    MainSectionContent,
    MainSectionImgHolder,
    MainSectionButton
} from '../../ui';

const Panel = props => {
    const newProps = props;

    return (
        <MainSectionContainer>
            {newProps.list.map(item => {
                return (
                    <MainSectionContent key={item.id}>
                        <Link to={item.link}>
                            <MainSectionImgHolder
                                src={item.image}
                                alt={item.name}
                            />
                            <MainSectionButton>{item.name}</MainSectionButton>
                        </Link>
                    </MainSectionContent>
                );
            })}
        </MainSectionContainer>
    );
};

export default Panel;
