import styled from 'styled-components';

const SlideContainer = styled.div`
    position: fixed;
    z-index: 100;
    height: 100vh !important;
    width: 100vw !important;
    margin: 0 auto;
    overflow-x: hidden;
    white-space: nowrap;
`;

const Slide = styled.div`
    background-image: url(${props => props.content});
    height: 100%;
    width: 100%;
    min-height: 800px;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: scroll;
    background-position: 100% 100%;
    z-index: 100;
`;

const SliderContent = styled.div`
    transform: translateX(-${props => props.translate}px);
    transition: transform ease-out ${props => props.transition}s;
    height: 100%;
    background-size: contain;
    width: ${props => props.width}px;
    display: flex;
`;

export { SlideContainer, Slide, SliderContent };
