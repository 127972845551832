import styled from 'styled-components';
import { colours } from '../../../../global';

export const MatchTextWrapper = styled.div`
    position: absolute;
    background-color: ${colours.charcoal};
    color: white;
    left: -3px;
    top: -3px;
    padding: 3px 30px 3px 10px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    clip-path: polygon(0 0, 100% 0, 75% 100%, 0 100%);
    border-radius: 5px;
`;

export const Grid = styled.div`
    margin-top: 20px;
    display: grid;
`;

export const GridHeadingRed = styled.span`
    color: ${colours.red};
    font-weight: bold;
`;

export const GridHeadingBlue = styled.span`
    color: ${colours.blue};
    font-weight: bold;
`;

export const SummaryCircleScore = styled.div`
    width: 22px;
    height: 22px;
    border-radius: 25px;
    background-color: ${props => {
        if (props.result === 'lose') return colours.red;
        if (props.result === 'win') return colours.blue;
        if (props.result === 'draw') return colours.grey_light;
        return 'none';
    }};

    border: 1px solid
        ${props => {
            if (props.result === 'lose') return colours.red;
            if (props.result === 'win') return colours.blue;
            if (props.result === 'draw') return colours.grey_light;
            return colours.charcoal;
        }};
`;

export const CircleScore = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    background-color: ${props => {
        if (props.result === 'lose') return colours.red;
        if (props.result === 'win') return colours.blue;
        return colours.grey_light;
    }};
`;
