import styled from 'styled-components';
//  import colours from '../../global/colours';

export const StyledNavButton = styled.div`
    padding: 5px;
    width: 80px;
    height: 50px;
    margin: 15px;
    text-align: center;

    a {
        &.is-active {
            opacity: 100%;
        }
        color: #000;
        text-decoration: none;
        opacity: 40%;
    }
    p {
        margin-bottom: 15px;
    }
`;

export const StyledNavImg = styled.img`
    text-align: center;
    width: 40px;
`;
