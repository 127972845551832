import { combineReducers } from 'redux';
import ItineraryReducer from './itinerary.reducer';
import RyderCupReducer from './ryder-cup.reducer';
import StablefordReducer from './stableford.reducer';
import TexasScrambleReducer from './texas-scramble.reducer';

export default combineReducers({
    itinerary: ItineraryReducer,
    ryderCup: RyderCupReducer,
    stableford: StablefordReducer,
    texasScramble: TexasScrambleReducer
});
