import React, { useState } from 'react';
import styled from 'styled-components';
import { ScoreButton } from './Buttons';
import { WhiteCard, VerticalDiv } from './Layout';
import { Span20px } from './Typography';

const FlexDiv = styled.div`
    display: flex;
    justify-content: space-between;
`;

const AccordionContent = styled.div`
    height: auto;
    max-height: 0;
    transition: max-height 0.1s ease-in;
    overflow: hidden;

    &.open {
        max-height: 2000px;
    }
`;

const AccordionCards = ({ icon, title, children }) => {
    const [expanded, setExpanded] = useState(false);
    return (
        <>
            <WhiteCard>
                <FlexDiv onClick={() => setExpanded(!expanded)}>
                    <Span20px>
                        {icon} {title.toUpperCase()}
                    </Span20px>
                    {!expanded ? (
                        <ScoreButton>+</ScoreButton>
                    ) : (
                        <ScoreButton>-</ScoreButton>
                    )}
                </FlexDiv>
            </WhiteCard>
            <AccordionContent className={expanded ? 'open' : null}>
                <VerticalDiv>
                    {children.map(child => (
                        <WhiteCard key={Math.random()}>{child}</WhiteCard>
                    ))}
                </VerticalDiv>
            </AccordionContent>
        </>
    );
};

export default AccordionCards;
