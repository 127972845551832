import React, { useState } from 'react';
import { useStore } from 'react-redux';
import styled from 'styled-components';
import {
    MarginX10px,
    SpaceAroundDiv,
    MarginTop29px,
    Span16px,
    SmallButton
} from '../../ui';
import { colours } from '../../global';
import { resetData } from '../../store/actions';
import Modal from '../../components/modal';

const DangerButton = styled.button`
    background: red;
    color: white;
    padding: 30px 80px;
    font-size: 20px;
    border-radius: 5px;
`;

const RyansSecretPage = () => {
    const { stableford, texasScramble } = useStore().getState();
    const [showModal, setShowModal] = useState(false);
    const { liveScores, allPlayers } = stableford;

    /*  I'm sorry   */

    const generateScoreData = () => {
        const dataObj = [];
        // eslint-disable-next-line array-callback-return
        Object.keys(liveScores).map((key, index) => {
            const matchNumber = `match${index + 1}`;
            let holeIndex = 0;
            for (let i = 0; i < allPlayers.length; i += 1) {
                if (allPlayers[i].match === index + 1) {
                    const nameObj = {
                        name: allPlayers[i].name,
                        hole1:
                            holeIndex === 0
                                ? liveScores[matchNumber].hole1.shot1Net
                                : liveScores[matchNumber].hole1.shot2Net,
                        hole2:
                            holeIndex === 0
                                ? liveScores[matchNumber].hole2.shot1Net
                                : liveScores[matchNumber].hole2.shot2Net,
                        hole3:
                            holeIndex === 0
                                ? liveScores[matchNumber].hole3.shot1Net
                                : liveScores[matchNumber].hole3.shot2Net,
                        hole4:
                            holeIndex === 0
                                ? liveScores[matchNumber].hole4.shot1Net
                                : liveScores[matchNumber].hole4.shot2Net,
                        hole5:
                            holeIndex === 0
                                ? liveScores[matchNumber].hole5.shot1Net
                                : liveScores[matchNumber].hole5.shot2Net,
                        hole6:
                            holeIndex === 0
                                ? liveScores[matchNumber].hole6.shot1Net
                                : liveScores[matchNumber].hole6.shot2Net,
                        hole7:
                            holeIndex === 0
                                ? liveScores[matchNumber].hole7.shot1Net
                                : liveScores[matchNumber].hole7.shot2Net,
                        hole8:
                            holeIndex === 0
                                ? liveScores[matchNumber].hole8.shot1Net
                                : liveScores[matchNumber].hole8.shot2Net,
                        hole9:
                            holeIndex === 0
                                ? liveScores[matchNumber].hole9.shot1Net
                                : liveScores[matchNumber].hole9.shot2Net,
                        hole10:
                            holeIndex === 0
                                ? liveScores[matchNumber].hole10.shot1Net
                                : liveScores[matchNumber].hole10.shot2Net,
                        hole11:
                            holeIndex === 0
                                ? liveScores[matchNumber].hole11.shot1Net
                                : liveScores[matchNumber].hole11.shot2Net,
                        hole12:
                            holeIndex === 0
                                ? liveScores[matchNumber].hole12.shot1Net
                                : liveScores[matchNumber].hole12.shot2Net,
                        hole13:
                            holeIndex === 0
                                ? liveScores[matchNumber].hole13.shot1Net
                                : liveScores[matchNumber].hole13.shot2Net,
                        hole14:
                            holeIndex === 0
                                ? liveScores[matchNumber].hole14.shot1Net
                                : liveScores[matchNumber].hole14.shot2Net,
                        hole15:
                            holeIndex === 0
                                ? liveScores[matchNumber].hole15.shot1Net
                                : liveScores[matchNumber].hole15.shot2Net,
                        hole16:
                            holeIndex === 0
                                ? liveScores[matchNumber].hole16.shot1Net
                                : liveScores[matchNumber].hole16.shot2Net,
                        hole17:
                            holeIndex === 0
                                ? liveScores[matchNumber].hole17.shot1Net
                                : liveScores[matchNumber].hole17.shot2Net,
                        hole18:
                            holeIndex === 0
                                ? liveScores[matchNumber].hole18.shot1Net
                                : liveScores[matchNumber].hole18.shot2Net
                    };
                    nameObj.total =
                        nameObj.hole1 +
                        nameObj.hole2 +
                        nameObj.hole3 +
                        nameObj.hole4 +
                        nameObj.hole5 +
                        nameObj.hole6 +
                        nameObj.hole7 +
                        nameObj.hole8 +
                        nameObj.hole9 +
                        nameObj.hole10 +
                        nameObj.hole11 +
                        nameObj.hole12 +
                        nameObj.hole13 +
                        nameObj.hole14 +
                        nameObj.hole15 +
                        nameObj.hole16 +
                        nameObj.hole17 +
                        nameObj.hole18;
                    holeIndex += 1;
                    dataObj.push(nameObj);
                }
            }
        });
        return dataObj;
    };

    /*  I'm so sorry    */

    const renderTableHeader = () => {
        const data = generateScoreData();
        if (!data) return;
        // eslint-disable-next-line consistent-return
        return (
            <>
                <th>&nbsp;</th>
                <th>Hole 1 </th>
                <th>Hole 2 </th>
                <th>Hole 3 </th>
                <th>Hole 4 </th>
                <th>Hole 5 </th>
                <th>Hole 6 </th>
                <th>Hole 7 </th>
                <th>Hole 8 </th>
                <th>Hole 9 </th>
                <th>Hole 10 </th>
                <th>Hole 11 </th>
                <th>Hole 12 </th>
                <th>Hole 13 </th>
                <th>Hole 14 </th>
                <th>Hole 15 </th>
                <th>Hole 16 </th>
                <th>Hole 17 </th>
                <th>Hole 18 </th>
                <th>TOTAL</th>
            </>
        );
    };

    /*  Oh god, make it stop    */

    const renderTableData = () => {
        const data = generateScoreData();
        if (!data) return;
        // eslint-disable-next-line consistent-return
        return data.map(obj => {
            const {
                name,
                hole1,
                hole2,
                hole3,
                hole4,
                hole5,
                hole6,
                hole7,
                hole8,
                hole9,
                hole10,
                hole11,
                hole12,
                hole13,
                hole14,
                hole15,
                hole16,
                hole17,
                hole18,
                total
            } = obj;
            return (
                <tr>
                    <td>{name}</td>
                    <td>{hole1}</td>
                    <td>{hole2}</td>
                    <td>{hole3}</td>
                    <td>{hole4}</td>
                    <td>{hole5}</td>
                    <td>{hole6}</td>
                    <td>{hole7}</td>
                    <td>{hole8}</td>
                    <td>{hole9}</td>
                    <td>{hole10}</td>
                    <td>{hole11}</td>
                    <td>{hole12}</td>
                    <td>{hole13}</td>
                    <td>{hole14}</td>
                    <td>{hole15}</td>
                    <td>{hole16}</td>
                    <td>{hole17}</td>
                    <td>{hole18}</td>
                    <td>{total}</td>
                </tr>
            );
        });
    };

    return (
        <>
            <MarginX10px>
                <DangerButton onClick={() => setShowModal(true)}>
                    Reset Data
                </DangerButton>
                <h2>Stableford</h2>
                <table>
                    <tbody>
                        <tr>{renderTableHeader()}</tr>
                        {renderTableData()}
                    </tbody>
                </table>
                <pre>{JSON.stringify(stableford, null, 2)}</pre>;
                <br />
                <h2>Texas Scramble</h2>
                <pre>{JSON.stringify(texasScramble, null, 2)}</pre>;
            </MarginX10px>
            {showModal ? (
                <Modal onClick={() => setShowModal(false)}>
                    <Span16px>
                        By ending your round your scores will be submitted to
                        the tournament organiser and no longer be editable on
                        this application.
                    </Span16px>
                    &nbsp;
                    <Span16px>Are you ready to end your round?</Span16px>
                    <MarginTop29px>
                        <SpaceAroundDiv>
                            <SmallButton
                                color={colours.green}
                                data-test='end-round-button-yes'
                                onClick={() => {
                                    resetData();
                                    setShowModal(false);
                                }}
                            >
                                Yes
                            </SmallButton>
                            <SmallButton
                                color={colours.red}
                                data-test='end-round-button-no'
                                onClick={() => setShowModal(false)}
                            >
                                No
                            </SmallButton>
                        </SpaceAroundDiv>
                    </MarginTop29px>
                </Modal>
            ) : null}
        </>
    );
};

export default RyansSecretPage;
