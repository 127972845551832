import React from 'react';
import { createPortal } from 'react-dom';
import Flex from '../../ui/Layout/Flex';
import { CloseIcon } from '../../ui';

let modalRoot = document.getElementById('modal-root');
if (!modalRoot) {
    modalRoot = document.createElement('div');
    modalRoot.setAttribute('id', 'modal-root');
    document.body.appendChild(modalRoot);
}

class Modal extends React.Component {
    el = document.createElement('div');

    componentDidMount() {
        modalRoot.appendChild(this.el);
    }

    componentWillUnmount() {
        modalRoot.removeChild(this.el);
    }

    render() {
        const { children, error, onClick } = this.props;
        return createPortal(
            <Flex
                alignItems='center'
                backgroundColor='rgba(0, 0, 0, 0.9)'
                left={0}
                height='100vh'
                position='fixed'
                justifyContent='center'
                padding='45px'
                top={0}
                width='100wh'
                zIndex={100}
            >
                <Flex
                    backgroundColor='white'
                    border={error ? '2px solid red' : 'none'}
                    flexDirection='column'
                    padding='28px 20px'
                    position='relative'
                    id='modal-content'
                >
                    <CloseIcon onClick={onClick} data-testid='close-icon'>
                        x
                    </CloseIcon>
                    {children}
                </Flex>
            </Flex>,
            this.el
        );
    }
}

export default Modal;
