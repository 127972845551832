/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { Span16px } from '../../../../ui/Typography';
import UsaFlag from '../../../../ui/usa-flag.png';
import EuFlag from '../../../../ui/eu-flag.png';
import {
    FlagImg,
    ScoreContainer,
    RoundScoreContainer,
    NetSpan,
    GrossSpan,
    ShotModifierDiv
} from './Player.styles';
import { CenterVerticalDiv, VerticalDiv, ScoreButton } from '../../../../ui';

const Player = ({
    firstPlayerSecondMatch,
    firstPlayer,
    name,
    team,
    handicap,
    shots,
    editable,
    shotModifier,
    onIncrement,
    onDecrement,
    result,
    gt,
    matchResultSection,
    matchTotalScore
}) => {
    return (
        <CenterVerticalDiv
            marginTop={
                firstPlayer && editable && !matchResultSection ? '29px' : null
            }
            data-test='component-player'
        >
            <CenterVerticalDiv
                marginTop={
                    firstPlayer && !editable && !matchResultSection
                        ? '29px'
                        : null
                }
            >
                {gt !== 'stableford' ? (
                    team === 'America' ? (
                        <FlagImg
                            src={UsaFlag}
                            height='20'
                            data-test='usa-flag'
                        />
                    ) : (
                        <FlagImg src={EuFlag} height='20' data-test='eu-flag' />
                    )
                ) : null}
                <Span16px>
                    {name} ({handicap})
                </Span16px>
                {shotModifier > 0 && !matchResultSection ? (
                    <ShotModifierDiv data-test='shot-modifier'>
                        {shotModifier}
                    </ShotModifierDiv>
                ) : null}
            </CenterVerticalDiv>
            {editable && !matchResultSection ? (
                <CenterVerticalDiv>
                    <ScoreButton
                        data-test='dec-shot-count'
                        onClick={onDecrement}
                    >
                        -
                    </ScoreButton>
                    <ScoreContainer data-test='gross-shot-count'>
                        {shots}
                    </ScoreContainer>
                    <ScoreButton
                        data-test='inc-shot-count'
                        onClick={onIncrement}
                    >
                        +
                    </ScoreButton>
                </CenterVerticalDiv>
            ) : firstPlayer ? (
                <VerticalDiv>
                    {!matchResultSection ? (
                        <CenterVerticalDiv>
                            <GrossSpan>Gross</GrossSpan>
                            <NetSpan>Net</NetSpan>
                        </CenterVerticalDiv>
                    ) : null}
                    <CenterVerticalDiv>
                        <RoundScoreContainer data-test='gross-shot-count'>
                            {!matchResultSection
                                ? shots
                                : matchTotalScore.shot1}
                        </RoundScoreContainer>
                        <RoundScoreContainer
                            result={
                                gt !== 'stableford'
                                    ? result
                                    : matchResultSection
                                    ? 'blank'
                                    : 'stableford'
                            }
                            data-test='net-shot-count'
                        >
                            {!matchResultSection ? shots - shotModifier : null}
                        </RoundScoreContainer>
                    </CenterVerticalDiv>
                </VerticalDiv>
            ) : (
                <CenterVerticalDiv>
                    <RoundScoreContainer data-test='gross-shot-count'>
                        {!matchResultSection
                            ? shots
                            : firstPlayerSecondMatch
                            ? matchTotalScore.shot1
                            : matchTotalScore.shot2}
                    </RoundScoreContainer>
                    <RoundScoreContainer
                        result={
                            gt !== 'stableford'
                                ? result
                                : matchResultSection
                                ? 'blank'
                                : 'stableford'
                        }
                        data-test='net-shot-count'
                    >
                        {!matchResultSection ? shots - shotModifier : null}
                    </RoundScoreContainer>
                </CenterVerticalDiv>
            )}
        </CenterVerticalDiv>
    );
};

Player.propTypes = {
    name: PropTypes.string.isRequired,
    team: PropTypes.string.isRequired,
    handicap: PropTypes.number.isRequired,
    shots: PropTypes.number.isRequired,
    onIncrement: PropTypes.func.isRequired,
    onDecrement: PropTypes.func.isRequired
};

export default Player;
