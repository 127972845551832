import { DECREMENT_HOLE_SCORE, INCREMENT_HOLE_SCORE } from '../types';

export const initialState = {
    holes: null,
    allPlayers: null,
    players: null,
    activeHole: null
};

export default (state = initialState, action) => {
    let activeHole;
    switch (action.type) {
        case INCREMENT_HOLE_SCORE:
            activeHole =
                state.activeHole === state.holes.length
                    ? state.activeHole
                    : state.activeHole + 1;
            return {
                ...state,
                activeHole
            };
        case DECREMENT_HOLE_SCORE:
            activeHole =
                state.activeHole === 1
                    ? state.activeHole
                    : state.activeHole - 1;
            return {
                ...state,
                activeHole
            };

        default:
            return state;
    }
};
