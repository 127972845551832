import styled from 'styled-components';

export const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
`;

export const StyledHeaderWrapper = styled.div`
    display: flex;
    position: relative;
    flex: 15;
    flex-shrink: 0;
    flex-basis: auto;
`;

export const StyledContentWrapper = styled.div`
    display: flex;
    flex: 85;
    position: relative;
    padding-bottom: 110px;
    flex-shrink: 0;
    flex-basis: auto;
`;

export const StyledSpan = styled.span`
    font-size: 20px;
`;

export const CenteredDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 80%;
`;
