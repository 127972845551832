import React from 'react';
import {
    WhiteCard,
    Span14px,
    Span16px,
    SpaceAroundDiv,
    CenterDiv
} from '../../../../ui';
import { CircleScore, CircleText } from '../../scorecard-page/Scorecard.styles';
import {
    MatchTextWrapper,
    Grid,
    GridHeadingBlue,
    GridHeadingRed,
    SummaryCircleScore
} from './MatchSummaryRyder.styles';

const MatchSummary = ({ players, liveScores }) => {
    const [playerEu, playerUsa] = players;
    let leadAmount = 0;
    let leaderString = '';

    const getMatchScore = () => {
        let euLead = 0;
        let holesRemaining = 9;
        let matchComplete = false;
        for (let index = 1; index <= 9; index += 1) {
            const euResult = liveScores[`hole${index}`].result;
            if (euResult !== 'undefined') holesRemaining -= 1;
            if (euResult === 'win') euLead += 1;
            else if (euResult === 'lose') euLead -= 1;
            if (
                Math.abs(euLead) > holesRemaining ||
                (euLead === 0 && holesRemaining === 0)
            ) {
                matchComplete = true;
                break;
            }
        }

        if (matchComplete && holesRemaining > 0) {
            leadAmount = `${Math.abs(euLead)} & ${holesRemaining}`;
            leaderString = euLead > 0 ? 'win' : 'lose';
        } else if (Math.abs(euLead)) {
            leadAmount = `${Math.abs(euLead)}UP`;
            leaderString = euLead > 0 ? 'win' : 'lose';
        } else {
            leadAmount = '';
            leaderString = 'draw';
        }

        return {
            leadAmount,
            leaderString
        };
    };

    return (
        <WhiteCard>
            <MatchTextWrapper>
                <Span14px>Match {playerEu.match}</Span14px>
            </MatchTextWrapper>
            <Grid>
                <GridHeadingBlue style={{ gridArea: '1/1/2/2' }}>
                    Europe
                </GridHeadingBlue>
                <GridHeadingRed style={{ gridArea: '1/2/2/3' }}>
                    USA
                </GridHeadingRed>
                <Span16px style={{ gridArea: '1/3/2/4', textAlign: 'center' }}>
                    Score
                </Span16px>
                <Span16px style={{ gridArea: '2/1/3/2' }}>
                    {playerEu.name.split(' ')[1]}
                </Span16px>
                <Span16px style={{ gridArea: '2/2/3/3' }}>
                    {playerUsa.name.split(' ')[1]}
                </Span16px>
                <CenterDiv style={{ gridArea: '2/3/4/4' }}>
                    <CircleScore result={getMatchScore().leaderString}>
                        <CircleText result={getMatchScore().leaderString}>
                            {getMatchScore().leadAmount}
                        </CircleText>
                    </CircleScore>
                </CenterDiv>
                <SpaceAroundDiv style={{ gridArea: '3/1/4/3' }}>
                    {[0, 1, 2, 3, 4, 5, 6, 7, 8].map(hole => (
                        <SummaryCircleScore
                            key={hole}
                            result={liveScores[`hole${hole + 1}`].result}
                        />
                    ))}
                </SpaceAroundDiv>
            </Grid>
        </WhiteCard>
    );
};

export default MatchSummary;
