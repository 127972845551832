import React from 'react';
import { connect } from 'react-redux';
import UsaFlag from '../../../ui/usa-flag.png';
import EuFlag from '../../../ui/eu-flag.png';
import { getLiveScores, headerScoresIncrement } from '../../../store/actions';
import {
    CircleScore,
    Column,
    Row,
    TableWrapper,
    CircleText,
    ResultText,
    Spacer,
    DrawText
} from './Scorecard.styles';
//  end imports
export class DisconnectedScorecardPage extends React.PureComponent {
    componentDidMount() {
        const { getLiveScores: loadLiveScores } = this.props;
        loadLiveScores();
    }

    splitByGroup = (array, property) => {
        return array.reduce((memo, x) => {
            if (!memo[x[property]]) memo[x[property]] = [];
            memo[x[property]].push(x);
            return memo;
        }, {});
    };

    generateData = (array, scores) => {
        const groupArray = this.splitByGroup(array, 'match');
        const MatchArray = [];

        Object.keys(groupArray).forEach((keyName, index) => {
            const groupText = groupArray[keyName][0].group;
            const matchText = groupArray[keyName][0].match;
            const euText = groupArray[keyName][0].name;
            const usText = groupArray[keyName][1].name;
            const { matchComplete } = groupArray[keyName][0];

            MatchArray.push({
                id: index,
                groupNo: groupText,
                match: matchText,
                europeName: euText,
                usaName: usText,
                hole1: scores[`match${keyName}`].hole1,
                hole2: scores[`match${keyName}`].hole2,
                hole3: scores[`match${keyName}`].hole3,
                hole4: scores[`match${keyName}`].hole4,
                hole5: scores[`match${keyName}`].hole5,
                hole6: scores[`match${keyName}`].hole6,
                hole7: scores[`match${keyName}`].hole7,
                hole8: scores[`match${keyName}`].hole8,
                hole9: scores[`match${keyName}`].hole9,
                completed: matchComplete
            });
        });
        return MatchArray;
    };

    generateScores = data => {
        const results = [...data];

        results.map(match => {
            let euLead = 0;
            let holesRemaining = 9;
            let matchComplete = false;
            for (let index = 1; index <= 9; index += 1) {
                const euResult = match[`hole${index}`].result;
                if (euResult !== 'undefined') holesRemaining -= 1;
                if (euResult === 'win') euLead += 1;
                else if (euResult === 'lose') euLead -= 1;
                if (
                    Math.abs(euLead) > holesRemaining ||
                    (euLead === 0 && holesRemaining === 0)
                ) {
                    matchComplete = true;
                    break;
                }
            }

            if (matchComplete && holesRemaining > 0) {
                match.leadAmount = `${Math.abs(euLead)} & ${holesRemaining}`;
                match.leader = euLead > 0 ? EuFlag : UsaFlag;
                match.leaderString = euLead > 0 ? 'win' : 'lose';
            } else if (Math.abs(euLead)) {
                match.leadAmount = `${Math.abs(euLead)}UP`;
                match.leader = euLead > 0 ? EuFlag : UsaFlag;
                match.leaderString = euLead > 0 ? 'win' : 'lose';
            } else {
                match.leadAmount = 0;
                match.leader = 'draw';
                match.leaderString = 'draw';
            }
            match.matchComplete = matchComplete;
            return match;
        });

        return results;
    };

    generateHeaderScore = data => {
        const headerResultObject = {
            euScore: 0,
            usScore: 0
        };

        data.map(match => {
            const { matchComplete, leaderString } = match;
            if (matchComplete) {
                if (leaderString === 'win') {
                    headerResultObject.euScore += 1;
                } else if (leaderString === 'lose') {
                    headerResultObject.usScore += 1;
                } else {
                    headerResultObject.usScore += 0.5;
                    headerResultObject.euScore += 0.5;
                }
            }
            return match;
        });

        return headerResultObject;
    };

    render() {
        const { allPlayers, liveScores, sendHeaderScores } = this.props;
        const scoreData = liveScores
            ? this.generateData(allPlayers, liveScores)
            : null;

        const resultsData = liveScores ? this.generateScores(scoreData) : null;
        const headerObject = liveScores
            ? this.generateHeaderScore(resultsData)
            : null;
        if (liveScores) sendHeaderScores(headerObject);

        return (
            <TableWrapper data-test='table-container'>
                <Row>
                    <Column span='4'>
                        <h2>Group</h2>
                    </Column>
                    <Column span='4'>
                        <h2>Match</h2>
                    </Column>
                    <Column blue span='5'>
                        <h2>EUROPE</h2>
                    </Column>
                    <Column span='1'>&nbsp;</Column>
                    <Column red span='5'>
                        <h2>USA</h2>
                    </Column>
                    <Column collapse right span='3'>
                        <h2>HOLE</h2>
                    </Column>
                    <Column collapse center span='1'>
                        <h2>1</h2>
                    </Column>
                    <Column collapse center span='1'>
                        <h2>2</h2>
                    </Column>
                    <Column collapse center span='1'>
                        <h2>3</h2>
                    </Column>
                    <Column collapse center span='1'>
                        <h2>4</h2>
                    </Column>
                    <Column collapse center span='1'>
                        <h2>5</h2>
                    </Column>
                    <Column collapse center span='1'>
                        <h2>6</h2>
                    </Column>
                    <Column collapse center span='1'>
                        <h2>7</h2>
                    </Column>
                    <Column collapse center span='1'>
                        <h2>8</h2>
                    </Column>
                    <Column collapse center span='1'>
                        <h2>9</h2>
                    </Column>
                    <Column center span='4'>
                        &nbsp;
                    </Column>
                </Row>
                {liveScores ? (
                    resultsData.map(obj => (
                        <Row key={obj.id}>
                            <Column span='4'>
                                <h3>{obj.groupNo}</h3>
                            </Column>
                            <Column span='4'>
                                <h3>{obj.match}</h3>
                            </Column>
                            <Column blue span='5'>
                                <h3>{obj.europeName}</h3>
                            </Column>
                            <Column right span='1'>
                                <p>vs.</p>
                            </Column>
                            <Column red span='5'>
                                <h3>{obj.usaName}</h3>
                            </Column>
                            <Column collapse span='3' right>
                                &nbsp;
                            </Column>
                            <Column collapse center span='1'>
                                <CircleScore result={obj.hole1.result}>
                                    <CircleText result={obj.hole1.result} />
                                </CircleScore>
                            </Column>
                            <Column collapse center span='1'>
                                <CircleScore result={obj.hole2.result}>
                                    <CircleText result={obj.hole2.result} />
                                </CircleScore>
                            </Column>
                            <Column collapse center span='1'>
                                <CircleScore result={obj.hole3.result}>
                                    <CircleText result={obj.hole3.result} />
                                </CircleScore>
                            </Column>
                            <Column collapse center span='1'>
                                <CircleScore result={obj.hole4.result}>
                                    <CircleText result={obj.hole4.result} />
                                </CircleScore>
                            </Column>
                            <Column collapse center span='1'>
                                <CircleScore result={obj.hole5.result}>
                                    <CircleText result={obj.hole5.result} />
                                </CircleScore>
                            </Column>
                            <Column collapse center span='1'>
                                <CircleScore result={obj.hole6.result}>
                                    <CircleText result={obj.hole6.result} />
                                </CircleScore>
                            </Column>
                            <Column collapse center span='1'>
                                <CircleScore result={obj.hole7.result}>
                                    <CircleText result={obj.hole7.result} />
                                </CircleScore>
                            </Column>
                            <Column collapse center span='1'>
                                <CircleScore result={obj.hole8.result}>
                                    <CircleText result={obj.hole8.result} />
                                </CircleScore>
                            </Column>
                            <Column collapse center span='1'>
                                <CircleScore result={obj.hole9.result}>
                                    <CircleText result={obj.hole9.result} />
                                </CircleScore>
                            </Column>
                            <Column blue right span='4'>
                                {obj.leader !== 'draw' ? (
                                    <div>
                                        <ResultText
                                            winner={obj.leaderString}
                                            lead={obj.leadAmount}
                                        />
                                        <img
                                            src={obj.leader}
                                            alt='winner flag'
                                        />
                                    </div>
                                ) : (
                                    <div>
                                        <DrawText />
                                        <Spacer />
                                    </div>
                                )}
                            </Column>
                        </Row>
                    ))
                ) : (
                    <span>&nbsp;</span>
                )}
            </TableWrapper>
        );
    }
}

const mapStateToProps = ({ ryderCup: { holes, allPlayers, liveScores } }) => {
    return {
        holes,
        allPlayers,
        liveScores
    };
};

const mapDispatchToProps = dispatch => ({
    sendHeaderScores: obj => dispatch(headerScoresIncrement(obj)),
    getLiveScores: () => dispatch(getLiveScores('ryderCup'))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DisconnectedScorecardPage);
