import React from 'react';
import { ReactComponent as Logo } from '../Logo.svg';
import { StyledHeader, StyledH1 } from './ScoreHeader.styles';

export default () => (
    <StyledHeader data-test='component-header'>
        <StyledH1>
            The <Logo data-test='header-logo' />
            Open
        </StyledH1>
    </StyledHeader>
);
