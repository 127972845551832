import React from 'react';
import { connect } from 'react-redux';
import Player from './player/Player';
import {
    MatchWrapper,
    HoleScoreWrapper,
    ScoreContainer
} from './PlayGroup.styles';
import {
    incrementActiveHole,
    decrementActiveHole
} from '../../../../../store/actions';

import { CenterVerticalDiv, ScoreButton } from '../../../../../ui';

export class DisconnectedPlayerGroup extends React.PureComponent {
    numberOfMatchesArray = () => {
        const { players } = this.props;
        const matches = players.reduce((acc, player) => {
            acc.push(player.match);
            return acc;
        }, []);
        return [...new Set(matches)];
    };

    render() {
        const {
            handleIncrement,
            handleDecrement,
            players,
            activeHole
        } = this.props;

        return (
            <div data-test='component-player-group'>
                {this.numberOfMatchesArray(players).map((match, index) => {
                    const playersInMatch = players.filter(
                        player => player.match === match
                    );
                    const playerEu = playersInMatch[0];
                    const playerUsa = playersInMatch[1];

                    return (
                        <MatchWrapper key={match}>
                            <Player
                                firstPlayer={index === 0}
                                data-test='player'
                                key={playerEu.name}
                                {...playerEu}
                            />

                            <Player
                                data-test='player'
                                key={playerUsa.name}
                                {...playerUsa}
                            />
                        </MatchWrapper>
                    );
                })}
                <HoleScoreWrapper>
                    <CenterVerticalDiv>
                        <ScoreButton
                            data-test='dec-shot-count'
                            onClick={handleDecrement}
                        >
                            -
                        </ScoreButton>
                        <ScoreContainer data-test='gross-shot-count'>
                            {activeHole}
                        </ScoreContainer>
                        <ScoreButton
                            data-test='inc-shot-count'
                            onClick={handleIncrement}
                        >
                            +
                        </ScoreButton>
                    </CenterVerticalDiv>
                </HoleScoreWrapper>
            </div>
        );
    }
}

const mapStateToProps = ({ ryderCup: { activeHole, players, holes } }) => ({
    activeHole,
    players,
    holes
});

const mapDispatchToProps = dispatch => ({
    handleIncrement: () => dispatch(incrementActiveHole()),
    handleDecrement: () => dispatch(decrementActiveHole())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DisconnectedPlayerGroup);
