import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';

import ScoreHeader from '../components/header/header-score';
import ScoreResultsHeader from '../components/header/header-results/ResultsHeader';
import BottomNavigationComponent from '../components/navigation/navbar/NavigationComponent';
import BackComponent from '../components/navigation/back-button/BackComponent';
import {
    StyledWrapper,
    StyledHeaderWrapper,
    StyledContentWrapper
} from './App.styles';

import Home from './home/Home';
import LandingPage from './scoring/scoring-landing-page/ScoringLandingPage';
import Scorecard from './scoring/scorecard-page/Scorecard';
import ItineraryLandingPage from './itinerary/itinerary-landing-page/ItineraryLandingPage';
import ItineraryPage from './itinerary/my-itinerary/ItineraryPage';
import { getAllData } from '../store/actions';
import MatchFormats from './match-formats/MatchFormats';
import Bonus from './match-formats/formats/Bonus';
import Stableford from './match-formats/formats/Stableford';
import TexasScramble from './match-formats/formats/TexasScramble';
import RyderCupLandingPage from './scoring/ryder-cup/RyderCupLandingPage';
import StablefordLandingPage from './scoring/stableford/StablefordLandingPage';
import TexasScrambleLandingPage from './scoring/texas-scramble/TexasScrambleLandingPage';
import RyderCupRules from './match-formats/formats/RyderCup';
import RyansSecretPage from './ryans-secret-page/ryans-secret-page';
import HintOverlay from './hint-overlay/HintOverlay';

class App extends React.Component {
    componentDidMount() {
        const { getAllData: loadAppData } = this.props;
        loadAppData();
    }

    render() {
        const { holes, allPlayers } = this.props;
        const isLoaded = holes && allPlayers;
        if (!isLoaded) {
            return <div data-test='component-loading-div'>Loading...</div>;
        }
        return (
            <StyledWrapper data-test='component-app-APP-JS'>
                <StyledHeaderWrapper>
                    <BackComponent />
                    <Switch>
                        <Route path='/scoreboard'>
                            <ScoreResultsHeader data-test='component-header' />
                        </Route>
                        <Route path='/hints'>
                            <HintOverlay
                                data-test='component-hint'
                                gameType='Stableford'
                            />
                        </Route>
                        <Route path={['/', '/ryder-cup']}>
                            <ScoreHeader data-test='component-header-APP-JS' />
                        </Route>
                    </Switch>
                </StyledHeaderWrapper>
                <StyledContentWrapper>
                    <Switch>
                        <Route exact path='/'>
                            <Home data-test='component-home-APP-JS' />
                        </Route>
                        <Route path='/enter-scores'>
                            <LandingPage data-test='component-landing-APP-JS' />
                        </Route>
                        <Route exact path='/match-formats'>
                            <MatchFormats data-test='component-match-APP-JS' />
                        </Route>
                        <Route exact path='/match-formats/the-and-open'>
                            <Stableford />
                        </Route>
                        <Route exact path='/match-formats/texas-scramble'>
                            <TexasScramble />
                        </Route>
                        <Route exact path='/match-formats/ryder-cup'>
                            <RyderCupRules />
                        </Route>
                        <Route exact path='/match-formats/bonus-rounds'>
                            <Bonus />
                        </Route>
                        <Route path='/ryder-cup'>
                            <RyderCupLandingPage
                                data-test='component-ryder-APP-JS'
                                gameType='Ryder Cup'
                            />
                        </Route>
                        <Route path='/stableford'>
                            <StablefordLandingPage
                                data-test='component-stableford-APP-JS'
                                gameType='Stableford'
                            />
                        </Route>
                        <Route path='/texas-scramble'>
                            <TexasScrambleLandingPage
                                gameType='Texas Scramble'
                                data-test='component-texas-APP-JS'
                            />
                        </Route>
                        <Route path='/my-itinerary' exact>
                            <ItineraryLandingPage data-test='component-itinerary-landing-APP-JS' />
                        </Route>
                        <Route path='/my-itinerary/:guest'>
                            <ItineraryPage data-test='component-itinerary-guest-APP-JS' />
                        </Route>
                        <Route path='/scoreboard'>
                            <Scorecard />
                        </Route>
                        <Route path='/ryans-super-secret-page'>
                            <RyansSecretPage data-test='component-home-APP-JS' />
                        </Route>
                    </Switch>
                    <BottomNavigationComponent />
                </StyledContentWrapper>
            </StyledWrapper>
        );
    }
}

const mapStateToProps = ({ ryderCup: { holes, scores, allPlayers } }) => {
    return {
        holes,
        allPlayers,
        scores
    };
};

export default connect(mapStateToProps, { getAllData })(App);
