import React from 'react';
import colours from '../../../../global/colours';
import {
    SmallButton,
    Span16px,
    SpaceAroundDiv,
    MarginTop29px
} from '../../../../ui';

export default ({ toggleModal, endRound }) => (
    <>
        <Span16px>
            By ending your round your scores will be submitted to the tournament
            organiser and no longer be editable on this application.
        </Span16px>
        &nbsp;
        <Span16px>Are you ready to end your round?</Span16px>
        <MarginTop29px>
            <SpaceAroundDiv>
                <SmallButton
                    color={colours.green}
                    data-test='end-round-button-yes'
                    onClick={endRound}
                >
                    Yes
                </SmallButton>
                <SmallButton
                    color={colours.red}
                    data-test='end-round-button-no'
                    onClick={toggleModal}
                >
                    No
                </SmallButton>
            </SpaceAroundDiv>
        </MarginTop29px>
    </>
);
