/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { Span16px } from '../../../../../../ui/Typography';

import {
    ScoreContainer,
    RoundScoreContainer,
    ShotModifierDiv
} from './Player.styles';

const Player = ({ name, shotModifier, matchResultSection }) => {
    return (
        <ScoreContainer data-test='component-player'>
            <Span16px>{name}</Span16px>
            {shotModifier > 0 && !matchResultSection ? (
                <ShotModifierDiv data-test='shot-modifier'>
                    {shotModifier}
                </ShotModifierDiv>
            ) : null}
            <RoundScoreContainer data-test='gross-shot-count'>
                <input type='radio' />
            </RoundScoreContainer>
        </ScoreContainer>
    );
};

Player.propTypes = {
    name: PropTypes.string.isRequired
};

export default Player;
