/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useStore } from 'react-redux';
import {
    Accordion,
    VerticalDiv,
    RedDiv,
    Span16px,
    MarginY10px,
    Span20px,
    TableDiv,
    Span14px,
    TableCell
} from '../../../ui';
import SubHeader from '../../../components/sub-header';
import AccordionCards from '../../../ui/AccordionCards';

const Stableford = () => {
    const {
        stableford: { allPlayers }
    } = useStore().getState();
    let { itinerary } = useStore().getState();

    if (!Array.isArray(itinerary)) {
        itinerary = Object.values(itinerary);
    }

    const groups = [];
    const numberOfGroups = [
        ...new Set(itinerary.map(g => +g.itinerary.Friday.playingGroup))
    ];
    numberOfGroups
        .sort((a, b) => (a > b ? 1 : -1))
        // eslint-disable-next-line array-callback-return
        .map(groupNumber => {
            const playersInGroup = allPlayers
                .filter(player => player.group === groupNumber)
                .map(player => ({
                    name: player.name,
                    handicap: player.handicap,
                    teeOff: player.teeOff,
                    group: groupNumber
                }))
                .sort((a, b) => (a.match > b.match ? 1 : -1));
            if (playersInGroup.length > 0) {
                groups.push({
                    number: playersInGroup[0].group,
                    teeOff: playersInGroup[0].teeOff,
                    playersInGroup
                });
            }
        });
    return (
        <RedDiv>
            <SubHeader data-test='component-subheader'>
                <Span20px>Day 1 Match Format: Stableford</Span20px>
            </SubHeader>
            <VerticalDiv>
                <Accordion icon='📕' title='Rules'>
                    <MarginY10px>
                        <Span16px>The big one, The AND Open 2020.</Span16px>
                    </MarginY10px>
                    <MarginY10px>
                        <Span16px>
                            All against all. You score points based on the
                            number of strokes taken on each hole.
                        </Span16px>
                    </MarginY10px>
                    <MarginY10px>
                        <Span16px>
                            Scores are calculated after adjusting the number of
                            strokes taken based on a players handicap, but don't
                            worry, we'll do the calculating for you.{' '}
                        </Span16px>
                    </MarginY10px>
                    <MarginY10px>
                        <Span16px>
                            Just add the number of shots you've had and we'll do
                            the rest.
                        </Span16px>
                    </MarginY10px>
                    <MarginY10px>
                        <VerticalDiv>
                            <Span16px>
                                <strong>Scoring:</strong>
                            </Span16px>
                            <Span16px>Double bogey or worse - 0</Span16px>
                            <Span16px>Bogey - 1</Span16px>
                            <Span16px>Par - 2</Span16px>
                            <Span16px>Birdie - 3</Span16px>
                            <Span16px>Eagle - 4</Span16px>
                            <Span16px>Albatross - 5</Span16px>
                        </VerticalDiv>
                    </MarginY10px>
                </Accordion>

                <AccordionCards icon='😎' title='Groups'>
                    {groups.map(group => (
                        <VerticalDiv>
                            <Span20px>
                                <span role='img' aria-label='Golf pin emoji'>
                                    ⛳️
                                </span>{' '}
                                Group {group.number} - Tee Off {group.teeOff}
                            </Span20px>
                            <TableDiv>
                                <TableCell>
                                    <strong>Name</strong>
                                </TableCell>
                                <TableCell>
                                    <strong>Handicap</strong>
                                </TableCell>
                            </TableDiv>
                            {group.playersInGroup.map(player => (
                                <TableDiv>
                                    <TableCell>
                                        <Span14px>{player.name}</Span14px>
                                    </TableCell>
                                    <TableCell>
                                        <Span14px>{player.handicap}</Span14px>
                                    </TableCell>
                                </TableDiv>
                            ))}
                        </VerticalDiv>
                    ))}
                </AccordionCards>
            </VerticalDiv>
        </RedDiv>
    );
};

export default Stableford;
