import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import HoleDetails from '../../hole-details/HoleDetails';
import PlayerGroup from '../../hole-details/player-group/PlayerGroup';
import { EuResult } from '../../../utils';
import {
    LightGreyDiv,
    CenterDiv,
    Button,
    RightArrow,
    LeftArrow,
    WhiteCard,
    VerticalDiv
} from '../../../ui';
import Flex from '../../../ui/Layout/Flex';
import {
    incrementActiveHoleStable,
    decrementActiveHoleStable,
    toggleScoreEditableStable,
    saveData,
    endRound
} from '../../../store/actions';
import Modal from '../../../components/modal';
import EndRoundModalContent from './model-content/end-round.modal';
import EndRoundErrorModalContent from './model-content/end-round-error.modal';
import { getCookie } from '../../../utils/cookies.util';
import HintOverlay from '../../hint-overlay/HintOverlay';
import MatchSummaryStableford from './match-summary/MatchSummaryStableford';

export class DisconnectedStablefordLandingPage extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false
        };
    }

    submitData = () => {
        const {
            handleChangeEditState,
            players,
            holes,
            activeHole,
            saveData: saveHoleData
        } = this.props;
        const currentHole = holes[activeHole - 1];
        const holeEditable = currentHole.editable;
        const match1 = EuResult(
            players[0],
            players[2],
            currentHole,
            activeHole
        );
        const match2 = EuResult(
            players[1],
            players[3],
            currentHole,
            activeHole
        );

        handleChangeEditState();
        if (holeEditable) saveHoleData(match1, match2, players, currentHole);
    };

    toggleModal = () => {
        const { showModal } = this.state;
        this.setState({ showModal: !showModal });
    };

    getUnsubmittedHoles = () => {
        const { holes } = this.props;
        return holes.filter(hole => hole.editable);
    };

    isMatchCompleted = () => {
        const { allPlayers, players } = this.props;

        const groupNumber = players ? players[0].group : 0;
        const group = allPlayers.filter(player => player.group === groupNumber);

        if (group.length === 0) return false;

        return group[0].matchComplete || false;
    };

    getMatches = () => {
        const { players } = this.props;
        return [...new Set(players.map(p => p.match))].map(matchIndex => {
            return players.filter(p => p.match === matchIndex);
        });
    };

    render() {
        const { showModal } = this.state;
        const {
            holes,
            activeHole,
            liveScores,
            handleNavigateLeft,
            handleNavigateRight,
            // eslint-disable-next-line no-shadow
            endRound
        } = this.props;

        if (this.isMatchCompleted()) return <Redirect to='/' />;

        const holeEditable = holes[activeHole - 1].editable;

        if (!getCookie('AND Open Stableford Hint')) {
            return <HintOverlay gameType='Stableford' />;
        }

        return (
            <>
                <LightGreyDiv data-test='component-ryder-cup'>
                    <HoleDetails
                        hole={holes.find(hole => hole.number === activeHole)}
                        data-test='hole-details'
                        type='stableford'
                    />
                    <VerticalDiv>
                        <CenterDiv>
                            <LeftArrow
                                hidden={activeHole === 1}
                                data-test='left-arrow'
                                onClick={handleNavigateLeft}
                            >
                                {activeHole - 1}
                            </LeftArrow>
                            {activeHole !== holes.length ? (
                                <Button
                                    data-test='change-score-state-button'
                                    onClick={() => {
                                        this.submitData();
                                    }}
                                >
                                    {holeEditable ? 'Save Score' : 'Edit Score'}
                                </Button>
                            ) : (
                                <Button
                                    data-test='change-score-state-button'
                                    onClick={() => {
                                        this.submitData();
                                        this.toggleModal();
                                    }}
                                >
                                    {holeEditable
                                        ? 'Save and End Round'
                                        : 'Edit Score'}
                                </Button>
                            )}

                            <RightArrow
                                hidden={activeHole === holes.length}
                                data-test='right-arrow'
                                onClick={handleNavigateRight}
                            >
                                {activeHole + 1}
                            </RightArrow>
                        </CenterDiv>
                    </VerticalDiv>
                    <VerticalDiv>
                        <WhiteCard>
                            <Flex
                                justifyContent='space-between'
                                fontWeight='bold'
                                margin='0 0 10px 0'
                            >
                                <Flex>Scores Player</Flex>
                                <Flex width='150px'>Total Shots</Flex>
                            </Flex>
                            <PlayerGroup
                                gameType='stableford'
                                matchResultSection='match-summary'
                                data-test='component-player-group'
                            />
                            {this.getMatches().map((match, index) => (
                                <MatchSummaryStableford
                                    key={match[0].match}
                                    liveScores={
                                        liveScores[`match${match[0].match}`]
                                    }
                                    index={index}
                                    editable={holes}
                                />
                            ))}
                        </WhiteCard>
                    </VerticalDiv>
                </LightGreyDiv>
                )
                {showModal ? (
                    <Modal error={this.getUnsubmittedHoles().length > 0}>
                        {this.getUnsubmittedHoles().length > 0 ? (
                            <EndRoundErrorModalContent
                                toggleModal={() => {
                                    const {
                                        handleChangeEditState
                                    } = this.props;
                                    this.toggleModal();
                                    handleChangeEditState();
                                }}
                                incompleteHoles={this.getUnsubmittedHoles()}
                            />
                        ) : (
                            <EndRoundModalContent
                                toggleModal={() => {
                                    const {
                                        handleChangeEditState
                                    } = this.props;
                                    this.toggleModal();
                                    handleChangeEditState();
                                }}
                                endRound={() => {
                                    this.submitData();
                                    endRound();
                                }}
                            />
                        )}
                    </Modal>
                ) : null}
            </>
        );
    }
}

const mapStateToProps = ({
    stableford: { holes, allPlayers, activeHole, players, liveScores }
}) => ({
    holes,
    activeHole,
    allPlayers,
    players,
    liveScores
});

const mapDispatchToProps = dispatch => {
    return {
        saveData: (match1, match2, players, currentHole) =>
            dispatch(
                saveData(match1, match2, players, currentHole, 'stableford')
            ),
        handleNavigateLeft: () => dispatch(decrementActiveHoleStable()),
        handleNavigateRight: () => dispatch(incrementActiveHoleStable()),
        handleChangeEditState: () => dispatch(toggleScoreEditableStable()),
        endRound: () => dispatch(endRound('stableford'))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DisconnectedStablefordLandingPage);
