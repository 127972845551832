import styled from 'styled-components';
import { colours } from '../global';

export const VerticalDiv = styled.div`
    display: flex;
    flex-direction: column;
`;

export const ClearFloat = styled.div`
    clear: both;
`;

export const AlignLeft = styled.div`
    float: left;
`;

export const AlignRight = styled.div`
    float: right;
`;

export const CenterVerticalDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: ${props => props.marginTop};
`;

export const CenterDiv = styled.div`
    visibility: ${props => (props.hidden ? 'hidden' : null)};
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const SpaceAroundDiv = styled.div`
    display: flex;
    justify-content: space-around;
`;

export const RedDiv = styled.div`
    background-color: ${colours.red};
    width: 100%;
`;

export const LightGreyDiv = styled.div`
    background-color: ${colours.grey_light};
    width: 100%;
`;

export const WhiteCard = styled.div`
    position: relative;
    margin: 10px;
    padding: 10px;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

export const MarginTop29px = styled.div`
    margin-top: 29px;
`;

export const MarginY10px = styled.div`
    margin-top: 10px;
    margin-bottom: 10px;
`;

export const MarginX10px = styled.div`
    margin-left: 10px;
    margin-right: 10px;
`;

export const Div70 = styled.div`
    width: 70%;
`;

export const Div80 = styled.div`
    width: 80%;
`;

export const MainSectionContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
`;

export const MainSectionContent = styled.div`
    margin: 2%;
    position: relative;
    text-align: center;
    width: 30%;
    @media only screen and (max-width: 600px) {
        width: 45%;
    }
`;

export const MainSectionImgHolder = styled.img`
    border-radius: 10px;
    width: 100%;
`;

export const MainSectionButton = styled.button`
    position: absolute;
    bottom: 25px;
    left: 25%;
    z-index: 1;
    background: #ffffff;
    border: 2px solid ${colours.green};
    border-radius: 25px;
    transition: all 0.1s ease-in;
    text-transform: uppercase;
    padding: 13px 28px;
    width: 50%;
    font-size: 14px;
    font-weight: bold;

    @media only screen and (max-width: 600px) {
        left: 6%;
        width: 90%;
    }
`;

export const TableDiv = styled.div`
    display: flex;
    font-size: 14px;
    line-height: 21px;
`;

export const TableCell = styled.div`
    flex: 0.12;
    @media only screen and (max-width: 600px) {
        flex: 1;
    }
`;

export const TableCellMin = styled.div`
    flex: 0.12;
    font-variant-numeric: tabular-nums;
    @media only screen and (max-width: 600px) {
        flex: 0.2;
    }
`;
